import React from 'react';
import { compose } from 'recompose';
import { withRouter, useRouteMatch } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import * as color from '../../../color';
import level from '../../../font/level';
import { Icon } from 'antd';
import lStorage, { storageKeys } from '../../../localStorage';

function MenuLink({ label, type, to, toFunc, activeOnlyWhenExact }) {
  let match = useRouteMatch({
    path: to,
    exact: activeOnlyWhenExact,
  });

  return (
    <ContentWrapper onClick={() => toFunc(to)} selected={match}>
      <MenuIcon type={type} />
      <ContentTitle>{label}</ContentTitle>
    </ContentWrapper>
  );
}

class Contact extends React.Component {
  constructor(props) {
    super(props);
    const openTeams = lStorage.getOrInitObject(
      storageKeys.settingsOpenTeams,
      {}
    );

    const state = { openTeams: { me: openTeams.me ?? true } };
    props.store.teams.forEach(
      (t) => (state.openTeams[t.id] = openTeams[t.id] ?? true)
    );
    this.state = state;
  }

  to = (link) => this.props.history.push(link);

  render() {
    const { store } = this.props;
    const privateTeamId = store.privateTeam?.id;

    return (
      <Left>
        {/* 個人のコンタクト */}
        <main>
          {privateTeamId && (
            <MenuLink
              label="自分のコンタクト"
              type="contacts"
              to={`/contacts/${privateTeamId}/contacts`}
              toFunc={this.to}
            />
          )}
          <Title>会社のコンタクト</Title>
          {this.props.store.teams.map((t) => (
            <React.Fragment key={t.name}>
              {t.isMember(this.props.store.me.id) && (
                <MenuLink
                  label={t.name}
                  type="contacts"
                  to={`/contacts/${t.id}/contacts`}
                  toFunc={this.to}
                />
              )}
            </React.Fragment>
          ))}
        </main>
      </Left>
    );
  }
}

const Left = styled.div`
  width: 15%;
  overflow: scroll;
  min-width: 200px;
  max-width: 240px;
  background-color: ${color.sidebar.background.normal};
  border-right: solid 0.5px #f1f1f3;
  padding-top: 16px;
  padding-bottom: 16px;
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 6px 4px 6px 16px;
  cursor: pointer;

  ${({ selected }) =>
    selected &&
    `
      background-color: ${color.sidebar.background.selected};
      background-color: ${color.sidebar.background.selected};
      font-weight: bold;
  `} ${({ selected }) =>
    !selected &&
    `
      :hover{
        background-color: ${color.sidebar.background.hover};
      }
  `}
`;

const ContentTitle = styled.div`
  font-size: ${level[2]};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: normal;
`;

const MenuIcon = styled(Icon)`
  font-size: ${level[2]};
  margin: auto 8px auto 0;
`;

const Title = styled.div`
  font-size: ${level[2]};
  font-weight: bold;
  padding: 6px 4px 6px 16px;
  margin-top: 16px;
`;

export default compose(withRouter, inject('store'), observer)(Contact);
