import { Draft } from 'lib';

export class DraftStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  /**
   * @returns {CollectionReference}
   */
  collection() {
    return this.rootStore.companyCollection('drafts');
  }

  /**
   * @params {string} sentId
   * @params {(drafts: Draft[]) => void} callback
   * @returns {Function} Unsubscribe function
   */
  syncDraftsBySentId(teamId, sentId, callback) {
    const query = this.collection()
      .where('teamId', '==', teamId)
      .where('inReplyToSentId', '==', sentId);
    return query.onSnapshot((snap) => {
      callback(snap.docs.map((doc) => new Draft(doc)));
    });
  }
}
