import React, { useEffect } from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { Provider as MobxProvider } from 'mobx-react';
import ScrollToTop from './shared/scrollToTop';
import { Top } from './App/Top';
import Print from './App/Print';
import { Register } from './App/Register/Register';
import Join from './App/Join';
import SignIn from './App/SignIn';
import { Settings } from './App/Settings';
import Contact from 'App/Contacts';
import { OAuthCallback } from 'App/OAuth/OAuthCallback';
import store from './store';
import './App.css';
import { Auth } from './App/Auth';
import { RedirectIfAuthenticated } from './App/Auth/redirectIfAuthenticated';
import NotFound from './App/Error/404';
import './polyfill';
import ResetPassword from './App/ResetPassword';
import Channel from './shared/channel';
import { eventNames, logEvent } from './analytics';
import { createBrowserHistory } from 'history';
import * as Sentry from '@sentry/react';
import {
  CaptureConsole as CaptureConsoleIntegration,
  ExtraErrorData as ExtraErrorDataIntegration,
} from '@sentry/integrations';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
import { isProduction } from './util';
import Reports from './App/Reports';
import { PageRedirect } from './shared/PageRedirect';
import { StoreContext } from 'hooks/useStore';
import { checkIPFunction } from 'functions';
import firebase from './firebase';
import 'react-day-picker/dist/style.css';
import { StoreProvider } from './providers/StoreProvider';
import { TabletOrSPOnly } from './App/Common/MediaQuery';
import { SidebarDrawer } from './App/Top/SidebarDrawer';
import { ConfirmDialogContainer } from './components/basics/dialog/ConfirmDialogContainer';
import { GlobalErrorHandler } from './components/error/GlobalErrorHandler';

const history = createBrowserHistory();

const isFirestoreListenSuccessBreadcrumb = (breadcrumb) =>
  breadcrumb.type === 'http' &&
  breadcrumb.category === 'xhr' &&
  breadcrumb.data?.method === 'POST' &&
  breadcrumb.data?.status_code === 200 &&
  (breadcrumb.data?.url || '').startsWith(
    'https://firestore.googleapis.com/google.firestore.v1.Firestore/Listen/channel'
  );

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_RELEASE_VERSION,
    integrations: [
      new CaptureConsoleIntegration({ levels: ['error'] }),
      new ExtraErrorDataIntegration({ depth: 6 }),
      new TracingIntegrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
        beforeNavigate: (context) => {
          return {
            ...context,
            name: window.location.pathname.replace(
              /\/[A-Za-z0-9]{20}/g,
              '/<ID>'
            ),
          };
        },
      }),
    ],
    tracesSampleRate: isProduction() ? 0.01 : 1.0,
    maxBreadcrumbs: 200,
    normalizeDepth: 6,
    beforeBreadcrumb(breadcrumb, hint) {
      try {
        if (isFirestoreListenSuccessBreadcrumb(breadcrumb)) return null;

        if ((breadcrumb.category || '').startsWith('ui')) {
          breadcrumb.message += ` innerText:"${(
            hint?.event?.target?.innerText || ''
          ).substring(0, 5)}"`;
        }
      } catch (e) {
        // noop
      }

      return breadcrumb;
    },
  });
}

function App() {
  useEffect(() => {
    logEvent(eventNames.page_view, { page_path: window.location.pathname });

    history.listen((location) => {
      logEvent(eventNames.page_view, { page_path: window.location.pathname });
    });
  }, []);

  useEffect(() => {
    const abortController = new AbortController();
    const timer = setInterval(() => {
      const { signInUser } = store;
      if (abortController.signal.aborted) {
        return;
      }
      if (signInUser) {
        store
          .hasIPLimitedSetting()
          .then((hasIPLimitedSetting) => {
            if (!hasIPLimitedSetting) {
              return;
            }
            return checkIPFunction();
          })
          .then((res) => {
            if (res?.data?.allow === false) {
              abortController.abort();
              firebase.auth().signOut();
              alert('許可されていないIPアドレスからアクセスしています。');
              window.location.href = '/';
            }
          })
          .catch(() => {
            /** noop */
          });
      }
    }, 10 * 1000);

    return () => {
      abortController.abort();
      clearInterval(timer);
    };
  }, []);

  return (
    <MobxProvider store={store}>
      <StoreContext.Provider value={store}>
        <StoreProvider>
          <Router history={history}>
            <Channel>
              <ScrollToTop>
                <Switch>
                  {/* 新規登録 */}
                  <Route exact path="/register">
                    <RedirectIfAuthenticated>
                      <Register />
                    </RedirectIfAuthenticated>
                  </Route>

                  {/* 新規登録（会社へジョイン） */}
                  <Route exact path="/join">
                    <RedirectIfAuthenticated>
                      <Join />
                    </RedirectIfAuthenticated>
                  </Route>

                  {/* ログイン */}
                  <Route exact path="/signIn">
                    <RedirectIfAuthenticated>
                      <SignIn />
                    </RedirectIfAuthenticated>
                  </Route>

                  {/* パスワードを忘れた場合 */}
                  <Route exact path="/resetPassword">
                    <RedirectIfAuthenticated>
                      <ResetPassword />
                    </RedirectIfAuthenticated>
                  </Route>
                  <Route
                    exact
                    path="/oauth/:service/callback"
                    component={OAuthCallback}
                  />

                  <Auth>
                    <PageRedirect store={store}>
                      <Switch>
                        {/* 各種設定 */}
                        <Route path="/settings" component={Settings} />

                        {/* レポート */}
                        <Route path="/reports" component={Reports} />

                        {/* コンタクト */}
                        <Route path="/contacts" component={Contact} />

                        {/* 印刷 */}
                        <Route path="/print" component={Print} />

                        {/* トップ */}
                        <Route
                          path={[
                            '/rooms/:roomId',
                            '/teams/:teamId/lineaccounts/:lineAccountId/:tab',
                            '/teams/:teamId/inboxes/:inboxId/tags/:tagId/:tab',
                            '/teams/:teamId/tags/:tagId/:tab',
                            '/teams/:teamId',
                            '/me/assigned/:tab',
                            '/me',
                            '/search',
                          ]}
                          component={Top}
                        />

                        {/* NotFound */}
                        <Route component={NotFound} />
                      </Switch>
                      <TabletOrSPOnly>
                        <SidebarDrawer />
                      </TabletOrSPOnly>
                      <ConfirmDialogContainer />
                      <GlobalErrorHandler />
                    </PageRedirect>
                  </Auth>
                </Switch>
              </ScrollToTop>
            </Channel>
          </Router>
        </StoreProvider>
      </StoreContext.Provider>
    </MobxProvider>
  );
}

export default Sentry.withProfiler(App);
