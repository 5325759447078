import { eventNames, logEvent } from '../../../../../../analytics';
import { message, Modal } from 'antd';
import { Store } from '../../../../../../store';
import { updateMessagesFunction } from '../../../../../../functions';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { assignedCountAtom } from '../../../../../../atoms/firestore/count/assigned';
import { store } from '../../../../../../providers/StoreProvider';
import { tagCountAtomFamily } from '../../../../../../atoms/firestore/count/tag';

type Result = {
  updateAllToProcessed: () => void;
};

export const useUpdateAllProcessed = (mobxStore: Store): Result => {
  const location = useLocation();
  const match = useRouteMatch<{
    teamId: string;
    inboxId: string;
    tagId: string;
  }>();

  const updateAllToProcessed = () => {
    logEvent(eventNames.update_status_of_all_messages_to_processed);
    const count = getUnprocessedCount();
    Modal.confirm({
      title: 'すべて対応済みに変更しますか？',
      content: `${count}件の未対応のメールをすべて対応済みにします。`,
      onOk: async () => {
        mobxStore.checkedMessages = [];
        await execUpdateAllToProcessed();
        message.success(`${count}件を対応済みにしました`);
      },
      okText: '対応済みに変更',
      cancelText: 'キャンセル',
      maskClosable: true,
    });
  };

  const execUpdateAllToProcessed = async () => {
    const assigneeId = location.pathname.startsWith('/me/assigned')
      ? mobxStore.me.id
      : null;
    const companyId = mobxStore.signInCompany;
    const { teamId, inboxId, tagId } = match.params;
    const teamIds = teamId ? [teamId] : mobxStore.joinedTeams.map((t) => t.id);

    await updateMessagesFunction({
      assigneeId,
      companyId,
      teamIds,
      inboxId,
      tagId,
    });
  };

  const getUnprocessedCount = () => {
    const assignedMe = location.pathname.startsWith('/me/assigned');
    if (assignedMe) {
      return store.get(assignedCountAtom);
    }

    const { teamId, inboxId, tagId } = match.params;
    return store.get(
      tagCountAtomFamily({
        teamId,
        tagId,
        inboxId,
      })
    );
  };

  return {
    updateAllToProcessed,
  };
};
