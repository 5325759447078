import React, { PropsWithChildren } from 'react';
import { match, NavLink } from 'react-router-dom';
import { Location } from 'history';

export type IsActiveRouteFunction = (
  match: match | null,
  location: Location
) => boolean;

type Props = {
  to?: string;
  exact?: boolean;
  badge?: number;
  isActiveRoute?: IsActiveRouteFunction;
  onClick?: () => void;
  label?: string;
};

export const MainNavItem = (props: PropsWithChildren<Props>): JSX.Element => {
  return (
    <div
      onClick={props.onClick}
      className="relative flex h-10 w-10 cursor-pointer items-center justify-center"
    >
      {props.to ? (
        <NavLink
          to={props.to}
          exact={props.exact}
          isActive={props.isActiveRoute}
          className="flex h-full w-full items-center justify-center"
          activeClassName="rounded-lg bg-sumi-200"
          aria-label={props.label}
        >
          {props.children}
        </NavLink>
      ) : (
        props.children
      )}
      {props.badge ? (
        <div className="absolute right-0.5 top-0.5 flex h-4 w-4 animate-bounce items-center justify-center rounded-3xl bg-[#FF5500] text-white">
          {props.badge}
        </div>
      ) : null}
    </div>
  );
};
