import moment from 'moment';
import { estimateServerTimestampsOption } from './common';
import { convertNtoBr, proxyDataHandler } from '../util';
import firebase from 'firebase/compat/app';
import { SignatureData } from './signature';

export type DraftAttachment = {
  disposition: string;
  filename: string;
  name: string;
  size: number;
  storagePath: string;
  type: string;
  uid: string;
};

export interface DraftData {
  teamId: string;
  originalInboxId?: string;
  inboxId: string;
  isReply: boolean;
  isForwarded: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inReplyToMessageId?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inReplyToMessageRef?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inReplyToSentId?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inReplyToSentRef?: any;
  drafter: string;
  messageId?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  from?: any;
  to: string[];
  cc: string[];
  bcc: string[];
  subject: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  originalSubject?: any;
  body: string;
  bodyHtml: string;
  signature: (SignatureData & { id: string }) | null;
  useQuote: boolean;
  attachments: DraftAttachment[];
  scheduledAt?: firebase.firestore.Timestamp | null;
  failedAt?: firebase.firestore.Timestamp | null;
  followupAt?: firebase.firestore.Timestamp | null;
  followupStatus?: string;
  isSending?: boolean;
  isGenerating?: boolean;
  plainTextMode: boolean;
  ignoreUnsubscribes?: boolean; // 予約送信時に購読解除状態を無視して送るか
  updatedAt: firebase.firestore.Timestamp;
  createdAt: firebase.firestore.Timestamp;
}

export interface Draft extends DraftData {}

export class Draft {
  private _id: string;
  private _data: DraftData;
  private _date: moment.Moment;
  private _ref: firebase.firestore.DocumentReference<DraftData>;
  public status?: string;

  constructor(doc: firebase.firestore.DocumentSnapshot<DraftData>) {
    this._id = doc.id;
    const data = doc.data(estimateServerTimestampsOption);
    if (!data) {
      throw new Error(`no data for draft#${doc.id}`);
    }
    this._data = data;
    this._date = moment(this._data.createdAt.toMillis()); // 毎回生成するのはコストがかかるため
    this._ref = doc.ref;

    return new Proxy(this, proxyDataHandler);
  }

  get id() {
    return this._id;
  }

  get ref() {
    return this._ref;
  }

  get date() {
    return this._date;
  }

  get teamId() {
    return this._data.teamId;
  }

  get originalInboxId() {
    return this._data.originalInboxId;
  }

  get inboxId() {
    return this._data.inboxId;
  }

  get isReply() {
    return this._data.isReply;
  }

  get isForwarded() {
    return this._data.isForwarded;
  }

  get inReplyToMessageId() {
    return this._data.inReplyToMessageId;
  }

  get inReplyToMessageRef() {
    return this._data.inReplyToMessageRef;
  }

  get inReplyToSentId() {
    return this._data.inReplyToSentId;
  }

  get inReplyToSentRef() {
    return this._data.inReplyToSentRef;
  }

  get drafter() {
    return this._data.drafter;
  }

  get messageId() {
    return this._data.messageId;
  }

  get from() {
    return this._data.from;
  }

  get to() {
    return this._data.to;
  }

  set to(value) {
    this._data.to = value;
  }

  get cc() {
    return this._data.cc;
  }

  set cc(value) {
    this._data.cc = value;
  }

  get bcc() {
    return this._data.bcc;
  }

  set bcc(value) {
    this._data.bcc = value;
  }

  get subject() {
    return this._data.subject;
  }

  get originalSubject() {
    return this._data.originalSubject;
  }

  get body() {
    return this._data.body;
  }

  get bodyHtml() {
    return this._data.bodyHtml;
  }

  get signature() {
    if (!this._data.signature) {
      return null;
    }
    return {
      ...this._data.signature,
      bodyHtml:
        this._data.signature.bodyHtml ||
        // HTML化対応前の署名はテキストからHTMLに変換する
        convertNtoBr(this._data.signature.body),
    };
  }

  get useQuote() {
    return this._data.useQuote || false;
  }

  get attachments() {
    return this._data.attachments;
  }

  get scheduledAt() {
    return this._data.scheduledAt;
  }

  get isScheduled() {
    return Boolean(this.scheduledAt);
  }

  get failedAt() {
    return this._data.failedAt;
  }

  get isFailed() {
    return Boolean(this.failedAt);
  }

  get isSending() {
    return Boolean(this._data.isSending);
  }

  get isGenerating() {
    return Boolean(this._data.isGenerating);
  }

  get plainTextMode() {
    return this._data.plainTextMode || false;
  }

  get ignoreUnsubscribes() {
    return this._data.ignoreUnsubscribes || false;
  }

  get companyId() {
    return this.ref.parent.parent?.id;
  }

  get createdAt() {
    return this._data.createdAt;
  }

  get updatedAt() {
    return this._data.updatedAt;
  }
}
