import { EditorList, EditorTextAlign, EditorToolbar } from './EditorToolbar';
import { EditorHandle } from '../WysiwygEditor/WysiwygEditor';
import React, { useEffect, useRef, useState } from 'react';
import { useSetAtom } from 'jotai';
import { newLinkTextAtom } from '../LinkBubbleMenu/LinkBubbleMenu';
import { v4 } from 'uuid';
import * as Portal from '@radix-ui/react-portal';
import { EditorFont } from '../extension/fontFamily';
import { ChainedCommands } from '@tiptap/react';

type Props = {
  handle: EditorHandle | undefined;
  disabled?: boolean;
  closable?: boolean;
  onClose?: () => void;
  onInsertImage?: (handle: EditorHandle, file: File) => Promise<void>;
};

export const EditorToolbarWithControls = ({
  handle,
  disabled,
  closable,
  onClose,
  onInsertImage,
}: Props) => {
  const editor = handle?.editor;
  const fileInputRef = useRef<HTMLInputElement>(null);
  const refresh = useRefresh();
  const setNewLinkText = useSetAtom(newLinkTextAtom);
  const isImage = editor?.isActive('image') ?? false;
  const textAlign = (['left', 'center', 'right'] as EditorTextAlign[]).find(
    (textAlign) => editor?.isActive({ textAlign })
  );
  let list: EditorList | undefined;
  if (editor?.isActive('bulletList')) {
    list = 'bulleted';
  } else if (editor?.isActive('orderedList')) {
    list = 'numbered';
  }
  const link = editor?.getAttributes('link').href ?? undefined;
  const textStyle = editor?.getAttributes('textStyle');
  useEffect(() => {
    if (!editor) {
      return;
    }
    editor.on('selectionUpdate', refresh);
    return () => {
      editor.off('selectionUpdate', refresh);
    };
  }, [editor, refresh]);

  const onChangeFile = async (file: File) => {
    fileInputRef.current!.value = '';
    if (!handle || !onInsertImage) {
      return;
    }
    await onInsertImage(handle, file);
  };

  const setFontFamily = (font: EditorFont | undefined) => {
    if (!editor) {
      return;
    }
    if (font) {
      editor.chain().focus().unsetFontFamily().setFontFamily(font).run();
    } else {
      editor.chain().focus().unsetFontFamily().run();
    }
    refresh();
  };

  const setFontSize = (fontSize: number | undefined) => {
    if (!editor) {
      return;
    }
    if (fontSize != null) {
      editor.chain().focus().setFontSize(fontSize).run();
    } else {
      editor.chain().focus().unsetFontSize().run();
    }
    refresh();
  };

  const setTextColor = (textColor: string | undefined) => {
    if (!editor) {
      return;
    }
    if (textColor != null) {
      editor.chain().focus().setColor(textColor).run();
    } else {
      editor.chain().focus().unsetColor().run();
    }
    refresh();
  };

  const setBackgroundColor = (backgroundColor: string | undefined) => {
    if (!editor) {
      return;
    }
    if (backgroundColor != null) {
      editor.chain().focus().setHighlight({ color: backgroundColor }).run();
    } else {
      editor.chain().focus().unsetHighlight().run();
    }
    refresh();
  };

  const setList = (list: EditorList | undefined) => {
    if (!editor) {
      return;
    }
    if (list === 'bulleted') {
      editor.chain().focus().toggleBulletList().run();
    } else if (list === 'numbered') {
      editor.chain().focus().toggleOrderedList().run();
    } else {
      editor.chain().focus().liftListItem('listItem').run();
    }
    refresh();
  };

  const toggle = (
    trueFunc: (cmd: ChainedCommands) => ChainedCommands,
    falseFunc: (cmd: ChainedCommands) => ChainedCommands
  ): ((value: boolean) => void) => {
    return (value) => {
      if (!editor) {
        return;
      }
      const func = value ? trueFunc : falseFunc;
      func(editor.chain().focus()).run();
    };
  };

  const tableAdd = (
    before: (cmd: ChainedCommands) => ChainedCommands,
    after: (cmd: ChainedCommands) => ChainedCommands
  ): ((value: 'before' | 'after') => void) => {
    return (value) => {
      if (!editor) {
        return;
      }
      const func = value === 'before' ? before : after;
      func(editor.chain().focus()).run();
    };
  };

  return (
    <>
      <EditorToolbar
        fontFamily={{
          value: textStyle?.fontFamily ?? undefined,
          canSet:
            (!isImage && editor?.can().setFontFamily('sans_serif')) ?? false,
          set: setFontFamily,
        }}
        fontSize={{
          value: textStyle?.fontSize ?? undefined,
          canSet: (!isImage && editor?.can().setFontSize(1)) ?? false,
          set: setFontSize,
        }}
        textColor={{
          value: textStyle?.color ?? undefined,
          canSet: (!isImage && editor?.can().setColor('#000000')) ?? false,
          set: setTextColor,
        }}
        backgroundColor={{
          value: editor?.getAttributes('highlight').color ?? undefined,
          canSet:
            (!isImage && editor?.can().setHighlight({ color: 'red' })) ?? false,
          set: setBackgroundColor,
        }}
        bold={{
          value: editor?.isActive('bold') ?? false,
          canSet: (!isImage && editor?.can().setBold()) ?? false,
          set: toggle(
            (c) => c.setBold(),
            (c) => c.unsetBold()
          ),
        }}
        italic={{
          value: editor?.isActive('italic') ?? false,
          canSet: (!isImage && editor?.can().setItalic()) ?? false,
          set: toggle(
            (c) => c.setItalic(),
            (c) => c.unsetItalic()
          ),
        }}
        underline={{
          value: editor?.isActive('underline') ?? false,
          canSet: (!isImage && editor?.can().setUnderline()) ?? false,
          set: toggle(
            (c) => c.setUnderline(),
            (c) => c.unsetUnderline()
          ),
        }}
        strikethrough={{
          value: editor?.isActive('strike') ?? false,
          canSet: (!isImage && editor?.can().setStrike()) ?? false,
          set: toggle(
            (c) => c.setStrike(),
            (c) => c.unsetStrike()
          ),
        }}
        blockquote={{
          value: editor?.isActive('blockquote') ?? false,
          canSet: (!isImage && editor?.can().setBlockquote()) ?? false,
          set: toggle(
            (c) => {
              console.log('set');
              return c.setBlockquote();
            },
            (c) => {
              console.log('unset');
              return c.unsetBlockquote();
            }
          ),
        }}
        textAlign={{
          value: textAlign ?? 'left',
          canSet:
            (editor?.isActive('heading') || editor?.isActive('paragraph')) ??
            false,
          set: (value) => editor?.chain().focus().setTextAlign(value).run(),
        }}
        list={{
          value: list,
          canSet:
            (editor?.can().toggleBulletList() ||
              editor?.can().toggleOrderedList()) ??
            false,
          set: setList,
        }}
        link={{
          value: link,
          canSet: editor
            ? editor.can().setLink({ href: '' }) &&
              editor.state.selection.from !== editor.state.selection.to
            : false,
          openInput: () => {
            if (!editor || link) {
              return;
            }
            const { view, state } = editor;
            const { from, to } = view.state.selection;
            const text = state.doc.textBetween(from, to, '');
            setNewLinkText(text);
            editor.commands.focus();
          },
        }}
        clearTextStyles={{
          canSet: editor?.can().unsetAllMarks() ?? false,
          set: () => {
            editor?.chain().focus().unsetTextAlign().unsetAllMarks().run();
            refresh();
          },
        }}
        image={{
          canSet:
            (!editor?.isActive('image') &&
              editor?.can().setImage({ src: '' })) ??
            false,
          openInput: () => fileInputRef.current?.click(),
        }}
        hideImage={!onInsertImage}
        table={{
          value: editor?.isActive('table') ?? false,
          canInsert: editor?.can().insertTable() ?? false,
          insert: (rows, cols) =>
            editor?.chain().focus().insertTable({ rows, cols }).run(),
        }}
        row={{
          canAddBefore: editor?.can().addRowBefore() ?? false,
          canAddAfter: editor?.can().addRowAfter() ?? false,
          canDelete: editor?.can().deleteRow() ?? false,
          delete: () => editor?.chain().focus().deleteRow().run(),
          add: tableAdd(
            (c) => c.addRowBefore(),
            (c) => c.addRowAfter()
          ),
        }}
        column={{
          canAddBefore: editor?.can().addColumnBefore() ?? false,
          canAddAfter: editor?.can().addColumnAfter() ?? false,
          canDelete: editor?.can().deleteColumn() ?? false,
          delete: () => editor?.chain().focus().deleteColumn().run(),
          add: tableAdd(
            (c) => c.addColumnBefore(),
            (c) => c.addColumnAfter()
          ),
        }}
        tableHeader={{
          value: editor?.isActive('tableHeader') ?? false,
          canToggle: editor?.can().toggleHeaderCell() ?? false,
          toggle: () => editor?.chain().focus().toggleHeaderCell().run(),
        }}
        closable={closable}
        onClose={onClose}
        onOpenChangeDropdown={(open) => !open && editor?.commands.focus()}
        disabled={!editor || disabled}
      />
      {onInsertImage && (
        <Portal.Root>
          <input
            type="file"
            onChange={(e) => onChangeFile(e.target.files![0])}
            ref={fileInputRef}
            accept="image/png, image/jpeg"
            hidden
          />
        </Portal.Root>
      )}
    </>
  );
};

const useRefresh = () => {
  const [, setId] = useState(v4());
  return () => setId(v4());
};
