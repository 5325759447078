import { ComponentPropsWithoutRef, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { TagColor } from './colors';
import { convertTagColorToHexColor } from '../../../color';
import { tv } from 'tailwind-variants';
import { Icon } from '../Icon';
import { Close } from '../../icons';

type Props = Omit<ComponentPropsWithoutRef<'div'>, 'color'> & {
  color: TagColor | null | undefined;
  closable?: boolean;
  onClose?: () => void;
};

const tag = tv({
  base: 'grid h-8 w-fit select-none items-center rounded-lg border px-2 text-xs leading-4',
  variants: {
    colored: {
      true: 'bg-[color-mix(in_srgb,var(--tag-color)_10%,white)] text-[var(--tag-color)]',
      false: 'border-sumi-300 bg-sumi-100',
    },
    closable: {
      true: 'grid-cols-[1fr_auto] gap-1.5',
      false: 'grid-cols-[1fr]',
    },
  },
});

export const Tag = forwardRef<HTMLDivElement, Props>(
  (
    { color, closable = false, onClose, style, className, children, ...props },
    ref
  ) => {
    const cssColor = color ? convertTagColorToHexColor(color) : undefined;
    return (
      <div
        className={twMerge(tag({ colored: !!color, closable }), className)}
        style={{
          ...style,
          borderColor: cssColor
            ? 'color-mix(in srgb ,var(--tag-color) 30%, white)' // Tailwindで機能しないのでスタイルで指定する
            : undefined,
          ['--tag-color' as never]: cssColor,
        }}
        {...props}
        ref={ref}
      >
        {children}
        {closable && (
          <button
            type="button"
            onClick={() => onClose?.()}
            className="cursor-pointer bg-transparent p-0"
          >
            <Icon icon={Close} size={8} />
          </button>
        )}
      </div>
    );
  }
);

Tag.displayName = 'Tag';
