import './WysiwygEditor.css';
import './message.css';
import { StarterKit } from '@tiptap/starter-kit';
import {
  Editor,
  EditorContent,
  PureEditorContent,
  useEditor,
} from '@tiptap/react';
import { Placeholder } from '@tiptap/extension-placeholder';
import { TextAlign } from '@tiptap/extension-text-align';
import { FontSize } from '../extension/fontSize';
import { Underline } from '@tiptap/extension-underline';
import { FontFamily } from '../extension/fontFamily';
import { Color } from '@tiptap/extension-color';
import { ExtendedTextStyle } from '../extension/extendedTextStyle';
import { ExtendedImage } from '../extension/extendedImage';
import { Link } from '@tiptap/extension-link';
import { LinkBubbleMenu } from '../LinkBubbleMenu/LinkBubbleMenu';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import {
  ComponentPropsWithoutRef,
  forwardRef,
  ReactNode,
  useEffect,
  useRef,
} from 'react';
import { Highlight } from '@tiptap/extension-highlight';
import { twMerge } from 'tailwind-merge';
import { flushSync } from 'react-dom';
import Table from '@tiptap/extension-table';
import { useWindowEvent } from '../../../../hooks/useWindowEvent';
import { ResolvedPos, Slice, Fragment, Node } from 'prosemirror-model';
import { EditorView } from 'prosemirror-view';

export type EditorHandle = {
  editor: Editor | null;
  getHtml: () => string;
  setHtml: (html: string) => void;
  getText: () => string;
  insertText: (text: string) => void;
  insertImage: (src: string, contentId: string) => void;
  hasImage: (contentId: string) => Promise<boolean>;
  deleteImage: (contentId: string) => Promise<boolean>;
};

type Props = ComponentPropsWithoutRef<'div'> & {
  defaultValue?: string;
  placeholder?: string;
  initEditorHandle?: (handle: EditorHandle) => void;
  editorClassName?: string;
  disabled?: boolean;
  children?: ReactNode;
  uploadImage?: (file: File) => Promise<{ src: string; contentId: string }>;
  onDeleteImage?: (contentId: string) => void;
  getBubbleMenuContainer?: () => HTMLElement;
};

// TiptapのflushSyncに問題がある
// https://github.com/ueberdosis/tiptap/issues/4492
PureEditorContent.prototype.maybeFlushSync = function maybeFlushSync(fn) {
  if (this.initialized) {
    flushSync(fn);
    this.initialized = false;
  } else {
    fn();
  }
};

const extensions = [
  StarterKit.configure({
    heading: false,
    horizontalRule: false,
  }),
  ExtendedTextStyle,
  Underline,
  FontFamily,
  Color,
  FontSize,
  Highlight.configure({
    multicolor: true,
  }),
  TextAlign.configure({
    types: ['heading', 'paragraph'],
  }),
  Link.configure({
    openOnClick: false,
  }),
  Table.configure({
    resizable: true,
  }),
  TableRow,
  TableHeader,
  TableCell,
];

export const WysiwygEditor = forwardRef<HTMLDivElement, Props>(
  (
    {
      defaultValue,
      placeholder,
      initEditorHandle,
      editorClassName,
      disabled,
      uploadImage,
      getBubbleMenuContainer,
      children,
      ...props
    },
    ref
  ) => {
    const initializedRef = useRef(false);
    const editor = useEditor({
      extensions: [
        ...extensions,
        ...(placeholder
          ? [
              Placeholder.configure({
                placeholder,
                emptyEditorClass: 'is-editor-empty text-sumi-500',
              }),
            ]
          : []),
        ExtendedImage.configure({
          inline: true,
          allowBase64: true,
          upload: uploadImage,
        }),
      ],
      editorProps: {
        clipboardTextParser,
        attributes: {
          class: twMerge('outline-none text-sm min-h-full', editorClassName),
        },
      },
      editable: !disabled,
    });

    // 上記のflushSyncのバグの回避
    useEffect(() => {
      if (initializedRef.current) {
        return;
      }
      if (editor) {
        initializedRef.current = true;
        if (defaultValue) {
          setTimeout(() => editor.commands.setContent(defaultValue, false));
        }
      }
    }, [editor, defaultValue]);

    useEffect(() => {
      if (!editor) {
        return;
      }
      initEditorHandle?.({
        editor,
        getHtml: () => editor.getHTML(),
        setHtml: (html) => {
          editor.commands.setContent(html);
        },
        getText: () => editor.getText({ blockSeparator: '\n' }),
        insertText: (text) => {
          if (!editor) {
            return;
          }
          const transaction = editor.state.tr.insertText(text);
          editor.view.dispatch(transaction);
          editor.commands.focus();
        },
        insertImage: (src, contentId) => {
          editor
            .chain()
            .focus()
            .setImage({ src, 'data-content_id': contentId } as never)
            .run();
        },
        hasImage: async (contentId) => {
          return new Promise((resolve) => {
            const view = editor.view;
            view.state.doc.descendants((node) => {
              if (
                node.type.name === 'image' &&
                node.attrs['data-content_id'] === contentId
              ) {
                resolve(true);
              }
            });
            resolve(false);
          });
        },
        deleteImage: async (contentId) => {
          return new Promise((resolve) => {
            let deleted = false;
            const view = editor.view;
            view.state.doc.descendants((node, pos) => {
              if (
                node.type.name === 'image' &&
                node.attrs['data-content_id'] === contentId
              ) {
                const tr = view.state.tr.delete(pos, pos + 1);
                tr.setMeta('addToHistory', false);
                view.dispatch(tr);
                deleted = true;
              }
            });
            resolve(deleted);
          });
        },
      });
    }, [editor]);

    useWindowEvent('keydown', (e) => {
      if (e.ctrlKey && e.altKey && e.key === 'd') {
        console.log(editor?.getHTML());
      }
    });

    if (!editor) {
      return null;
    }
    return (
      <div
        {...props}
        className={twMerge('flex h-full flex-col', props.className)}
        ref={ref}
      >
        <EditorContent editor={editor} className="message-container grow" />
        <LinkBubbleMenu
          editor={editor}
          hidden={disabled}
          getContainer={getBubbleMenuContainer}
        />
        {children}
      </div>
    );
  }
);

WysiwygEditor.displayName = 'WysiwygEditor';

const clipboardTextParser = (
  text: string,
  context: ResolvedPos,
  _plain: boolean,
  _view: EditorView
) => {
  const blocks = text.split(/\r\n?|\n/);
  const nodes: Node[] = [];

  blocks.forEach((line) => {
    const nodeJson: any = { type: 'paragraph' };
    if (line.length > 0) {
      nodeJson.content = [{ type: 'text', text: line }];
    }
    const node = Node.fromJSON(context.doc.type.schema, nodeJson);
    nodes.push(node);
  });

  const fragment = Fragment.fromArray(nodes);
  return Slice.maxOpen(fragment);
};
