import { DefaultTextarea } from '../../../Common/Input';
import { Modal } from 'antd';
import { useState } from 'react';
import { isSP } from 'shared/util';
import SPCreateMesssageToolModal from '../SPCreateMesssageToolModal';
import { Button } from 'components/basics';
import { Ai } from 'components/icons';

type Props = {
  visible: boolean;
  onOk: (prompt: string) => void;
  onCancel: () => void;
};

export const TextCompletionModal = ({
  visible,
  onOk,
  onCancel,
}: Props): JSX.Element => {
  const [prompt, setPrompt] = useState('');

  const contentBody = (
    <>
      <p className="mb-2 text-sm">
        自動生成したいメール内容を具体的に指示してください
      </p>
      <DefaultTextarea
        value={prompt}
        onChange={(e) => setPrompt(e.target.value)}
        style={{ width: '100%' }}
        rows={isSP() ? 6 : 3}
        placeholder={
          'クレームに対するお詫びメールを100文字程度で作成してください'
        }
        autoFocus
      />
    </>
  );

  const modalTitle = (
    <div className="flex items-center gap-2">
      <Ai />
      <p className="mb-0 font-bold">自動生成</p>
    </div>
  );

  if (isSP()) {
    return (
      <SPCreateMesssageToolModal
        title="yaritori AIで自動生成"
        onCancel={onCancel}
        isModalOpen={visible}
        footerElement={
          <Button className="w-full" onClick={() => onOk(prompt)}>
            生成
          </Button>
        }
      >
        {contentBody}
      </SPCreateMesssageToolModal>
    );
  }

  return (
    <Modal
      okButtonProps={{
        style: {
          border: 'none',
          fontWeight: 'bold',
          background: '-webkit-linear-gradient(top, #4E8BE6, #60DCED)',
        },
      }}
      cancelButtonProps={{
        style: {
          borderColor: '#4984F2',
          color: '#4984F2',
          fontWeight: 'bold',
        },
      }}
      title={modalTitle}
      visible={visible}
      onOk={() => onOk(prompt)}
      onCancel={onCancel}
      okText="AI で文章を生成"
      cancelText="キャンセル"
      width="50%"
    >
      {contentBody}
    </Modal>
  );
};
