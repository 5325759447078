import React, { useCallback, useEffect, useState } from 'react';
import { Icon, message, Modal, Tooltip } from 'antd';
import { observer } from 'mobx-react';
import Linkify from 'react-linkify';
import styled, { css } from 'styled-components';
import media from 'styled-media-query';

import Avatar from '../../Common/Avatar';
import level from '../../../font/level';
import * as color from '../../../color';
import { highlightMention } from '../../../util';
import store from 'store';
import { CommentEditor } from './CommentEditor';
import { Comment as CommentEntity, CommentAttachment } from 'lib';
import { assertNonNull } from '../../../utils/type';
import { deleteObject, ref } from 'firebase/storage';
import { storage } from '../../../firebase';
import { Attachments } from './Attachments';
import { arrayRemove, serverTimestamp, updateDoc } from 'firebase/firestore';

const { confirm } = Modal;

const componentDecorator = (href: string, text: string, key: number) => (
  <a href={href} key={key} target="_blank" rel="noopener noreferrer">
    {text}
  </a>
);

type Props = {
  comment: CommentEntity;
  highlight: boolean;
};

export const Comment = observer<React.FC<Props>>(({ comment, highlight }) => {
  const [editingComment, setEditingComment] = useState<CommentEntity | null>(
    null
  );
  const [attachments, setAttachments] = useState(comment.attachments);

  useEffect(() => {
    if (comment.unreadUsers?.includes(store.me.id)) {
      // Mark as read.
      updateDoc(store.doc('comments', comment.id), {
        unreadUsers: arrayRemove(store.me.id),
        updatedAt: serverTimestamp(),
      });
    }
  }, []);

  const commenter = store.getUser(comment.commenter);
  const isAuthor = comment.commenter === store.me.id;
  const isReadOnly = store.me.isReadOnly;

  const onEdit = () => {
    setEditingComment(comment);
  };

  const onChange = (text: string) => {
    setEditingComment({
      ...editingComment,
      text,
    } as CommentEntity);
  };

  const onSave = async () => {
    assertNonNull(editingComment);
    await store.commentStore.updateComment(editingComment.id, {
      text: editingComment.text,
    });
    setEditingComment(null);
  };

  const onDelete = () => {
    Modal.confirm({
      title: 'コメントを削除しますか？',
      content: '一度削除すると元に戻せません。他の人も確認できなくなります。',
      onOk: async () => {
        try {
          await store.commentStore.deleteComment(comment.id);
          message.success('コメントを削除しました');
        } catch (e) {
          message.error('コメントの削除中にエラーが発生しました');
          throw e;
        }
      },
      okText: '削除',
      cancelText: 'キャンセル',
      okType: 'danger',
      maskClosable: true,
    });
  };

  const onRemove = useCallback(
    (attachment: CommentAttachment) => {
      confirm({
        title: `添付ファイル "${attachment.filename}" を削除しますか？`,
        content: '一度削除すると元に戻せません',
        okText: '削除',
        cancelText: 'キャンセル',
        onOk: async () => {
          await deleteObject(ref(storage, attachment.storagePath));
          const newAttachments = attachments.filter(
            (f) => f.storagePath !== attachment.storagePath
          );
          setAttachments(newAttachments);
          await store.commentStore.updateComment(comment.id, {
            attachments: newAttachments,
          });
        },
        okType: 'danger',
        maskClosable: true,
      });
    },
    [comment.id, attachments]
  );

  return (
    <Wrapper highlight={highlight} name={`comment-${comment.id}`}>
      <AvatarWrapper>
        <Avatar user={commenter} />
      </AvatarWrapper>
      <Body>
        <Meta>
          <Commenter>
            {commenter ? commenter.name : '削除されたユーザ'}
          </Commenter>
          <Tooltip title={comment.date.format('YYYY年M月D日 HH:mm')}>
            <CommentedAt>{comment.date.fromNow()}</CommentedAt>
          </Tooltip>
        </Meta>
        {!!editingComment ? (
          <CommentEditor
            comment={editingComment}
            placeholder="チーム内コメント（@でメンション）"
            useMention
            onChange={onChange}
            onCancel={() => setEditingComment(null)}
            onSave={onSave}
          />
        ) : (
          <TextWrapper>
            <Text>
              <Linkify componentDecorator={componentDecorator}>
                {highlightMention(
                  comment.text,
                  store.users,
                  store.groupStore.groups
                )}
              </Linkify>
            </Text>
            {isAuthor && (
              <Tools className="tools">
                <Tooltip title="編集">
                  <Icon type="edit" onClick={onEdit} />
                </Tooltip>
                <Tooltip title="削除">
                  <Icon
                    style={{ marginLeft: '0.5rem' }}
                    type="delete"
                    onClick={onDelete}
                  />
                </Tooltip>
              </Tools>
            )}
          </TextWrapper>
        )}
        <Attachments
          attachments={attachments}
          onRemove={!isReadOnly ? onRemove : undefined}
        />
      </Body>
    </Wrapper>
  );
});

const Wrapper = styled.div<{ highlight: boolean; name: string }>`
  display: flex;
  padding: 10px 20px;
  border-radius: 8px;
  ${({ highlight }) =>
    highlight &&
    css`
      @keyframes border {
        0% {
          box-shadow: 0 0 0 2px rgba(53, 119, 239, 0.2);
        }
        100% {
          box-shadow: 0 0 0 2px ${color.sidebar.background.normal};
        }
      }
      animation: border 1s ease 5 alternate;
    `}
  ${media.greaterThan('small')`
    margin: 12px 0px;
    padding: 0;
  `}
`;

const AvatarWrapper = styled.div`
  margin-top: 2px;
`;

const Body = styled.div`
  flex: 1;
  width: 0;
  margin-left: 10px;
`;

const Meta = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`;

const Commenter = styled.span`
  font-weight: bold;
  padding-right: 5px;
`;

const CommentedAt = styled.span`
  font-size: ${level[0]};
  color: #8d8d8d;
`;

const TextWrapper = styled.div`
  display: flex;
  padding-right: 2rem;

  &:hover .tools {
    opacity: 1;
  }
`;

const Text = styled.div`
  white-space: pre-line;
  word-break: break-all;
  width: fit-content;
  border-radius: 8px;
  background-color: white;
  padding: 8px 12px;
`;

const Tools = styled.div`
  margin-right: -2rem;
  display: flex;
  opacity: 0;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
  font-size: 1rem;

  svg {
    cursor: pointer;
    color: rgb(141, 141, 141);

    &:hover {
      color: rgb(56, 56, 56);
    }
  }
`;
