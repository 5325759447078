import React, { Component } from 'react';
import Scheduled from './Conversation/Scheduled';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';
import styled from 'styled-components';
import * as color from '../../../color';
import media from 'styled-media-query';
import Conversation from './Conversation';
import { SPBackButton } from './Conversation/SPBackButton';
import { Tooltip } from 'antd';

class ScheduledConversation extends Component {
  render() {
    if (this.props.draftsLoading) return null;

    const { draftId } = this.props.match.params;
    const draft = this.props.store.getDraft(draftId);
    if (!draft) return <div />;

    if (draft.isReply) {
      // 返信の場合は会話を表示
      return (
        <Conversation messageId={draft.inReplyToMessageId} key={draft.id} />
      );
    }

    return (
      <Wrapper>
        <Header>
          <SPBackButton backLink="/me/scheduled" />
          <div className="subject">
            <Tooltip title={draft.subject}>{draft.subject}</Tooltip>
          </div>
        </Header>
        <Scheduled draft={draft} />
      </Wrapper>
    );
  }
}

export default compose(
  withRouter,
  inject('store'),
  observer
)(ScheduledConversation);

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  flex: 1;
  overflow: auto;
  background-color: ${color.sidebar.background.normal};

  ${media.lessThan('small')`
    height: 100vh;
  `};
`;

const Header = styled.div`
  position: sticky;
  top: 0;
  background-color: ${color.sidebar.background.normal};
  z-index: 2;
  padding: 10px;

  & > .subject {
    font-size: 18px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;
