import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { Icon, Spin } from 'antd';
import Draft from './Draft';
import SimpleBar from 'simplebar-react';

class Drafts extends Component {
  to = (link) => this.props.history.push(link);

  render() {
    const { searchedDrafts, draftsLoading } = this.props;

    if (draftsLoading) {
      return <Loading />;
    }

    if (searchedDrafts.length === 0) {
      return null;
    }

    return (
      <SimpleBar className="h-full">
        <div className="pt-4">
          {searchedDrafts.map((draft) => (
            <Draft
              draft={draft}
              toFunc={this.to}
              to={
                draft.isReply
                  ? `/me/drafts/${draft.id}/teams/${draft.teamId}/inboxes/${draft.inboxId}`
                  : `/me/drafts/${draft.id}`
              }
              key={draft.id}
            />
          ))}
        </div>
      </SimpleBar>
    );
  }
}

const Loading = () => {
  return (
    <Center>
      <Spin indicator={<Icon type="loading" spin />} />
    </Center>
  );
};

const Center = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default compose(withRouter, inject('store'), observer)(Drafts);
