import React from 'react';
import { MainNavItem } from './MainNavItem';
import {
  Help,
  Insights,
  Menu,
  Patient,
  ServiceLogo,
  Settings,
} from '../../../components/icons';
import { NavLink, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import { MainSearch } from './MainSearch';
import { Icon } from '../../../components/basics';
import { useTopPagePath } from '../../../hooks/useTopPagePath';
import { useStore } from 'hooks/useStore';
import { PCOnly, TabletOnly } from '../MediaQuery';
import { MobileNavButton } from '../../Top/MobileNavButton';
import { MainNavTopButton } from './MainNavTopButton';
import SvgSend from 'components/icons/Send';

export const MAIN_NAV_HEIGHT = 64;

export const MainNav = observer(() => {
  const location = useLocation();
  const store = useStore();
  const topPagePath = useTopPagePath(store);
  const handleClickChannelItem = () => {
    store.channelStore.showMessenger();
  };

  return (
    <div className="relative flex h-header items-center xl:justify-center">
      <PCOnly>
        <div className="flex items-center xl:absolute xl:left-0">
          <div className="flex items-center gap-6">
            <div className="mx-4">
              <NavLink
                to={topPagePath}
                className="flex h-full w-full items-center justify-center"
              >
                <Icon icon={ServiceLogo} className="text-sea-500" />
              </NavLink>
            </div>
            <MainNavTopButton />
            <MainNavItem to="/reports">
              <Icon icon={Insights} className="text-sumi-900" />
            </MainNavItem>
            <MainNavItem to="/contacts">
              <Icon icon={Patient} className="font-bold text-sumi-900" />
            </MainNavItem>
            <div className="group flex gap-0.5">
              <SvgSend />
              <p className="m-0 font-bold leading-3 text-sea-500	">
                Coming
                <br />
                soon
              </p>
              <div className="absolute left-[80px] top-[62px] hidden w-[400px] rounded bg-sumi-600 p-[12px] text-white group-hover:block">
                <p className="m-0 text-center	text-sm	">
                  8月上旬にメール一斉配信機能をリリース予定です！
                  <br />
                  ご興味ある方は資料をお送りするので右上のサポート
                  <br />
                  チャットからお問い合わせください。
                </p>
              </div>
            </div>
          </div>
        </div>
      </PCOnly>
      <TabletOnly>
        <div className="pl-4">
          <MobileNavButton icon={Menu} onClick={store.openDrawer} />
        </div>
      </TabletOnly>
      <div className="h-10 flex-1 px-4 xl:w-1/2 xl:flex-none">
        <MainSearch />
      </div>
      <div className="mr-4 flex items-center gap-3 xl:absolute xl:right-0">
        {store.channelStore.canShowChannel(location.pathname) ? (
          <MainNavItem
            onClick={handleClickChannelItem}
            badge={store.channelStore.unreadCount}
          >
            <Icon icon={Help} className="text-sumi-900" />
          </MainNavItem>
        ) : null}
        <PCOnly>
          <MainNavItem to="/settings" label="設定">
            <Icon icon={Settings} className="text-sumi-900" />
          </MainNavItem>
        </PCOnly>
      </div>
    </div>
  );
});
