import { BubbleMenu, Editor } from '@tiptap/react';
import { tv } from 'tailwind-variants';
import { useEffect, useState } from 'react';
import { atom, useAtom, useSetAtom } from 'jotai';
import { atomWithReducer } from 'jotai/utils';
import { uniq } from 'lodash';

type Props = {
  editor: Editor | null;
  bubbleMenu?: boolean;
  defaultUrl?: string;
  hidden?: boolean;
  getContainer?: () => HTMLElement;
};

type Action =
  | {
      type: 'add';
      editor: Editor;
    }
  | {
      type: 'remove';
      editor: Editor;
    };

const menu = tv({
  base: 'flex h-8 w-fit items-center rounded border border-sumi-300 bg-white text-xs text-sumi-500 shadow',
});

const editButton = tv({
  base: 'cursor-pointer select-none bg-transparent px-0 text-sea-500',
});

export const newLinkTextAtom = atom<string | undefined>(undefined);
export const linkEditingEditorsAtom = atomWithReducer<Editor[], Action>(
  [],
  (prev, action) => {
    switch (action?.type) {
      case 'add':
        return uniq([...prev, action.editor]);
      case 'remove':
        return prev.filter((e) => e !== action.editor);
      default:
        return prev;
    }
  }
);

export const LinkBubbleMenu = ({
  editor,
  defaultUrl = '',
  hidden,
  getContainer,
}: Props) => {
  const [editing, setEditing] = useState(false);
  const dispatchLinkEditingEditors = useSetAtom(linkEditingEditorsAtom);
  const href = editor?.getAttributes('link').href ?? defaultUrl;
  const [inputValue, setInputValue] = useState(href);
  const [newLinkText, setNewLinkText] = useAtom(newLinkTextAtom);
  useEffect(() => {
    setEditing(false);
  }, [href]);
  useEffect(() => {
    if (!editor) {
      return;
    }
    const type = editing || newLinkText ? 'add' : 'remove';
    dispatchLinkEditingEditors({ type, editor });
  }, [editor, editing, newLinkText, dispatchLinkEditingEditors]);
  useEffect(() => {
    if (!editor) {
      return;
    }

    const onUpdate = () => {
      if (newLinkText) {
        setNewLinkText('');
      }
    };
    editor.on('selectionUpdate', onUpdate);
    return () => {
      editor.off('selectionUpdate', onUpdate);
    };
  }, [editor, newLinkText]);
  useEffect(() => {
    if (newLinkText) {
      setInputValue(newLinkText);
    }
  }, [newLinkText]);
  const editingContent = (
    <form
      action=""
      className="grid grid-cols-[auto_1fr_auto] items-center gap-2 px-2"
      onSubmit={(e) => {
        e.stopPropagation();
        e.preventDefault();
        if (!editor) {
          return;
        }

        if (newLinkText) {
          editor.chain().focus().setLink({ href: inputValue }).run();
          setEditing(false);
        } else {
          editor
            .chain()
            .extendMarkRange('link')
            .setLink({ href: inputValue })
            .run();
          setEditing(false);
        }
      }}
    >
      <div className="select-none">URL:</div>
      <input
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        className="w-[180px] rounded border border-sumi-300 text-sumi-900 focus-visible:border-sea-500 focus-visible:outline-none"
        autoFocus
      />
      <button type="submit" className={editButton()}>
        保存
      </button>
    </form>
  );
  const content = (
    <div className="grid grid-cols-[auto_1fr_auto_auto] items-center gap-2 px-2">
      <div className="select-none">URL:</div>
      <a
        href={href}
        rel="noopener noreferrer"
        target="_blank"
        className="max-w-[200px] truncate whitespace-nowrap text-sea-500"
      >
        {href}
      </a>
      <button
        type="button"
        className={editButton()}
        onClick={() => {
          setInputValue(href);
          setEditing(true);
        }}
      >
        編集
      </button>
      <button
        type="button"
        className={editButton()}
        onClick={() => editor?.commands.unsetLink()}
      >
        削除
      </button>
    </div>
  );
  return editor ? (
    <div>
      <BubbleMenu
        pluginKey="linkMenu"
        editor={editor}
        className={menu()}
        shouldShow={({ editor }) => editor.isActive('link') && !hidden}
        tippyOptions={{
          onHide: () => setEditing(false),
          appendTo: getContainer?.(),
        }}
      >
        {editing ? editingContent : content}
      </BubbleMenu>
      <BubbleMenu
        pluginKey="newLinkMenu"
        editor={editor}
        shouldShow={({ editor }) => !editor.isActive('link') && !hidden}
        tippyOptions={{
          appendTo: getContainer?.(),
        }}
      >
        {newLinkText && <div className={menu()}>{editingContent}</div>}
      </BubbleMenu>
    </div>
  ) : (
    <div className={menu()}>{editing ? editingContent : content}</div>
  );
};
