import {
  EditorHandle,
  WysiwygEditor,
} from '../../../Common/Editor/WysiwygEditor/WysiwygEditor';
import React, { useEffect, useState } from 'react';
import { EditorToolbarWithControls } from '../../../Common/Editor/EditorToolbar/EditorToolbarWithControls';
import SimpleBar from 'simplebar-react';
import { debounce } from 'lodash';
import * as Portal from '@radix-ui/react-portal';
import Draggable from 'react-draggable';
import { Icon } from '../../../../components/basics';
import { Close } from '../../../../components/icons';
import { Alert } from '../../../../components/basics/Alert/Alert';
import { reservedWords } from '../../../Common/Editor/util';

type Props = {
  placeholder?: string;
  defaultValue?: string;
  onChange: (plainText: string, html: string) => void;
  disabled?: boolean;
};

const RESERVED_WORDS = [
  {
    name: '送信先の名前',
    description: '送信先のメールアドレスに設定されている名前',
    reservedWord: reservedWords.toName,
  },
  {
    name: '送信先のメールアドレス',
    description: '送信先のメールアドレス',
    reservedWord: reservedWords.toEmail,
  },
  {
    name: 'ログインユーザの名前',
    description: 'いまログインしているご自身の名前',
    reservedWord: reservedWords.meName,
  },
  {
    name: 'ログインユーザのメールアドレス',
    description: 'いまログインしているご自身のメールアドレス',
    reservedWord: reservedWords.meEmail,
  },
  {
    name: '返信元の名前',
    description: '返信元のメールアドレスに設定している名前',
    reservedWord: reservedWords.fromName,
  },
  {
    name: '返信元のメールアドレス',
    description: '返信元のメールアドレス',
    reservedWord: reservedWords.fromEmail,
  },
] as const;

export const SettingsWysiwygEditor = ({
  placeholder,
  defaultValue,
  onChange,
  disabled,
}: Props) => {
  const [handle, setHandle] = useState<EditorHandle | undefined>();
  const [showReservedWords, setShowReservedWords] = useState(false);
  useEffect(() => {
    const editor = handle?.editor;
    if (!editor) {
      return;
    }
    const onUpdate = debounce(
      () => {
        onChange(handle.getText(), handle.getHtml());
      },
      200,
      {
        maxWait: 1000,
      }
    );
    editor.on('update', onUpdate);
    return () => {
      editor.off('update', onUpdate);
    };
  }, [handle?.editor]);
  return (
    <>
      <div className="rounded border border-sumi-200 bg-white">
        <SimpleBar
          className="h-52"
          classNames={{
            contentEl: 'simplebar-content h-full',
          }}
        >
          <WysiwygEditor
            placeholder={placeholder}
            defaultValue={defaultValue}
            initEditorHandle={setHandle}
            disabled={disabled}
            editorClassName="px-2.5 py-2"
          />
        </SimpleBar>
        <div className="mx-2.5 h-[1px] bg-sumi-200" />
        <div className="grid grid-cols-[1fr_auto] items-center gap-2 p-2.5">
          <EditorToolbarWithControls
            handle={handle}
            disabled={disabled}
            closable={false}
          />
          <button
            type="button"
            className="cursor-pointer bg-transparent p-0"
            onClick={() => {
              setShowReservedWords(!showReservedWords);
              handle?.editor?.commands.focus();
            }}
            disabled={disabled}
          >
            予約語
          </button>
        </div>
      </div>
      {showReservedWords && (
        <Portal.Root>
          <Draggable bounds="body" handle={`[data-reserved-words-handle]`}>
            <div className="absolute bottom-8 right-8 z-50 w-[600px] max-w-full overflow-hidden rounded-lg bg-white text-sm shadow-lg">
              <div
                className="grid h-9 cursor-move select-none grid-cols-[1fr_auto] items-center justify-between bg-sumi-100 pl-3"
                data-reserved-words-handle={true}
                onClick={() => handle?.editor?.commands.focus()}
              >
                <span>予約語</span>
                <button
                  type="button"
                  onClick={() => setShowReservedWords(false)}
                  className="flex h-9 w-9 cursor-pointer items-center justify-center bg-transparent p-0"
                >
                  <Icon icon={Close} size={12} />
                </button>
              </div>
              <SimpleBar className="max-h-[50vh] max-w-full">
                <div className="p-4">
                  <Alert className="mb-4">
                    テンプレート使用時に「送信先の名前」などを自動でメール本文に埋め込むことができる機能です。
                    <br />
                    ※情報がない場合は空欄で表示されます。
                  </Alert>
                  <table className="w-full table-auto">
                    <thead>
                      <tr>
                        <th className="px-2 py-4">予約語</th>
                        <th className="px-2 py-4">説明</th>
                      </tr>
                    </thead>
                    <tbody>
                      {RESERVED_WORDS.map((word, i) => (
                        <tr key={i} className="border-t border-t-sumi-300">
                          <td className="px-2 py-3">
                            <button
                              type="button"
                              className="cursor-pointer bg-transparent p-0 text-start text-sea-500 hover:underline"
                              onClick={() =>
                                handle?.insertText(word.reservedWord)
                              }
                            >
                              {word.name}
                            </button>
                          </td>
                          <td className="px-2 py-3">{word.description}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </SimpleBar>
            </div>
          </Draggable>
        </Portal.Root>
      )}
    </>
  );
};
