/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createMessageLike, MessageLike, Thread, threadConverter } from 'lib';
import { Store } from 'store';

import { FieldPath } from '../firebase';
import { subscribeQueryInArray } from '../utils/firestore';
import { onSnapshot } from 'firebase/firestore';
import { companyDoc } from '../firestore';

export class ThreadStore {
  constructor(private rootStore: Store) {}

  get messageFilterStore() {
    return this.rootStore.messageFilterStore;
  }

  collection() {
    return this.rootStore.companyCollection('threads');
  }

  /**
   * Convert a thread document to a Message-like object, to be rendered by
   * MessageList.
   */
  threadToMessage(thread: any, viewingDeleted?: boolean): MessageLike {
    const messageLike = createMessageLike(thread.ref, thread.data());
    return viewingDeleted ? messageLike.toDeletedThread() : messageLike;
  }

  async syncStarredThreads() {
    const threadIds = this.rootStore.privateStore.starredThreadIdsOrderByDate
      .slice(0, this.rootStore.starredMessagesSize)
      .filter((id) => !this.rootStore.starredMessages.some((x) => x.id === id));
    if (!threadIds.length) {
      this.rootStore.starredMessagesLoading = false;
      return;
    }

    const unsubscribeStarredThreads = subscribeQueryInArray(
      this.collection().where('deleted', '==', false),
      FieldPath.documentId(),
      threadIds,
      (docs: any) => {
        if (docs.length === 0) {
          return;
        }
        const msgs = docs.map((doc: any) => this.threadToMessage(doc));
        this.rootStore.starredMessages = msgs;
        this.rootStore.starredMessagesLoading = false;
      }
    );
    this.rootStore.unsubscribeStarredMessages.push(unsubscribeStarredThreads);
  }

  async syncThread(
    threadId: string,
    deleted: boolean,
    callback: (thread: Thread) => void
  ) {
    return onSnapshot(
      companyDoc('threads', threadId, threadConverter),
      (doc) => {
        const thread = doc.data()!;
        return callback(deleted ? thread.toDeletedThread() : thread);
      }
    );
  }
}
