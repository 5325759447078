import {
  Comment,
  commentConverter,
  MessageLike,
  messageLikeConverter,
} from 'lib';
import { companyCollection, companyDoc } from '../../firestore';
import { getDoc, orderBy, query, where } from 'firebase/firestore';
import { store } from '../../providers/StoreProvider';
import { meAtom, threadViewAtom } from '../auth';
import { atomWithPaginate, PaginateAtom } from '../../utils/atom';
import _ from 'lodash';
import { atom } from 'jotai';
import { joinedTeamIdsAtom } from './team';
import { messagesPathAtom } from './message';

export const mentionedMessageMapAtom = atom<Record<string, MessageLike>>({});

const mentionedCommentsAtom: PaginateAtom<Comment> = atomWithPaginate(
  () =>
    query(
      companyCollection('comments', commentConverter),
      where('teamId', 'in', store.get(joinedTeamIdsAtom)),
      where('mentionedUsers', 'array-contains', store.get(meAtom).id),
      orderBy('createdAt', 'desc')
    ),
  async (snapshot) => {
    const threadView = store.get(threadViewAtom);
    const idField = threadView ? 'threadId' : 'messageId';
    const comments = snapshot.docs.map((doc) => doc.data());
    const groupedComments = _.uniqBy(comments, idField);

    const messageMap = store.get(mentionedMessageMapAtom);
    for (const comment of groupedComments) {
      if (comment[idField] in messageMap) {
        continue;
      }
      try {
        const message = (
          await getDoc(
            companyDoc(
              store.get(messagesPathAtom),
              comment[idField],
              messageLikeConverter
            )
          )
        ).data();
        if (message) {
          if (!threadView || !message.deleted) {
            store.set(mentionedMessageMapAtom, (prev) => ({
              ...prev,
              [message.id]: message,
            }));
          }
        }
      } catch (e) {
        console.error(
          `mentioned message not found: commentId=${comment.id} messageId=${comment[idField]}`
        );
      }
    }
    return groupedComments;
  }
);

type MentionedMessage = {
  comment: Comment;
  message: MessageLike;
};

export const mentionedMessagesAtom: PaginateAtom<MentionedMessage> = atom(
  (get) => {
    const threadView = get(threadViewAtom);
    const idField = threadView ? 'threadId' : 'messageId';
    const mentionedCommentsPaginate = get(mentionedCommentsAtom);
    const messageMap = get(mentionedMessageMapAtom);
    if (mentionedCommentsPaginate.state !== 'hasData') {
      return mentionedCommentsPaginate;
    }
    const mentionedComments = mentionedCommentsPaginate.data;
    mentionedComments.sort(
      (a, b) => b.createdAt.toMillis() - a.createdAt.toMillis()
    );
    return {
      ...mentionedCommentsPaginate,
      data: mentionedComments.flatMap((comment) => {
        const message = messageMap[comment[idField]];
        return message
          ? {
              comment,
              message,
            }
          : [];
      }),
    };
  },
  (get, set, action) => set(mentionedCommentsAtom, action)
);
