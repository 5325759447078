import { cloneElement, ReactElement, useMemo } from 'react';

type Props = {
  label: string | ReactElement;
  description?: string | ReactElement;
  required?: boolean;
  errorMessage?: string;
  children: ReactElement;
};

export const InputGroup = ({
  label,
  description,
  required,
  errorMessage,
  children: originalChildren,
}: Props) => {
  const isInput =
    typeof originalChildren.type === 'object' &&
    (originalChildren.type as { displayName: string }).displayName === 'Input';
  const children = useMemo(() => {
    if (isInput && errorMessage) {
      return cloneElement(originalChildren, { error: true, required });
    }
    return originalChildren;
  }, [originalChildren, errorMessage]);
  return (
    <div className="flex flex-col gap-2">
      <div className="grid grid-cols-[auto_1fr] gap-2 text-sm">
        {typeof label === 'string' ? <span>{label}</span> : label}
        <span className="text-sun-500">{required && '必須'}</span>
      </div>
      <div>{children}</div>
      {description && (
        <div className="text-xs text-sumi-500">{description}</div>
      )}
      {errorMessage && (
        <div className="text-sm text-sun-500">{errorMessage}</div>
      )}
    </div>
  );
};
