import moment from 'moment';
import {
  addSizeToImageTag,
  addTargetAndRelAttrToAnchorTag,
  convertHtmlToQuote,
  decorateHtml,
  sanitizeHtml,
} from '../util';
import { CommentData } from './comment';
import { InboxData } from './inbox';
import { Timestamp } from '@firebase/firestore-types';
import { createConverter, createEntityFactory } from '../converter';
import { DocumentReference, FirestoreDataConverter } from 'firebase/firestore';
import { createThread, ThreadData } from './thread';

export const Impression = ['unknown', 'neutral', 'complaint'] as const;
export type Impression = typeof Impression[number];

export interface MessageReaders {
  [userId: string]: Timestamp;
}

export interface MessageLikeData {
  from: MessageFrom;
  subject: string;
  isAutoReplied: boolean;
  deleted: boolean;
  tags: string[];
  teamId: string;
  text: string;
  assignee: string | null;
  readers: MessageReaders;
  attachments: MessageAttachment[];
  inboxId: string;
  status: MessageStatus;
  impression?: Impression;
  date: Timestamp;
  latestComment: MessageLatestComment | null;
  updatedAt: Timestamp;
  createdAt: Timestamp;
}

export const createMessageLike = createEntityFactory(
  'MessageLike',
  (data: MessageLikeData, ref) => ({
    date: moment(data.date.toMillis()),

    get tags() {
      return data.tags || [];
    },

    toDeletedThread: () =>
      createMessageLike(ref, {
        ...((data as ThreadData).deletedThread as ThreadData),
        teamId: data.teamId,
        inboxId: data.inboxId,
        deleted: true,
      }),

    asMessage: () =>
      createMessage(ref as DocumentReference<MessageData>, data as MessageData),

    asThread: () =>
      createThread(ref as DocumentReference<ThreadData>, data as ThreadData),
  })
);

export type MessageLike = ReturnType<typeof createMessageLike>;

export const messageLikeConverter: FirestoreDataConverter<MessageLike> =
  createConverter(createMessageLike);

type Readable = {
  readers?: { [userId: string]: Timestamp };
};

export const isUnread = (message: Readable, userId: string): boolean => {
  return !message.readers?.[userId];
};

export const isUnreadComment = (
  message: Readable,
  comment: CommentData,
  userId: string
): boolean => {
  const readTime = message.readers?.[userId];
  return !readTime || readTime.toMillis() < comment.createdAt.toMillis();
};

export interface MessageData extends MessageLikeData {
  type: string;
  messageId: string;
  to?: MessageRecipients;
  cc?: MessageRecipients;
  bcc?: MessageRecipients;
  replyTo?: MessageRecipients;
  referenceAddresses: string[];
  references: string[];
  textAsHtml: string;
  textAsHtmlStoragePath: string;
  textStoragePath: string;
  inReplyTo: string | null;
  deletedBy?: string;
  html: string;
  htmlStoragePath: string;
  originalEmlStoragePath: string;
  emlStoragePath: string;
  emlStorageSize: number;
  storagePath: string;
  snippet: string;
  threadId: string;
  inboxRef: DocumentReference<InboxData>;
  deletedAt: Timestamp;
}

export const MessageStatus = {
  Unprocessed: '未対応',
  Processed: '対応済み',
  None: '',
} as const;

export type MessageStatus = string;

export type MessageAttachment = {
  contentDisposition: string;
  contentType: string;
  filename: string;
  related: boolean;
  size: number;
  storagePath: string;
};

export type MailAddress = {
  address: string;
  name?: string;
};

export type MessageFrom = {
  html?: string;
  text?: string;
  value: MailAddress[];
};

export type MessageRecipients = {
  html: string;
  text: string;
  value: MailAddress[];
};

export type MessageLatestComment = {
  commentId: string;
} & CommentData;

export const messageCompareFn = (a: Message, b: Message) =>
  b.date.valueOf() - a.date.valueOf() || (b.id > a.id ? -1 : 1);

export const createMessage = createEntityFactory(
  'Message',
  (data: MessageData) => ({
    date: moment(data.date.toMillis()),

    get hasAttachments() {
      return data.attachments ? data.attachments.length > 0 : false;
    },

    get fromValue() {
      if (data.from.value.length === 0) {
        return null;
      }
      return data.from.value[0];
    },

    get fromText() {
      return data.from.text || '';
    },

    get fromIconName() {
      return this.fromText.length > 0 ? this.fromText[0].toUpperCase() : '';
    },

    _decoratedHtml: undefined as string | undefined,
    get html() {
      const html = data.html;
      if (!html) return html;
      if (!this._decoratedHtml) this._decoratedHtml = decorateHtml(html);
      return this._decoratedHtml;
    },

    get htmlForQuote() {
      if (!data.html) return data.html; // html対応ではないメールの場合
      return convertHtmlToQuote(data.html);
    },

    get replyAddress() {
      if (!data.replyTo || !this.replyToValue) {
        // replyToがない場合
        return this.fromText;
      }

      const replyToText = data.replyTo.text;
      const replyToAddress = this.replyToValue.address;
      const replyToName = this.replyToValue.name;
      const fromAddress = this.fromValue?.address;

      if (replyToAddress !== fromAddress) {
        // replyToとfromの値が異なる場合
        return replyToText;
      }

      if (replyToName) {
        // replyToのnameが設定されている場合
        return replyToText;
      }

      // replyToとfromの値が同じ
      return this.fromText;
    },

    get replyToValue() {
      if (!data.replyTo?.value || data.replyTo.value.length === 0) {
        return null;
      }
      return data.replyTo.value[0];
    },

    get subject() {
      return data.subject || '';
    },

    get text() {
      return data.text || ''; // textがundefinedの場合がある。（例: Thunderbirdから転送された場合）
    },

    _sanitizedTextAsHtml: undefined as string | undefined,
    get sanitizedTextAsHtml() {
      if (!this._sanitizedTextAsHtml) {
        this._sanitizedTextAsHtml = addTargetAndRelAttrToAnchorTag(
          addSizeToImageTag(sanitizeHtml(data.textAsHtml))
        );
      }
      return this._sanitizedTextAsHtml;
    },

    get toAddresses() {
      if (!data.to) return [];
      return (
        data.to.value
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .filter((c: any) => Boolean(c.address)) // "recipient list not shown: ;"などの場合、addressがundefinedになるケースがあるため、除外している
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .map((c: any) =>
            c.name.length > 0 ? `${c.name} <${c.address}>` : c.address
          )
      );
    },

    get ccAddresses() {
      if (!data.cc) return [];
      return (
        data.cc.value
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .filter((c: any) => Boolean(c.address)) // "recipient list not shown: ;"などの場合、addressがundefinedになるケースがあるため、除外している
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .map((c: any) =>
            c.name.length > 0 ? `${c.name} <${c.address}>` : c.address
          )
      );
    },

    get bccAddresses() {
      if (!data.bcc) return [];
      return (
        data.bcc.value
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .filter((c: any) => Boolean(c.address)) // "recipient list not shown: ;"などの場合、addressがundefinedになるケースがあるため、除外している
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .map((c: any) =>
            c.name.length > 0 ? `${c.name} <${c.address}>` : c.address
          )
      );
    },

    get readers() {
      return data.readers || {};
    },

    get assignee() {
      return data.assignee || null;
    },

    get tags() {
      return data.tags || [];
    },

    get inReplyTo() {
      return data.inReplyTo || null;
    },

    get references() {
      return data.references || [];
    },

    generateQuotedHeader() {
      return `${this.date.format('YYYY年M月D日(ddd) HH:mm')} ${
        this.fromText
      }:\n`;
    },

    generateForwardedHeader() {
      let body = `---------- Forwarded message ---------
From: ${data.from.text}
Date: ${this.date.format('YYYY年M月D日(ddd) HH:mm')}
Subject: ${this.subject}
To: ${data.to?.text || ''}
`;

      if (data.cc) {
        body += `Cc: ${data.cc.text}\n`;
      }
      return body + `\n`;
    },
  })
);

export type Message = ReturnType<typeof createMessage>;

export const messageConverter = createConverter(createMessage);
