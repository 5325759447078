import * as Select from '@radix-ui/react-select';
import * as Toolbar from '@radix-ui/react-toolbar';
import { Icon } from '../../../../components/basics';
import { CaretDown } from '../../../../components/icons';
import React, { useMemo, useState } from 'react';
import { twMerge } from 'tailwind-merge';

type Props<T> = {
  value: T;
  onChange: (value: T) => void;
  options: {
    value: T;
    label: string;
  }[];
  disabled?: boolean;
  labelRenderer?: (value: T) => JSX.Element;
  className?: string;
};

export const ToolbarSelect = <T,>({
  value,
  options,
  onChange,
  disabled,
  labelRenderer,
  className,
}: Props<T>) => {
  const [open, setOpen] = useState(false);
  const current = useMemo(
    () => options.find((v) => v.value === value),
    [options, value]
  );
  const currentIndex = useMemo(
    () => options.findIndex((v) => v.value === value),
    [options, value]
  );
  return (
    <Select.Root
      value={(currentIndex ?? 0).toString()}
      onValueChange={(v) => {
        if (!v) {
          return;
        }
        const selected = options[parseInt(v)].value;
        onChange(selected);
      }}
      onOpenChange={setOpen}
      disabled={disabled}
    >
      <Select.Trigger asChild>
        <Toolbar.Button
          className={twMerge(
            'grid h-7 cursor-pointer select-none grid-cols-[1fr_auto] items-center justify-between gap-1 rounded-lg border border-sumi-300 bg-transparent px-2 text-xs text-sumi-900 focus-visible:outline-1 focus-visible:outline-sea-500/50 enabled:hover:bg-sumi-50 disabled:cursor-not-allowed disabled:text-sumi-300',
            className
          )}
        >
          <span className="truncate whitespace-nowrap text-start">
            {labelRenderer && current
              ? labelRenderer(current.value)
              : current?.label}
          </span>
          <Icon
            icon={CaretDown}
            className={'transform ' + (open ? 'rotate-180' : '')}
          />
        </Toolbar.Button>
      </Select.Trigger>

      <Select.Portal className="z-[1001]">
        <Select.Content
          position="popper"
          className="z-50 flex max-h-[40dvh] min-w-[100px] flex-col gap-2 overflow-hidden rounded-lg bg-white px-2.5 shadow-dropdown"
          onCloseAutoFocus={(e) => e.preventDefault()}
        >
          <Select.ScrollUpButton className="absolute left-0 top-0 z-10 flex h-5 w-full cursor-default items-center justify-center rounded-lg bg-white">
            <Icon icon={CaretDown} className="rotate-180" />
          </Select.ScrollUpButton>
          <Select.Viewport className="flex flex-col gap-[1px] py-2.5">
            {options.map((option, i) => (
              <Select.Item
                key={i}
                value={i.toString()}
                className="flex h-7 min-h-[28px] w-full cursor-pointer items-center whitespace-nowrap rounded-lg px-1.5 py-1 text-left text-sm leading-none outline-none data-[state=checked]:bg-sumi-200 data-[state=checked]:hover:bg-sumi-300 data-[state=unchecked]:hover:bg-sumi-100 data-[state=checked]:focus-visible:bg-sumi-300 data-[state=unchecked]:focus-visible:bg-sumi-100"
              >
                {labelRenderer ? labelRenderer(option.value) : option.label}
                <Select.ItemIndicator />
              </Select.Item>
            ))}
          </Select.Viewport>
          <Select.ScrollDownButton className="absolute bottom-0 left-0 z-10 flex h-5 w-full cursor-default items-center justify-center rounded-lg bg-white">
            <Icon icon={CaretDown} />
          </Select.ScrollDownButton>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  );
};
