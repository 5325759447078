import * as React from 'react';
import type { SVGProps } from 'react';
const SvgCaretLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={8}
    height={12}
    fill="none"
    viewBox="0 0 8 12"
    {...props}
  >
    <path
      fill="currentColor"
      d="M.925 6c0-.1.017-.192.05-.275a.762.762 0 0 1 .175-.25L6.1.525c.134-.133.313-.196.538-.187.225.008.404.079.537.212.167.167.238.346.213.538a.94.94 0 0 1-.238.512L2.75 6l4.4 4.4a.816.816 0 0 1 .225.538.592.592 0 0 1-.225.537.551.551 0 0 1-.525.213.972.972 0 0 1-.55-.238L1.15 6.525a.762.762 0 0 1-.175-.25A.734.734 0 0 1 .925 6Z"
    />
  </svg>
);
export default SvgCaretLeft;
