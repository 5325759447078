import {
  TAG_COLORS,
  TagColor,
} from '../../../../../components/basics/Tag/colors';
import { useConfirmDialog } from '../../../../../hooks/confirmDialog';
import { Controller, useForm } from 'react-hook-form';
import { SettingPageDrawer } from '../../../common/SettingPageDrawer/SettingPageDrawer';
import { InputGroup } from '../../../../../components/forms/InputGroup/InputGroup';
import { Input, Select } from '../../../../../components/forms';
import { Button } from '../../../../../components/basics';
import { Switch } from '../../../../../components/basics/Switch/Switch';
import { Tag } from '../../../../../components/basics/Tag/Tag';

type TagEntry = {
  name: string;
  parentId: string | null;
  color: TagColor | null;
};

type Form = TagEntry & {
  showParentInput: boolean;
};

export type TagUpdate = TagEntry;

type Props = {
  tagId: string | undefined;
  tag: Partial<TagEntry>;
  parentTargetTags: {
    id: string;
    name: string;
    color: TagColor | null;
  }[];
  onUpdate: (id: string | undefined, update: TagUpdate) => Promise<void>;
  onDelete: (id: string) => Promise<void>;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  readonly: boolean;
  container?: HTMLElement;
};

export const TagEditDrawer = ({
  tagId,
  tag,
  parentTargetTags,
  onUpdate,
  onDelete,
  open,
  onOpenChange,
  readonly,
  container,
}: Props) => {
  const openDialog = useConfirmDialog();
  const {
    register,
    control,
    handleSubmit,
    formState: { isSubmitting, isDirty },
    watch,
    reset,
  } = useForm<Form>({
    defaultValues: {
      name: tag.name ?? '',
      showParentInput: tag.parentId != null,
      parentId: tag.parentId ?? null,
      color: tag.color ?? null,
    },
  });
  const onSubmit = async (update: Form) => {
    try {
      await onUpdate(tagId, {
        name: update.name,
        color: update.color,
        parentId: update.showParentInput ? update.parentId : null,
      });
      reset(update);
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <SettingPageDrawer
      title={tagId ? 'タグ編集' : 'タグ作成'}
      open={open}
      onOpenChange={onOpenChange}
      container={container}
    >
      <form
        action=""
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col gap-4 text-sm"
      >
        <Controller
          name="name"
          control={control}
          rules={{
            required: '入力してください',
          }}
          render={({ fieldState }) => (
            <InputGroup
              label="名前"
              description="例）重要、後で返信"
              errorMessage={fieldState.error?.message}
            >
              <div
                className={
                  watch('showParentInput')
                    ? 'grid grid-cols-[1fr_2fr] gap-2'
                    : ''
                }
              >
                {watch('showParentInput') && (
                  <Controller
                    name="parentId"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <Select
                        value={value}
                        onChange={onChange}
                        placeholder="親タグなし"
                        options={[
                          { value: null, label: '親タグなし' },
                          ...parentTargetTags
                            .filter((p) => p.id !== tagId)
                            .map((p) => ({
                              value: p.id,
                              label: p.name,
                            })),
                        ]}
                        renderLabel={(value, label) => {
                          if (value == null) {
                            return undefined;
                          }
                          const find = parentTargetTags.find(
                            (p) => p.id === value
                          );
                          return <Tag color={find?.color}>{label}</Tag>;
                        }}
                        renderOption={(value, label) => {
                          if (value == null) {
                            return label;
                          }
                          const find = parentTargetTags.find(
                            (p) => p.id === value
                          );
                          return <Tag color={find?.color}>{label}</Tag>;
                        }}
                        className="h-10"
                        variants={{ rounded: 'lg', width: 'full' }}
                        disabled={readonly || isSubmitting}
                      />
                    )}
                  />
                )}
                <Input
                  {...register('name', {
                    setValueAs: (v) => (typeof v === 'string' ? v.trim() : v),
                  })}
                  placeholder="重要"
                  disabled={readonly}
                  autoFocus
                />
              </div>
            </InputGroup>
          )}
        />
        <div>
          <Controller
            name="showParentInput"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Switch
                value={value}
                onChange={onChange}
                label="親タグを設定する"
              />
            )}
          />
        </div>
        <InputGroup label="色">
          <Controller
            name="color"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Select
                value={value}
                onChange={onChange}
                renderLabel={(value) => {
                  if (!watch('name')) {
                    return '';
                  }
                  const parent = parentTargetTags.find(
                    (t) => t.id === watch('parentId')
                  );
                  const prefix = parent ? `${parent.name}/` : '';
                  return (
                    <Tag color={value}>
                      {prefix}
                      {watch('name')}
                    </Tag>
                  );
                }}
                renderOption={(value) => {
                  const parent = parentTargetTags.find(
                    (t) => t.id === watch('parentId')
                  );
                  const prefix = parent ? `${parent.name}/` : '';
                  return (
                    <Tag color={value}>
                      {prefix}
                      {watch('name')}
                    </Tag>
                  );
                }}
                options={[
                  { value: null, label: '' },
                  ...TAG_COLORS.map((c) => ({ value: c, label: c })),
                ]}
                className="h-10"
                variants={{ rounded: 'lg', width: 'full' }}
                disabled={readonly || isSubmitting || !watch('name')}
              />
            )}
          />
        </InputGroup>
        <div className="flex gap-4">
          {tagId ? (
            <>
              <Button
                color="danger"
                disabled={readonly || isSubmitting}
                onClick={() =>
                  openDialog({
                    title: 'タグを削除しますか？',
                    description: '一度削除すると元に戻せません',
                    okType: 'danger',
                    okText: '削除',
                    onOk: async () => await onDelete(tagId),
                  })
                }
              >
                削除
              </Button>
              <Button
                type="submit"
                disabled={readonly || !isDirty}
                loading={isSubmitting}
              >
                更新
              </Button>
            </>
          ) : (
            <Button
              type="submit"
              disabled={readonly || !isDirty}
              loading={isSubmitting}
            >
              作成
            </Button>
          )}
        </div>
      </form>
    </SettingPageDrawer>
  );
};
