import Encoding from 'encoding-japanese';
import { normalizeStr, WriteBatch } from 'lib';
import firebase, { db } from '../../../firebase';

export const csvFieldNames = [
  ['名前', 'name'],
  ['メールアドレス', 'email'],
  ['タグ', 'tags'],
  ['会社名', 'companyName'],
  ['電話番号', 'phoneNumber'],
  ['メモ', 'memo'],
];

export const hasValidFields = (fields) =>
  fields.length === csvFieldNames.length &&
  fields.every((field, i) => field === csvFieldNames[i][1]);

export const filterContactValues = (existing, rows) =>
  rows.reduce(
    (r, contact) =>
      r.emailSet.has(contact.email)
        ? {
            ...r,
            update: [...r.update, contact],
          }
        : {
            ...r,
            add: [...r.add, contact],
            emailSet: r.emailSet.add(contact.email),
          },
    {
      add: [],
      update: [],
      emailSet: new Set(existing.map((c) => c.email)),
    }
  );

export const deduplicateContactValues = (values) =>
  Array.from(new Map(values.reverse().map((v) => [v.email, v])).values());

export const detectFileEncoding = async (file) => {
  const buffer = await file.arrayBuffer();
  return Encoding.detect(new Uint8Array(buffer));
};

export const tagNamesContainedIn = (contacts) =>
  Array.from(
    contacts
      .reduce((set, c) => c.tags.reduce((s, t) => s.add(t), set), new Set())
      .values()
  );

export const batchAddContactTags = (
  companyId,
  teamId,
  existing,
  contactTagNames
) => {
  const batch = new WriteBatch(db);
  const contactTagsRef = db
    .collection('companies')
    .doc(companyId)
    .collection('contactTags');

  contactTagNames.forEach((name) => {
    const current = existing.find((e) => e.name === name);
    if (current) return;
    const ref = contactTagsRef.doc();
    batch.addOperation((b) =>
      b.set(ref, {
        name,
        teamId,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      })
    );
  });

  return batch.commit();
};

export const batchAddOrUpdateContacts = async (
  companyId,
  teamId,
  existingContactTags,
  existingContacts,
  contacts,
  overwrite = false
) => {
  await batchAddContactTags(
    companyId,
    teamId,
    existingContactTags,
    tagNamesContainedIn(contacts)
  );

  const batch = new WriteBatch(db);
  const contactsRef = db
    .collection('companies')
    .doc(companyId)
    .collection('contacts');

  contacts.forEach((c) => {
    const current = existingContacts.find((e) => e.email === c.email);
    if (current) {
      if (!overwrite) return;
      batch.addOperation((b) =>
        b.update(current.ref, {
          ...c,
          nameNormalized: normalizeStr(c.name),
          emailNormalized: normalizeStr(c.email),
          companyNameNormalized: normalizeStr(c.companyName),
          phoneNumberNormalized: normalizeStr(c.phoneNumber),
          memoNormalized: normalizeStr(c.memo),
          tags: c.tags.sort((a, b) => a.localeCompare(b, 'ja')),
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        })
      );
      return;
    }

    const ref = contactsRef.doc();
    batch.addOperation((b) =>
      b.set(ref, {
        ...c,
        nameNormalized: normalizeStr(c.name),
        emailNormalized: normalizeStr(c.email),
        companyNameNormalized: normalizeStr(c.companyName),
        phoneNumberNormalized: normalizeStr(c.phoneNumber),
        memoNormalized: normalizeStr(c.memo),
        tags: c.tags.sort((a, b) => a.localeCompare(b, 'ja')),
        teamId,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      })
    );
  });

  return batch.commit();
};
