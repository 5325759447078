import * as React from 'react';
import type { SVGProps } from 'react';
const SvgAttach = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M9.582 18.237c-1.219 0-2.255-.427-3.11-1.281-.854-.854-1.28-1.891-1.28-3.11V4.839c0-.877.312-1.627.936-2.252a3.07 3.07 0 0 1 2.253-.937 3.07 3.07 0 0 1 2.252.937c.624.625.937 1.375.937 2.252v8.125c0 .557-.193 1.027-.577 1.411a1.92 1.92 0 0 1-1.41.577 1.918 1.918 0 0 1-1.412-.577 1.92 1.92 0 0 1-.576-1.41V5.335a.483.483 0 0 1 .497-.497.483.483 0 0 1 .497.497v7.628c0 .282.095.518.285.708.19.19.427.286.708.286a.961.961 0 0 0 .708-.286.961.961 0 0 0 .286-.708V4.84a2.12 2.12 0 0 0-.637-1.558 2.121 2.121 0 0 0-1.56-.637c-.614 0-1.134.212-1.558.637a2.12 2.12 0 0 0-.636 1.558v9.007c0 .938.332 1.739.995 2.402a3.274 3.274 0 0 0 2.402.995c.938 0 1.74-.331 2.403-.995a3.274 3.274 0 0 0 .995-2.402v-8.51a.483.483 0 0 1 .496-.497.483.483 0 0 1 .497.497v8.51c0 1.219-.427 2.256-1.28 3.11-.855.854-1.892 1.28-3.11 1.28Z"
      opacity={0.7}
    />
  </svg>
);
export default SvgAttach;
