import { Extension } from '@tiptap/core';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    fontSize: {
      setFontSize: (fontSize: number) => ReturnType;
      unsetFontSize: () => ReturnType;
    };
  }
}

export const EDITOR_DEFAULT_FONT_SIZE = 14;

// Quillのときに使用していたフォントサイズクラスと新しいフォントサイズの対応表
const COMPATIBILITY_MAP = {
  'ql-size-huge': 32,
  'ql-size-large': 20,
  'ql-size-small': 10,
} as const;

export const FontSize = Extension.create({
  name: 'fontSize',

  addGlobalAttributes() {
    return [
      {
        types: ['textStyle'],
        attributes: {
          fontSize: {
            default: null,
            parseHTML: (element) => {
              const found = [...element.classList.values()].find((cn) =>
                Object.keys(COMPATIBILITY_MAP).includes(cn)
              );
              if (found) {
                return COMPATIBILITY_MAP[found as never];
              }
              const fontSize = element.style.fontSize;
              if (!fontSize) {
                return null;
              }
              const intFontSize = parseInt(fontSize);
              return intFontSize === EDITOR_DEFAULT_FONT_SIZE
                ? null
                : intFontSize;
            },
            renderHTML: (attribute) => {
              if (!attribute.fontSize) {
                return {
                  style: `font-size: ${EDITOR_DEFAULT_FONT_SIZE}px`,
                };
              }
              return {
                style: `font-size: ${attribute.fontSize}px`,
              };
            },
          },
        },
      },
    ];
  },

  addCommands() {
    return {
      setFontSize:
        (fontSize) =>
        ({ chain }) =>
          chain().setMark('textStyle', { fontSize }).run(),
      unsetFontSize:
        () =>
        ({ chain }) =>
          chain()
            .setMark('textStyle', { fontSize: null })
            .removeEmptyTextStyle()
            .run(),
    };
  },
});
