import { SettingObjectRoutes } from '../../../common';
import { Signatures } from './Signatures';
import { observer } from 'mobx-react';
import { useStore } from '../../../../../hooks/useStore';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import React, { ComponentProps } from 'react';
import { Store } from '../../../../../store';
import {
  SignatureEditDrawer,
  SignatureUpdate,
} from '../SignatureEditDrawer/SignatureEditDrawer';
import {
  addDoc,
  deleteDoc,
  serverTimestamp,
  updateDoc,
} from 'firebase/firestore';
import { SignatureData } from 'lib';
import { useToast } from '../../../../../hooks/useToast';

type Props = {
  teamId: string;
  routes: SettingObjectRoutes<{ teamId: string }, 'signatureId'>;
};

export const SignaturesWithLogic = observer(({ teamId, routes }: Props) => {
  const store = useStore();
  const readonly = store.me.isReadOnly;
  const { showToast } = useToast();

  const handleUpdate = async (
    id: string | undefined,
    update: SignatureUpdate
  ) => {
    const data: SignatureData = {
      teamId,
      title: update.title,
      body: update.body,
      bodyHtml: update.bodyHtml,
    };
    if (id) {
      await updateDoc(store.doc('signatures', id), {
        ...data,
        updatedAt: serverTimestamp(),
      })
        .then(() => {
          showToast('success', '署名を更新しました');
          routes.toIndex({ teamId });
        })
        .catch((e) => {
          console.error(e);
          showToast('error', '署名の更新に失敗しました');
        });
    } else {
      await addDoc(store.collection('signatures'), {
        ...data,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      })
        .then(() => {
          showToast('success', '署名を作成しました');
          routes.toIndex({ teamId });
        })
        .catch((e) => {
          console.error(e);
          showToast('error', '署名の作成に失敗しました');
        });
    }
  };

  const handleDelete = async (id: string) => {
    await deleteDoc(store.doc('signatures', id))
      .then(() => {
        showToast('success', '署名を削除しました');
      })
      .catch((e) => {
        console.error(e);
        showToast('error', '署名の削除に失敗しました');
      });
  };

  return (
    <>
      <Signatures
        signatures={store.getSignatures(teamId).map((s) => ({
          id: s.id,
          title: s.title,
        }))}
        onOpenCreateDrawer={() => routes.toNew({ teamId })}
        onOpenEditDrawer={(id) => routes.toDetail({ teamId, signatureId: id })}
        loading={store.signaturesLoading}
        readonly={store.me.isReadOnly}
      />
      <Switch>
        <Route exact path={routes.paths.new}>
          <SignatureEditDrawer
            signatureId={undefined}
            signature={{}}
            onUpdate={handleUpdate}
            onDelete={handleDelete}
            open={true}
            onOpenChange={() => routes.toIndex({ teamId })}
            readonly={readonly}
          />
        </Route>
        <Route exact path={routes.paths.detail}>
          <EditDrawer
            store={store}
            path={routes.paths.detail}
            onUpdate={handleUpdate}
            onDelete={handleDelete}
            open={true}
            onOpenChange={() => routes.toIndex({ teamId })}
            readonly={readonly}
          />
        </Route>
      </Switch>
    </>
  );
});

type EditDrawerProps = Omit<
  ComponentProps<typeof SignatureEditDrawer>,
  'signature' | 'signatureId'
> & {
  store: Store;
  path: string;
};

const EditDrawer = ({ store, path, ...props }: EditDrawerProps) => {
  const detailMatch = useRouteMatch<{ signatureId: string }>(path);

  if (!detailMatch) {
    return null;
  }

  const signature = store.getSignature(detailMatch.params.signatureId);
  if (!signature) {
    return null;
  }

  return (
    <SignatureEditDrawer
      signatureId={detailMatch.params.signatureId}
      signature={{
        title: signature.title,
        body: signature.body,
        bodyHtml: signature.bodyHtml,
      }}
      {...props}
    />
  );
};
