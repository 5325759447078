import { useStore } from 'hooks/useStore';
import { useEffect, useState } from 'react';

interface Props {
  id: string;
}

export const AccountName = ({ id }: Props): JSX.Element => {
  const [name, setName] = useState('');
  const { accountStore } = useStore();
  useEffect(() => {
    accountStore.getAccountNameById(id).then((newName) => {
      if (newName !== name) {
        setName(newName);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, accountStore.timestamp]);
  return <span>{name}</span>;
};
