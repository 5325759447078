import { ReactNode } from 'react';
import { createStore, Provider } from 'jotai';
import { useSubscribeAuthState } from '../atoms/auth';

export type Props = {
  children: ReactNode;
};

export const store = createStore();

export const StoreProvider = ({ children }: Props): JSX.Element => {
  return (
    <Provider store={store}>
      <Auth>{children}</Auth>
    </Provider>
  );
};

const Auth = ({ children }: { children: ReactNode }): JSX.Element => {
  useSubscribeAuthState();
  return <>{children}</>;
};
