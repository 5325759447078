import React, {
  useCallback,
  useEffect,
  useState,
  MouseEventHandler,
  FC,
} from 'react';
import styled from 'styled-components';
import { Avatar, Button, Icon, List, message, Tooltip } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import {
  downloadBlob,
  openOrDownloadAttachment,
  getAttachmentDataUrl,
} from '../../../../../util';
import { formatFileSize } from 'utils/file';
import { button } from '../../../../../color';
import { storage } from 'firebase.js';
import { getDownloadURL, ref } from 'firebase/storage';
import { ZipWriter } from '../../../../../utils/ZipWriter';
import { SPOnly, PCOnly } from 'App/Common/MediaQuery';

type AttachmentData = {
  contentType?: string;
  type?: string;
  name?: string;
  filename?: string;
  related?: boolean;
  storagePath: string;
  size: number;
};

const getAttachmentIcon = (attachment: AttachmentData) => {
  const contentType = attachment.contentType || attachment.type || '';
  switch (contentType) {
    case 'application/pdf':
      return 'file-pdf';
    case 'application/zip':
      return 'file-zip';
    default:
      const type = contentType.split('/')[0];
      return (
        {
          text: 'file-text',
          image: 'file-image',
        }[type] || 'file'
      );
  }
};

export const Attachments: FC<{
  attachments: AttachmentData[];
}> = ({ attachments }) => {
  const nonRelatedAttachments = attachments.filter((a) => !a.related);
  const [attachmentUrls, setAttachmentUrls] = useState<string[]>([]);

  useEffect(() => {
    setAttachmentUrls([]);

    (async () => {
      const urls = await Promise.all(
        attachments.map(async (attachment, i) => {
          return getAttachmentDataUrl(attachment);
        })
      );
      setAttachmentUrls(urls);
    })();
  }, [attachments]);

  const onClickItem = useCallback(async (attachment) => {
    try {
      return openOrDownloadAttachment(attachment);
    } catch (e) {
      console.error(e);
      message.error('ファイルの取得に失敗しました。');
    }
  }, []);

  const onClickDownload = useCallback(async (e, attachment) => {
    e.stopPropagation();
    return openOrDownloadAttachment(attachment, true);
  }, []);

  const onClickDownloadAll: MouseEventHandler<HTMLButtonElement> = useCallback(
    async (e) => {
      e.stopPropagation();
      const zipWriter = new ZipWriter();
      await Promise.all(
        nonRelatedAttachments.map(async (attachment) => {
          const url = await getDownloadURL(
            ref(storage, attachment.storagePath)
          );
          zipWriter.addEntry(
            attachment.name || attachment.filename || 'file',
            url
          );
        })
      );
      const blob = await zipWriter.getBlob();
      downloadBlob('attachments.zip', blob);
    },
    [nonRelatedAttachments]
  );

  const onDragStart = (e: React.DragEvent, attachment: AttachmentData) => {
    const index = attachments.indexOf(attachment);
    if (index < 0) {
      return;
    }
    const url = attachmentUrls[index];
    const contentType = attachment.contentType || attachment.type;
    const filename = attachment.filename || attachment.name;
    if (!url || !contentType || !filename) {
      return;
    }

    e.dataTransfer?.setData('DownloadURL', `${contentType}:${filename}:${url}`);
  };

  if (nonRelatedAttachments.length === 0) return <></>;

  return (
    <Wrapper>
      <Actions>
        <Tooltip title="添付ファイルを一括ダウンロード" placement="topRight">
          <DownloadAllButton
            aria-label="添付ファイルを一括ダウンロード"
            onClick={onClickDownloadAll}
          >
            <DownloadOutlined />
          </DownloadAllButton>
        </Tooltip>
      </Actions>
      <SPOnly>
        <div className="flex w-[calc(100%-19px)] flex-wrap items-center gap-x-3 gap-y-1">
          {nonRelatedAttachments.map((attachment) => (
            <label
              onClick={() => onClickItem(attachment)}
              className="mb-0 flex cursor-pointer items-center gap-2"
            >
              <Icon
                type={getAttachmentIcon(attachment)}
                style={{ fontSize: '0.9375rem', color: 'rgb(73 132 242)' }}
              />
              <span className="max-w-[85px] overflow-hidden overflow-ellipsis whitespace-nowrap text-sm text-sea-500 underline underline-offset-4">
                {attachment.name || attachment.filename || 'file'}
              </span>
            </label>
          ))}
        </div>
      </SPOnly>
      <PCOnly>
        <List
          itemLayout="horizontal"
          grid={{ gutter: 12, column: 4 }}
          dataSource={nonRelatedAttachments}
          renderItem={(attachment) => (
            <List.Item
              draggable
              onDragStart={(e) => onDragStart(e, attachment)}
            >
              <Attachment
                title={attachment.name || attachment.filename}
                onClick={() => onClickItem(attachment)}
              >
                <Icon
                  type={getAttachmentIcon(attachment)}
                  style={{ fontSize: '0.9375rem' }}
                />
                <AttachmentText>
                  {attachment.name || attachment.filename || 'file'}
                </AttachmentText>
                <span>{formatFileSize(attachment.size)}</span>
                <IconWrapper
                  draggable={false}
                  onClick={(e) => onClickDownload(e, attachment)}
                  aria-label="ダウンロード"
                >
                  <Avatar size="small" icon="arrow-down" />
                </IconWrapper>
              </Attachment>
            </List.Item>
          )}
        />
      </PCOnly>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
`;
const Actions = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  cursor: pointer;
`;
const DownloadAllButton = styled.button`
  background-color: transparent;
  margin: 0;
  padding: 0;
  cursor: pointer;

  ::before {
    content: '';
    position: absolute;
    inset: -10px;
  }
  :hover {
    svg {
      fill: ${button.border.primary};
    }
  }
  svg {
    font-size: 1.5em;
    fill: rgb(41, 41, 41);
  }
`;

const Attachment = styled(Button)`
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
  padding: 15px 12px;
`;

const IconWrapper = styled.div`
  margin-left: 1rem;
  &:hover > * {
    background: ${button.background.primary};
  }
  * {
    font-size: 12px;
  }
`;

const AttachmentText = styled.span`
  overflow: hidden;
  text-align: left;
  flex-grow: 1;
  text-overflow: ellipsis;
`;
