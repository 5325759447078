import { Image } from '@tiptap/extension-image';
import { NodeViewWrapper, ReactNodeViewRenderer } from '@tiptap/react';
import { NodeViewProps, NodeViewRendererProps } from '@tiptap/core';
import { useEffect, useMemo, useRef, useState } from 'react';
import { tv } from 'tailwind-variants';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { ImageOptions } from '@tiptap/extension-image/src/image';
import { v4 } from 'uuid';

type ExtendedImageOptions = ImageOptions & {
  upload:
    | ((file: File) => Promise<{ src: string; contentId: string }>)
    | undefined;
};

export const ExtendedImage = Image.extend<ExtendedImageOptions>({
  addOptions() {
    return {
      ...this.parent?.(),
      upload: undefined,
    };
  },
  addAttributes() {
    return {
      ...this.parent?.(),
      width: {
        default: null,
      },
      'data-content_id': {
        default: null,
      },
      'data-upload_id': {
        default: null,
      },
    };
  },
  addNodeView() {
    return ReactNodeViewRenderer(ImageRenderer);
  },
  addProseMirrorPlugins() {
    return [
      ((upload: ExtendedImageOptions['upload']) =>
        new Plugin({
          key: new PluginKey('extendedImage'),
          props: {
            handlePaste(view, event, slice) {
              const uploadId = v4();
              const file = event.clipboardData?.items[0]?.getAsFile();
              if (!file || !file.type.startsWith('image/')) {
                return;
              }

              event.preventDefault();

              if (!upload) {
                return;
              }

              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = () => {
                const { schema } = view.state;
                const node = schema.nodes.image.create({
                  src: reader.result,
                  alt: '',
                  'data-upload_id': uploadId,
                });
                const transaction = view.state.tr.insert(
                  view.state.selection.from,
                  node
                );
                view.dispatch(transaction);
              };
              setTimeout(() => {
                upload(file).then(({ src, contentId }) => {
                  view.state.doc.descendants((node, pos) => {
                    if (
                      node.type.name === 'image' &&
                      node.attrs['data-upload_id'] === uploadId
                    ) {
                      const tr = view.state.tr
                        .setNodeAttribute(pos, 'src', src)
                        .setNodeAttribute(pos, 'data-content_id', contentId)
                        .setNodeAttribute(pos, 'data-upload_id', undefined);
                      tr.setMeta('addToHistory', false);
                      view.dispatch(tr);
                    }
                  });
                });
              }, 100);
            },
          },
        }))(this.options.upload),
    ];
  },
});

const imageWrapper = tv({
  variants: {
    editing: {
      true: 'relative h-auto',
    },
  },
});

const resizeHandle = tv({
  base: 'absolute top-0 h-full w-[8px] cursor-ew-resize',
  variants: {
    side: {
      left: 'left-0',
      right: 'right-0 ',
    },
  },
});

const resizeIndicator = tv({
  base: 'absolute top-[calc((100%_-_min(32px,50%))_/_2)] h-[min(32px,50%)] w-[2px] cursor-ew-resize bg-sumi-500',
  variants: {
    side: {
      left: 'left-1',
      right: 'right-1 ',
    },
  },
});

const sizeDisplay = tv({
  base: 'absolute whitespace-nowrap rounded border border-sumi-500 bg-white/75 px-1 text-sm text-sumi-800',
  variants: {
    pos: {
      inner: 'bottom-1 right-1',
      outer: 'left-1 top-1',
    },
  },
});

const ImageRenderer = ({
  node,
  HTMLAttributes,
  updateAttributes,
  ...props
}: NodeViewRendererProps & Pick<NodeViewProps, 'updateAttributes'>) => {
  const attrs = node.attrs;
  const [focused, setFocus] = useState(false);
  const [imageHeight, setImageHeight] = useState(0);
  const now = useMemo(() => Date.now(), []);
  const ref = useRef<HTMLImageElement>(null);
  const resizingRef = useRef(false);
  const resizingDirection = useRef(1);
  const resizeStartX = useRef(0);
  const initialImageWidth = useRef<number | null>(attrs.width);
  const imageWidth = attrs.width;
  const onClick = (e: MouseEvent) => {
    if (!props.editor.isEditable) {
      return;
    }
    if (resizingRef.current) {
      resizingRef.current = false;
      return;
    }
    if (e.target === ref.current) {
      if (!focused) {
        setFocus(true);
      }
    } else {
      if (
        (e.target as HTMLElement).getAttribute('data-resize-handle') === 'true'
      ) {
        return;
      }
      if (focused) {
        setFocus(false);
      }
    }
  };
  const updateImageHeight = () => {
    setImageHeight(ref.current?.clientHeight ?? 0);
  };
  const onDragging = (e: PointerEvent) => {
    if (!resizingRef.current) {
      return;
    }
    const amount =
      (e.clientX - resizeStartX.current) * resizingDirection.current;
    let imageWidth = initialImageWidth.current;
    if (imageWidth == null) {
      imageWidth = ref.current?.clientWidth ?? 0;
      initialImageWidth.current = imageWidth;
    }
    const width = imageWidth + amount;
    updateAttributes({ width: Math.max(width, 8) });
    updateImageHeight();
  };
  useEffect(() => {
    updateImageHeight();
    document.addEventListener('click', onClick);
    return () => document.removeEventListener('click', onClick);
  }, [focused]);
  useEffect(() => {
    document.addEventListener('pointermove', onDragging);
    return () => document.removeEventListener('pointermove', onDragging);
  }, []);
  return (
    <NodeViewWrapper
      className={imageWrapper({ editing: focused })}
      style={{
        display: props.extension.options.inline ? 'inline-block' : undefined,
      }}
    >
      <img
        src={`${attrs.src}#${now}`} // キャッシュさせないためにURLを変更する
        alt={attrs.alt}
        title={attrs.title}
        width={attrs.width}
        data-content_id={attrs['data-content_id']}
        className={attrs['data-upload_id'] ? 'opacity-50' : ''}
        {...HTMLAttributes}
        ref={ref}
      />
      {focused && !attrs['data-upload_id'] && (
        <>
          <div className="pointer-events-none absolute inset-0 left-0 top-0 border border-dashed border-sumi-500" />
          <div
            className={resizeHandle({ side: 'left' })}
            onPointerDown={(e) => {
              resizingRef.current = true;
              resizingDirection.current = -1;
              resizeStartX.current = e.clientX;
              initialImageWidth.current = attrs.width;
            }}
            data-resize-handle={true}
          />
          <div
            className={resizeHandle({ side: 'right' })}
            onPointerDown={(e) => {
              resizingRef.current = true;
              resizingDirection.current = 1;
              resizeStartX.current = e.clientX;
              initialImageWidth.current = attrs.width;
            }}
            data-resize-handle={true}
          />
          <div className={resizeIndicator({ side: 'left' })} />
          <div className={resizeIndicator({ side: 'right' })} />
          <div className="pointer-events-none absolute bottom-0 right-0 z-10 h-0 w-0 select-none">
            <div
              className={sizeDisplay({
                pos: imageWidth && imageWidth < 80 ? 'outer' : 'inner',
              })}
            >
              {imageWidth == null ? 'auto' : `${imageWidth} x ${imageHeight}`}
            </div>
          </div>
        </>
      )}
    </NodeViewWrapper>
  );
};
