import { MessageLike, messageLikeConverter } from 'lib';
import { companyCollection } from '../../firestore';
import { orderBy, query, where } from 'firebase/firestore';
import { store } from '../../providers/StoreProvider';
import { atomWithPaginate, PaginateAtom } from '../../utils/atom';
import { joinedTeamIdsAtom } from './team';
import { messagesPathAtom } from './message';

export const commentedMessagesAtom: PaginateAtom<MessageLike> =
  atomWithPaginate(
    () => {
      const messagesPath = store.get(messagesPathAtom);
      return query(
        companyCollection(messagesPath, messageLikeConverter),
        where('teamId', 'in', store.get(joinedTeamIdsAtom)),
        orderBy('latestComment.createdAt', 'desc')
      );
    },
    async (snapshot, prev) => {
      snapshot.docChanges().forEach((change) => {
        const message = change.doc.data();
        switch (change.type) {
          case 'added':
            prev.push(message);
            break;
          case 'modified':
            prev.splice(
              prev.findIndex((m) => m.id === message.id),
              1,
              message
            );
            break;
          case 'removed':
            prev.splice(
              prev.findIndex((m) => m.id === message.id),
              1
            );
            break;
        }
      });

      return prev.sort(
        (a, b) =>
          b.latestComment!.createdAt.toMillis() -
          a.latestComment!.createdAt.toMillis()
      );
    }
  );
