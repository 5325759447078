import juice from 'juice';

export const generateHtml = ({
  body,
  signatureBody,
  quotedBody,
}: {
  body: string;
  signatureBody: string;
  quotedBody: string;
}) => `<!DOCTYPE HTML PUBLIC "-//W3C//DTD HTML 4.01 Transitional//EN" "http://www.w3.org/TR/html4/loose.dtd">
<html lang="ja">
<head>
  <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
  <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1" /><!--[if !mso]><!-->
  <meta http-equiv="X-UA-Compatible" content="IE=Edge" /><!--<![endif]-->
  <!--[if (gte mso 9)|(IE)]>
  <xml>
    <o:OfficeDocumentSettings>
      <o:AllowPNG/>
      <o:PixelsPerInch>96</o:PixelsPerInch>
    </o:OfficeDocumentSettings>
  </xml>
  <![endif]-->
  <!--[if (gte mso 9)|(IE)]>
  <style type="text/css">
    body {width: 600px;margin: 0 auto;}
    table {border-collapse: collapse;}
    table, td {mso-table-lspace: 0pt;mso-table-rspace: 0pt;}
    img {-ms-interpolation-mode: bicubic;}
  </style>
  <![endif]-->
</head>
<body>
${juice(
  `<div class="message-container"><div>${body}</div><div>${signatureBody}</div></div>`,
  {
    extraCss: defaultCSS,
  }
)}
${quotedBody}
</body>
</html>
`;

// message.css
const defaultCSS = `
.message-container {
  box-sizing: border-box;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.42;
  height: auto;
  margin: 0;
  position: relative;
  color: #000;
  word-break: break-word;
}

.message-container * {
  box-sizing: border-box;
}

.message-container blockquote {
  border-left: 4px solid #ccc;
  margin: 0;
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  padding: 0 0 0 16px;
}

.message-container img {
  max-width: 100%;
  user-select: text;
}

.message-container a {
  color: #4984f2;
  text-decoration: underline;
}

.message-container p,
.message-container ol,
.message-container ul,
.message-container pre,
.message-container h1,
.message-container h2,
.message-container h3,
.message-container h4,
.message-container h5,
.message-container h6 {
  margin: 0;
  padding: 0;
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}

.message-container table {
  border-collapse: collapse;
  margin: 0;
  overflow: hidden;
  table-layout: fixed;
}

.message-container table th {
  font-weight: bold;
  background-color: #f8f8fb;
  text-align: left;
}

.message-container table th,
.message-container table td {
  border: 1px solid #d1d5db;
  padding: 8px 10px;
  position: relative;
  vertical-align: top;
}

.message-container ul,
.message-container ol {
  list-style-position: inside;
}

.message-container ul p,
.message-container ol p {
  display: inline;
}

.message-container ul ul,
.message-container ol ol {
  padding-left: 20px;
}

.message-container p:empty {
  min-height: 1.42em;
}
`;
