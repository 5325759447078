import { Button, Card, message as toast, Modal } from 'antd';
import {
  commentConverter,
  Draft as DraftEntity,
  Event as EventEntity,
  extractEmail,
  extractNonInlineAttachments,
  extractNonInlineSentAttachments,
  Inbox,
  isUnread,
  isUnreadComment,
  Lock as LockEntity,
  messageConverter,
  sentConverter,
} from 'lib';
import throttle from 'lodash.throttle';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';
import { compose } from 'recompose';
import styled from 'styled-components';
import media from 'styled-media-query';
import { v4 as uuidv4 } from 'uuid';
import { eventNames, logEvent } from '../../../../analytics';
import * as color from '../../../../color';
import firebase, { db } from 'firebase.js';
import {
  generateFileFromAttachment,
  generateStorageAttachmentName,
} from '../../../../util';
import { PCOnly, SPOnly } from '../../../Common/MediaQuery';
import { Comment } from '../../Comment';
import { uid as rcuid } from '../../CreateMessage/rcuid';
import Event from '../../Event';
import { getScrollContainerId, scrollTo, uploadFileToStorage } from '../util';
import { AddComment } from './AddComment';
import { Draft } from './Draft';
import { ConversationHeader } from './Header';
import Message from './Message';
import OtherMemberDraft from './OtherMemberDraft';
import Scheduled from './Scheduled';
import Sent from './Sent';
import ThreadConversationWrapper from './ThreadConversationWrapper';
import {
  deleteDoc,
  getDoc,
  onSnapshot,
  query,
  serverTimestamp,
  Timestamp,
  updateDoc,
  where,
} from 'firebase/firestore';
import { companyCollection, companyDoc } from '../../../../firestore';
import { store } from '../../../../providers/StoreProvider';
import { messagesAtom } from '../../../../atoms/firestore/message';
import { AutoRepliedIndication } from './AutoRepliedIndication/AutoRepliedIndication';

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAddingComment: false,
      message: null,
      comments: [],
      events: [],
      locks: [],
      sent: [],
      uploadingAttachments: [],
    };
    this._commentsInitLoaded = false; // renderに影響しないため、stateで管理しない
    this.messageDetailRef = React.createRef();
    this.headerRef = React.createRef();
  }

  componentDidMount() {
    this.fetchAndSync();
  }

  fetchAndSync = async () => {
    const message = await this.findMessage();
    if (!message) {
      console.error(
        'Conversation.fetchAndSync: message not found:',
        this.props.messageId
      );
      return;
    }
    this.setState({ message }, this.getMessageFromStorage);
    this.syncMessage();
    this.syncComments(message);
    this.syncEvents(message);
    this.syncLocks(message);
    this.syncSent(message);
  };

  findMessage = async () => {
    const { messageId } = this.props;

    try {
      const snapshot = await getDoc(
        companyDoc('messages', messageId, messageConverter)
      );
      if (!snapshot.exists()) {
        return null;
      }
      return snapshot.data();
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  // 既読にする
  markAsRead = async () => {
    const { message } = this.state;
    // Firestoreの書き込み速度の制限に引っかかるのを避けるため、すでに既読の場合は何もしない
    const messageOrCommentUnread =
      isUnread(message, this.props.store.me.id) ||
      !message.latestComment ||
      isUnreadComment(message, message.latestComment, this.props.store.me.id);
    if (!messageOrCommentUnread) return;

    await updateDoc(message.ref, {
      [`readers.${this.props.store.me.id}`]: serverTimestamp(),
      updatedAt: serverTimestamp(),
    });
  };

  syncMessage = () => {
    const { messageId } = this.props;
    this._unsubscribeSyncMessage = onSnapshot(
      companyDoc('messages', messageId, messageConverter),
      (doc) => {
        if (!doc.exists()) {
          console.error('Conversation.syncMessage: !doc.exists:', {
            messageId,
          });
        }
        const message = doc.data();
        if (message.deletedAt && message.deletedBy !== this.props.store.me.id) {
          // 閲覧中のメールが削除された場合
          const deletedBy = this.props.store.getUser(message.deletedBy);
          toast.warn(`閲覧中のメールが${deletedBy.name}によって削除されました`);
          this.props.history.push('./');
        }
        this.setState({ message });
      },
      (err) => {
        console.log(`Encountered error: ${err}`);
      }
    );
  };

  componentWillUnmount() {
    this.unsubscribeAll();
  }

  unsubscribeAll = () => {
    this.unsubscribeSyncMessage();
    this.unsubscribeComments();
    this.unsubscribeEvents();
    this.unsubscribeLocks();
    this.unsubscribeSent();
  };

  get headerHeight() {
    return this.headerRef.current?.offsetHeight;
  }

  scrollToSent = (sentId) => {
    scrollTo(`sent-${sentId}`, this.headerHeight);
  };

  scrollToAnchorSent = () => {
    const { sentId } = this.props;
    if (sentId) {
      this.scrollToSent(sentId);
    }
  };

  scrollToComment = (commentId) => {
    scrollTo(`comment-${commentId}`, this.headerHeight);
  };

  scrollToAnchorComment = () => {
    const { commentId } = this.props;
    if (commentId) {
      this.scrollToComment(commentId);
    }
  };

  startForwarding = throttle(
    async (storageMessageAttachments) => {
      const { message } = this.state;
      const inboxSnapshot = await getDoc(message.inboxRef);
      const inbox = new Inbox(inboxSnapshot);

      // 下書きを作成する（事前にidを取得する）
      const draftRef = db
        .collection(`companies/${this.props.store.signInCompany}/drafts`)
        .doc();

      // 添付ファイルも転送にする
      const attachments = await Promise.all(
        extractNonInlineAttachments(
          storageMessageAttachments || message.attachments
        ).map(async (attachment) => {
          const file = await generateFileFromAttachment(attachment);
          file.uid = rcuid(); // rc-uploadを経由していないため、uidが作られず、表示時にエラーとなる。そのため、ここでuidを手動で生成している
          const storagePath = `companies/${
            this.props.store.signInCompany
          }/drafts/${
            draftRef.id
          }/attachments/${uuidv4()}/${generateStorageAttachmentName(
            file.name
          )}`;
          return await uploadFileToStorage(
            file,
            storagePath,
            this.props.store.me.id,
            inbox.teamId
          );
        })
      );

      // 自動cc
      const cc = inbox.autoCcs.map((acc) => acc);

      // 自動Bcc
      const bcc = inbox.autoBccs.filter(
        (abcc) => ![...cc].some((r) => extractEmail(abcc) === extractEmail(r))
      );

      // 下書きを作成する（添付ファイルともにsetする）
      const addDraftPromise = draftRef.set({
        inboxId: inbox.id,
        teamId: inbox.teamId,
        to: [],
        cc,
        bcc,
        subject: `Fwd: ${message.subject}`,
        originalSubject: message.subject,
        body: '',
        signature:
          this.props.store.getSignature(inbox.defaultSignatureId)?.signature ||
          null,
        useQuote: true,
        attachments,
        plainTextMode: this.props.store.me.plainTextMode,
        drafter: this.props.store.me.id,
        inReplyToMessageId: message.id,
        inReplyToMessageRef: message.ref,
        isReply: true,
        isForwarded: true,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });

      // イベントを作成する
      const addEventPromise = this.props.store.companyCollection('events').add({
        user: this.props.store.me.id,
        name: this.props.store.me.name,
        teamId: message.teamId,
        messageId: message.id,
        type: 'draft:create:forward',
        text: `${this.props.store.me.name}が転送を開始しました。`,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
      await Promise.all([addEventPromise, addDraftPromise]);
      logEvent(eventNames.start_forwarding);

      this.scrollToBottom();
    },
    2000,
    { trailing: false }
  );

  startReply = throttle(
    async (message, lock, replyAll) => {
      const inboxSnapshot = await getDoc(message.inboxRef);
      const inbox = new Inbox(inboxSnapshot);
      if (message.assignee == null) {
        await updateDoc(message.ref, {
          assignee: this.props.store.me.id,
          updatedAt: serverTimestamp(),
        });
      }
      let locker;
      if (lock) {
        // ロックを削除する
        await lock.ref.delete();
        locker = this.props.store.getUser(lock.locker);
      }

      let to = [];

      if (
        message.replyAddress &&
        inbox.email !== extractEmail(message.replyAddress)
      ) {
        to = [message.replyAddress];
      } else if (
        inbox.email === extractEmail(message.replyAddress) &&
        message.toAddresses.length > 0
      ) {
        to = [message.toAddresses[0]];
      }

      if (replyAll) {
        to = [
          ...to,
          ...message.toAddresses.filter(
            (c) =>
              !to.some((t) => extractEmail(c) === extractEmail(t)) &&
              extractEmail(c) !== inbox.email
          ),
        ];
      }

      let cc = [];
      if (replyAll) {
        // toと重複する場合はセットしない
        cc = message.ccAddresses.filter(
          (c) => !to.some((t) => extractEmail(c) === extractEmail(t))
        );
      }

      // 自動cc
      cc = [
        ...cc,
        ...inbox.autoCcs.filter(
          (acc) =>
            !cc.some((c) => extractEmail(c) === acc) &&
            !to.some((t) => extractEmail(acc) === extractEmail(t))
        ),
      ];

      // 自動Bcc
      const bcc = inbox.autoBccs.filter(
        (abcc) =>
          ![...to, ...cc].some((r) => extractEmail(abcc) === extractEmail(r))
      );

      // 下書きを作成する
      const addDraftPromise = db
        .collection(`companies/${this.props.store.signInCompany}/drafts`)
        .add({
          inboxId: inbox.id,
          teamId: inbox.teamId,
          to,
          cc,
          bcc,
          subject: `${message.subject.startsWith('Re: ') ? '' : 'Re: '}${
            message.subject
          }`,
          originalSubject: message.subject,
          body: '',
          signature:
            this.props.store.getSignature(inbox.defaultSignatureId)
              ?.signature || null,
          useQuote: true,
          attachments: [],
          plainTextMode: this.props.store.me.plainTextMode,
          drafter: this.props.store.me.id,
          inReplyToMessageId: message.id,
          inReplyToMessageRef: message.ref,
          isReply: true,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });

      // ロックを作成する
      const addLockPromise = await this.props.store
        .companyCollection('locks')
        .add({
          teamId: message.teamId,
          messageId: message.id,
          inboxId: inbox.id,
          locker: this.props.store.me.id,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });

      // イベントを作成する
      const addEventPromise = this.props.store.companyCollection('events').add({
        user: this.props.store.me.id,
        name: this.props.store.me.name,
        teamId: message.teamId,
        messageId: message.id,
        type: lock ? 'draft:create:delegate' : 'draft:create',
        text: lock
          ? `${this.props.store.me.name}が${
              locker ? locker.name : '削除されたユーザ'
            }の代わりに返信を開始しました。`
          : `${this.props.store.me.name}が返信を開始しました。`,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
      await Promise.all([addEventPromise, addDraftPromise, addLockPromise]);
      logEvent(eventNames.start_reply);

      this.scrollToBottom();
    },
    2000,
    { trailing: false }
  );

  startForwardingToSent = throttle(
    async (sent) => {
      const inboxSnapshot = await db
        .collection(`companies/${this.props.store.signInCompany}/inboxes`)
        .doc(sent.inboxId)
        .get();
      if (!inboxSnapshot.exists) {
        console.error(
          'Conversation.startForwardingToSent: !inboxSnapshot.exists:',
          {
            sentId: sent.id,
            inboxId: sent.inboxId,
          }
        );
      }
      const inbox = new Inbox(inboxSnapshot);

      // 下書きを作成する（事前にidを取得する）
      const draftRef = db
        .collection(`companies/${this.props.store.signInCompany}/drafts`)
        .doc();

      // 添付ファイルも転送にする
      const attachments = await Promise.all(
        extractNonInlineSentAttachments(sent.attachments).map(
          async (attachment) => {
            const ref = firebase.storage().ref(attachment.storagePath);
            const url = await ref.getDownloadURL();
            const res = await fetch(url);
            const blob = await res.blob();
            const file = new File([blob], attachment.name, {
              type: attachment.type,
            });
            file.uid = rcuid(); // rc-uploadを経由していないため、uidが作られず、表示時にエラーとなる。そのため、ここでuidを手動で生成している
            const storagePath = `companies/${
              this.props.store.signInCompany
            }/drafts/${
              draftRef.id
            }/attachments/${uuidv4()}/${generateStorageAttachmentName(
              file.name
            )}`;
            return await uploadFileToStorage(
              file,
              storagePath,
              this.props.store.me.id,
              inbox.teamId
            );
          }
        )
      );

      // 自動cc
      const cc = inbox.autoCcs.map((acc) => acc);

      // 自動Bcc
      const bcc = inbox.autoBccs.filter(
        (abcc) => ![...cc].some((r) => extractEmail(abcc) === extractEmail(r))
      );

      // 下書きを作成する
      const addDraftPromise = draftRef.set({
        inboxId: inbox.id,
        teamId: inbox.teamId,
        to: [],
        cc,
        bcc,
        subject: `Fwd: ${sent.subject}`,
        originalSubject: sent.subject,
        body: '',
        signature:
          this.props.store.getSignature(inbox.defaultSignatureId)?.signature ||
          null,
        useQuote: true,
        attachments,
        plainTextMode: this.props.store.me.plainTextMode,
        drafter: this.props.store.me.id,
        inReplyToMessageId: sent.inReplyToMessageId,
        inReplyToMessageRef: db
          .collection(`companies/${this.props.store.signInCompany}/messages`)
          .doc(sent.inReplyToMessageId),
        inReplyToSentId: sent.id,
        inReplyToSentRef: sent.ref,
        isReply: true,
        isForwarded: true,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });

      // イベントを作成する
      const addEventPromise = this.props.store.companyCollection('events').add({
        user: this.props.store.me.id,
        name: this.props.store.me.name,
        teamId: sent.teamId,
        messageId: sent.inReplyToMessageId,
        type: 'draft:create:forward',
        text: `${this.props.store.me.name}が転送を開始しました。`,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
      await Promise.all([addEventPromise, addDraftPromise]);
      logEvent(eventNames.start_forwarding_to_sent);

      this.scrollToBottom();
    },
    2000,
    { trailing: false }
  );

  startReplyToSent = throttle(
    async (sent, replyAll) => {
      const inboxSnapshot = await db
        .collection(`companies/${this.props.store.signInCompany}/inboxes`)
        .doc(sent.inboxId)
        .get();
      if (!inboxSnapshot.exists) {
        console.error('Conversation.startReplyToSent: !inboxSnapshot.exists:', {
          sentId: sent.id,
          inboxId: sent.inboxId,
        });
      }
      const inbox = new Inbox(inboxSnapshot);

      let to =
        (replyAll
          ? sent.to
          : sent.to.length > 0
          ? sent.to.slice(0, 1)
          : null) || [];

      let cc = [];
      if (replyAll && sent.cc) {
        // toと重複する場合はセットしない
        cc = sent.cc.filter(
          (c) => !to.some((t) => extractEmail(c) === extractEmail(t))
        );
      }

      // 自動cc
      cc = [
        ...cc,
        ...inbox.autoCcs.filter(
          (acc) =>
            !cc.some((c) => extractEmail(c) === acc) &&
            !to.some((t) => extractEmail(acc) === extractEmail(t))
        ),
      ];

      // 自動Bcc
      const bcc = inbox.autoBccs.filter(
        (abcc) =>
          ![...to, ...cc].some((r) => extractEmail(abcc) === extractEmail(r))
      );

      // 下書きを作成する
      const addDraftPromise = db
        .collection(`companies/${this.props.store.signInCompany}/drafts`)
        .add({
          inboxId: inbox.id,
          teamId: inbox.teamId,
          to,
          cc,
          bcc,
          subject: `${sent.subject.startsWith('Re: ') ? '' : 'Re: '}${
            sent.subject
          }`,
          originalSubject: sent.subject,
          body: '',
          signature:
            this.props.store.getSignature(inbox.defaultSignatureId)
              ?.signature || null,
          useQuote: true,
          attachments: [],
          plainTextMode: this.props.store.me.plainTextMode,
          drafter: this.props.store.me.id,
          inReplyToMessageId: sent.inReplyToMessageId,
          inReplyToMessageRef: db
            .collection(`companies/${this.props.store.signInCompany}/messages`)
            .doc(sent.inReplyToMessageId),
          inReplyToSentId: sent.id,
          inReplyToSentRef: sent.ref,
          isReply: true,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });

      // ロックを作成する
      const addLockPromise = await this.props.store
        .companyCollection('locks')
        .add({
          teamId: sent.teamId,
          messageId: sent.inReplyToMessageId,
          inboxId: inbox.id,
          locker: this.props.store.me.id,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });

      // イベントを作成する
      const addEventPromise = this.props.store.companyCollection('events').add({
        user: this.props.store.me.id,
        name: this.props.store.me.name,
        teamId: sent.teamId,
        messageId: sent.inReplyToMessageId,
        type: 'draft:create',
        text: `${this.props.store.me.name}が返信を開始しました。`,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
      await Promise.all([addEventPromise, addDraftPromise, addLockPromise]);
      logEvent(eventNames.start_reply_to_sent);
      this.scrollToBottom();
    },
    2000,
    { trailing: false }
  );

  /**
   * @param message {{id: string} | null}
   * @return {null|string}
   */
  toLink = (message) => {
    let url;
    if (this.props.location.pathname.startsWith('/me/assigned')) {
      url = `/me/assigned/messages`;
    }

    const { teamId, inboxId, tagId } = this.props.match.params;
    if (teamId && inboxId && tagId) {
      url = `/teams/${teamId}/inboxes/${inboxId}/tags/${tagId}/messages`;
    } else if (teamId && tagId) {
      url = `/teams/${teamId}/tags/${tagId}/messages`;
    } else if (teamId) {
      url = `/teams/${teamId}/messages`;
    }

    if (url) {
      return message ? `${url}/${message.id}` : url;
    }

    return null;
  };

  backLink = () => {
    const matched = [
      '/me/drafts',
      '/me/scheduled',
      '/me/sent',
      '/me/mentioned',
      '/me/assigned/messages',
      '/me/assigned/processed',
      '/me/assigned/all',
      '/me/commented',
      '/me/starred',
    ].find((pathPrefix) => this.props.location.pathname.startsWith(pathPrefix));
    if (matched) return matched;

    return '.';
  };

  updateStatus = async (message, status) => {
    const {
      selectedStatus,
      searchStore: { inSearch },
    } = this.props.store;
    const statusToProcessed = status === '対応済み';

    const messagesPaginate = store.get(messagesAtom);

    const updatePromise = updateDoc(message.ref, {
      status,
      updatedAt: serverTimestamp(),
    });
    const addEventPromise = this.props.store.companyCollection('events').add({
      user: this.props.store.me.id,
      name: this.props.store.me.name,
      teamId: message.teamId,
      messageId: message.id,
      type: statusToProcessed
        ? 'status:update:processed'
        : 'status:update:backlog',
      text: `${this.props.store.me.name}が「${status}」に変更しました。`,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
    await Promise.all([updatePromise, addEventPromise]);
    logEvent(eventNames.update_status, { status });

    if (statusToProcessed && selectedStatus === '未対応' && !inSearch) {
      if (messagesPaginate.state !== 'hasData') {
        return;
      }
      this.toNextMessage(messagesPaginate.data);
    }
  };

  /**
   * @param tagId string
   */
  handleRemoveTag = (tagId) => {
    const { tagId: inboxTagId } = this.props.match.params;
    if (tagId !== inboxTagId) {
      return;
    }
    const messagesPaginate = store.get(messagesAtom);
    if (messagesPaginate.state !== 'hasData') {
      return;
    }

    const messages = messagesPaginate.data;
    this.toNextMessage(messages);
  };

  /**
   * @param messages {MessageLike[]}
   */
  toNextMessage = (messages) => {
    let nextLink;

    // 次の未対応のメッセージを表示する
    // 現在のメッセージの次の順番を取得する
    const currentMessageIndex = messages.findIndex(
      ({ id }) => id === this.props.messageId
    );
    if (currentMessageIndex === -1) return;
    const nextMessageIndex = currentMessageIndex + 1;
    const hasNextMessage = messages.length > nextMessageIndex;
    const anotherMessages = messages.filter(
      (m) => m.id !== this.props.threadId
    );
    if (hasNextMessage) {
      // 次のメッセージがある場合、自動で遷移する
      nextLink = this.toLink(messages[nextMessageIndex]);
    } else if (anotherMessages.length > 0) {
      // 次のメッセージがない場合、他のメッセージがあれば自動で先頭に遷移する
      nextLink = this.toLink(messages[0]);
    } else {
      // メッセージがなにもない場合はメッセージ詳細を消す
      nextLink = this.toLink(null);
    }

    if (nextLink) {
      this.props.history.push(nextLink);
    }
  };

  updateAssignee = async (message, userId) => {
    const updatePromise = updateDoc(message.ref, {
      assignee: userId,
      updatedAt: serverTimestamp(),
    });
    const assignee = userId ? this.props.store.getUser(userId) : null;
    const addEventPromise = this.props.store.companyCollection('events').add({
      user: this.props.store.me.id,
      name: this.props.store.me.name,
      assignee: userId,
      assigneeName: assignee?.name || '担当者未設定',
      teamId: message.teamId,
      messageId: message.id,
      type: 'assignee:update',
      text: `${this.props.store.me.name}が「${
        assignee?.name || '担当者未設定'
      }」に担当者を設定しました。`,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
    await Promise.all([updatePromise, addEventPromise]);
    logEvent(eventNames.update_assignee, { assignee: userId });
  };

  scrollToBottom = () => {
    scroll.scrollToBottom({
      containerId: getScrollContainerId(),
      duration: 300,
    });
  };

  syncComments = (message) => {
    this._commentsInitLoaded = false;
    this.unsubscribeComments();
    let q = query(
      companyCollection('comments', commentConverter),
      where('teamId', '==', message.teamId),
      where('messageId', '==', message.id)
    );
    this._unsubscribeComments = onSnapshot(
      q,
      (snapshot) => {
        let comments = [];
        snapshot.forEach((doc) => {
          comments.push(doc.data());
        });

        snapshot.docChanges().forEach((change) => {
          if (change.type === 'added') {
            const addedComment = change.doc.data();
            if (
              this._commentsInitLoaded &&
              addedComment.commenter !== this.props.store.me.id
            ) {
              const commentedUser = this.props.store.getUser(
                addedComment.commenter
              );
              toast.info(
                `閲覧中のページに${commentedUser?.name}がコメントを追加しました`
              );

              setTimeout(() => {
                // すぐ実行すると、まだコンポーネントが存在せずスクロールできない
                this.scrollToComment(addedComment.id);
                // 既読にする
                setTimeout(this.markAsRead, 400);
              }, 200);
            }
          }
        });
        this.setState({ comments });
        if (!snapshot.metadata.fromCache) this._commentsInitLoaded = true;
      },
      (err) => {
        console.log(`Encountered error: ${err}`);
      }
    );
  };

  syncEvents = (message) => {
    this.unsubscribeEvents();
    let q = this.props.store
      .companyCollection('events')
      .where('teamId', '==', message.teamId)
      .where('messageId', '==', message.id);
    this._unsubscribeEvents = q.onSnapshot(
      (snapshot) => {
        let events = [];
        snapshot.forEach((doc) => events.push(new EventEntity(doc)));
        events.sort((a, b) => a.date.valueOf() - b.date.valueOf());
        this.setState({ events });
      },
      (err) => {
        console.log(`Encountered error: ${err}`);
      }
    );
  };

  syncLocks = (message) => {
    this.unsubscribeLocks();
    let q = this.props.store
      .companyCollection('locks')
      .where('teamId', '==', message.teamId)
      .where('messageId', '==', message.id);
    this._unsubscribeLocks = q.onSnapshot(
      (snapshot) => {
        let locks = [];
        snapshot.forEach((doc) => locks.push(new LockEntity(doc)));
        this.setState({ locks });
      },
      (err) => {
        console.log(`Encountered error: ${err}`);
      }
    );
  };

  syncSent = (message) => {
    const { sent } = this.props;
    if (sent && message.teamId !== sent.teamId) {
      // After replying to a team message from a private inbox, sent.teamId is
      // different from message.teamId.
      this.setState({
        sent: [sent],
      });
      setTimeout(() => {
        this.scrollToSent(sent.id);
      }, 200);
      return;
    }
    this.unsubscribeSent();

    const unsubscribe1 = onSnapshot(
      query(
        companyCollection('sent', sentConverter),
        where('teamId', '==', message.teamId),
        where('inReplyToMessageId', '==', message.id)
      ),
      (snapshot) => {
        let sent = [];
        snapshot.forEach((doc) => sent.push(doc.data()));
        sent.sort((a, b) => a.date.diff(b.date));
        this.setState({ sent });

        // 最後に送信されたメッセージにスクロールする
        setTimeout(() => {
          if (this.props.commentId || this.props.sentId) {
            return;
          }
          const lastSent = sent.at(-1);
          if (!lastSent) {
            return;
          }
          this.scrollToSent(lastSent.id);
        }, 1000);
      },
      (err) => {
        console.log(`Encountered error: ${err}`);
      }
    );
    const unsubscribe2 = onSnapshot(
      query(
        companyCollection('sent', sentConverter),
        where('teamId', '==', message.teamId),
        where('inReplyToMessageId', '==', message.id),
        where('createdAt', '>', Timestamp.now())
      ),
      (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === 'added') {
            setTimeout(() => {
              this.scrollToSent(change.doc.id);
            }, 200);
          }
        });
      }
    );

    this._unsubscribeSent = () => {
      unsubscribe1();
      unsubscribe2();
    };
  };

  unsubscribeSyncMessage = () => {
    if (this._unsubscribeSyncMessage) this._unsubscribeSyncMessage();
  };

  unsubscribeComments = () => {
    if (this._unsubscribeComments) this._unsubscribeComments();
  };

  unsubscribeEvents = () => {
    if (this._unsubscribeEvents) this._unsubscribeEvents();
  };

  unsubscribeLocks = () => {
    if (this._unsubscribeLocks) this._unsubscribeLocks();
  };

  unsubscribeSent = () => {
    if (this._unsubscribeSent) this._unsubscribeSent();
  };

  addComment = () => {
    setTimeout(this.scrollToBottom, 200);
  };

  markMessageAsDeleted = async (message) => {
    toast.destroy();
    await updateDoc(message.ref, {
      deleted: true,
      updatedAt: serverTimestamp(),
    });
    this.props.history.push('./');
    toast.success(
      <>
        メール "{message.subject}"（件名） をゴミ箱に移動しました
        <Button type="link" onClick={() => this.restoreMessage(message)}>
          取り消す
        </Button>
      </>
    );
  };

  restoreMessage = async (message) => {
    toast.destroy();
    await updateDoc(message.ref, {
      deleted: false,
      deletedAt: null,
      deletedBy: null,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
    this.props.history.push('./');
    toast.success(
      <>
        ゴミ箱のメール "{message.subject}"（件名） を元に戻しました
        <Button type="link" onClick={() => this.markMessageAsDeleted(message)}>
          取り消す
        </Button>
      </>
    );
  };

  deleteMessage = async (message) => {
    Modal.confirm({
      title: `メール "${message.subject}"（件名） を削除しますか？`,
      content: (
        <div>
          <p>
            一度削除すると元に戻せません。
            <br />
            下記のコンテンツが削除されます。
          </p>
          <Card title={'削除対象のコンテンツ'}>
            ・メール
            <br />
            ・メールへの返信中の下書き
            <br />
            ・この会話のコメント
            <br />
            ・この会話のイベント（例：〇〇が返信を開始しました。）
            <br />
          </Card>
        </div>
      ),
      onOk: async () => {
        this.props.history.push('./');
        // リッスンしているユーザに削除を伝えるため、一度deletedAtを付けて更新している
        await updateDoc(message.ref, {
          deletedAt: serverTimestamp(),
          deletedBy: this.props.store.me.id,
          updatedAt: serverTimestamp(),
        });
        // 物理的に削除する
        await deleteDoc(message.ref);
        logEvent(eventNames.remove_message);
      },
      onCancel: () => {},
      okText: '削除',
      cancelText: 'キャンセル',
      okType: 'danger',
      maskClosable: true,
    });
  };

  render() {
    const { messageId, commentId, sentId } = this.props;
    const { comments, message, events, locks, sent } = this.state;

    const drafts = this.props.store.getAllDrafts(messageId);
    const lock = locks.length > 0 ? locks[0] : null;

    const teamMembers = message
      ? this.props.store.getUsersByTeamId(message.teamId)
      : [];

    let conversations = [comments, events, sent].flat();
    conversations.sort((a, b) => a.date.diff(b.date));
    const sortedDrafts = drafts.slice().sort((a, b) => a.date.diff(b.date));
    sortedDrafts.forEach((draft) => (draft.status = message?.status));
    conversations = [...conversations, ...sortedDrafts]; // 下書きは常に下に持ってくる

    const isReadOnly = this.props.store.me.isReadOnly;

    return (
      <Wrapper id="ConversationContainer">
        {/* ヘッダー */}
        {message && (
          <ConversationHeader
            message={message}
            locked={Boolean(lock)}
            updateStatus={this.updateStatus}
            teamMembers={teamMembers}
            users={this.props.store.users}
            updateAssignee={this.updateAssignee}
            restoreMessage={this.restoreMessage}
            deleteMessage={this.deleteMessage}
            backLink={this.backLink}
            onRemoveTag={this.handleRemoveTag}
            ref={this.headerRef}
          />
        )}

        <Conversation ref={this.messageDetailRef}>
          <ThreadConversationWrapper>
            {message?.isAutoReplied && <AutoRepliedIndication />}

            {/* メッセージ */}
            {message && (
              <Message
                message={message}
                lock={lock}
                startReply={this.startReply}
                startForwarding={this.startForwarding}
                markAsRead={this.markAsRead}
                scrollToAnchorComment={this.scrollToAnchorComment}
                scrollToBottom={this.scrollToBottom}
                markMessageAsDeleted={this.markMessageAsDeleted}
                restoreMessage={this.restoreMessage}
                deleteMessage={this.deleteMessage}
                store={this.props.store}
                collapsed={sentId && this.props.store.preferences.threadView}
              />
            )}

            {conversations.map((conversation) => {
              if (conversation.type === 'Comment') {
                return (
                  <Comment
                    comment={conversation}
                    key={conversation.id}
                    highlight={conversation.id === commentId}
                  />
                );
              } else if (conversation.type === 'Sent') {
                return (
                  <Sent
                    sent={conversation}
                    startReply={this.startReplyToSent}
                    startForwarding={this.startForwardingToSent}
                    scrollToAnchorSent={this.scrollToAnchorSent}
                    scrollToBottom={this.scrollToBottom}
                    lock={lock}
                    me={this.props.store.me}
                    getUser={this.props.store.getUser}
                    key={conversation.id}
                    highlight={conversation.id === sentId}
                    isReadOnly={isReadOnly}
                  />
                );
              }
              switch (conversation.constructor) {
                case EventEntity:
                  // イベント
                  return <Event event={conversation} key={conversation.id} />;
                case DraftEntity:
                  // 予約送信
                  if (conversation.isScheduled) {
                    return (
                      <Scheduled draft={conversation} key={conversation.id} />
                    );
                  }

                  // 他のメンバーの下書き
                  if (conversation.drafter !== this.props.store.me.id) {
                    return (
                      <OtherMemberDraft
                        draft={conversation}
                        key={conversation.id}
                      />
                    );
                  }

                  // 下書き
                  return <Draft draft={conversation} key={conversation.id} />;
                default:
                  // 基本起こり得ない
                  return <div />;
              }
            })}
          </ThreadConversationWrapper>
          <SPOnly>
            <AddComment
              message={message}
              me={this.props.store.me}
              addComment={this.addComment}
              teamMembers={teamMembers}
              users={this.props.store.users}
              groups={this.props.store.groupStore.groups}
              placeholder="チーム内コメント（@でメンション）"
              useMention
              disabled={isReadOnly}
            />
          </SPOnly>
        </Conversation>

        {/* コメント追加 */}
        <PCOnly>
          <AddComment
            message={message}
            me={this.props.store.me}
            addComment={this.addComment}
            teamMembers={teamMembers}
            users={this.props.store.users}
            groups={this.props.store.groupStore.groups}
            placeholder="チーム内コメント（@でメンション）"
            useMention
            disabled={isReadOnly}
          />
        </PCOnly>
      </Wrapper>
    );
  }
}

export default compose(withRouter, inject('store'), observer)(Index);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  flex: 1;
  background-color: ${color.sidebar.background.normal};
  overflow: auto;
  ${media.lessThan('small')`
    height: 100vh;
  `}
`;

const Conversation = styled.div`
  flex: 1;
  background-color: ${color.sidebar.background.normal};
`;
