import { Check } from 'components/icons';

export const PlanFeatures: React.FC<{
  items: PlanFeatureItemProps[];
}> = ({ items }) => {
  return (
    <>
      {items.map((item, i) => (
        <PlanFeatureItem {...item} key={`feature${i}`} />
      ))}
    </>
  );
};

// PlanFeatureItem
export type PlanFeatureItemProps = {
  text: string;
  bold?: boolean;
};

const PlanFeatureItem: React.FC<PlanFeatureItemProps> = ({ text, bold }) => {
  return (
    <div className={`${bold ? 'font-semibold' : ''} text-sm`}>
      <Check className="mr-1.5 text-[#B4B4B7]" />
      {text}
    </div>
  );
};
