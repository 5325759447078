import { observer } from 'mobx-react';
import { useStore } from '../../../hooks/useStore';
import { isSP } from '../../../shared/util';
import CreateMessage from '../CreateMessage';
import React, { useLayoutEffect, useMemo, useRef } from 'react';
import { Modal } from 'antd';
import Draggable from 'react-draggable';
import { Close } from '../../../components/icons';
import { Icon } from '../../../components/basics';
import { useWindowEvent } from '../../../hooks/useWindowEvent';
import SimpleBar from 'simplebar-react';
import { throttle } from 'lodash';

const MIN_MODAL_HEIGHT = 600;
const MODAL_HEIGHT_RATIO = 0.7;
const MODAL_WIDTH_RATIO = 20 / 21;

export const MessageModal = observer(() => {
  const modalRef = useRef<HTMLDivElement>(null);
  const store = useStore();
  const { newDraft: _newDraft, newDraftAdding } = store;
  const newDraft = _newDraft ? store.getDraft(_newDraft.id) : undefined;
  const closeDraft = async () => {
    store.newDraft = null;
  };

  const updateModalHeight = () => {
    const modalElement = modalRef.current;
    if (!modalElement) {
      return;
    }
    const modalHeight = Math.max(
      window.innerHeight * MODAL_HEIGHT_RATIO,
      MIN_MODAL_HEIGHT
    );
    const modalWidth = modalHeight * MODAL_WIDTH_RATIO;
    modalElement.style.width = `${modalWidth}px`;
    modalElement.style.setProperty('--modal-width', `${modalWidth}px`);
  };
  useLayoutEffect(() => {
    if (!newDraft && !newDraftAdding) {
      return;
    }
    updateModalHeight();
  }, [newDraft, newDraftAdding]);
  const throttledUpdateModalSize = useMemo(
    () => throttle(updateModalHeight, 100),
    [updateModalHeight]
  );
  useWindowEvent('resize', () => throttledUpdateModalSize());

  if (isSP()) {
    if (newDraft) {
      return (
        <Modal
          visible={Boolean(newDraft)}
          footer={null}
          closable={false}
          bodyStyle={{ padding: '16px' }}
          className="fixed inset-0 z-50 !m-0 !max-w-full !rounded-none !p-0 md:relative md:top-24 md:m-auto"
        >
          <button
            onClick={closeDraft}
            className="h-5 w-5 cursor-pointer bg-transparent !p-0"
          >
            <Close />
          </button>
          <CreateMessage draft={newDraft} deleteCallback={closeDraft} />
        </Modal>
      );
    } else {
      return <div />;
    }
  }

  if (!newDraft && !newDraftAdding) {
    return <div />;
  }

  return (
    <Draggable
      bounds="body"
      cancel="form, button, .ant-modal-wrap, .simplebar-dragging"
    >
      <div
        id={`modal-${newDraft?.id}`}
        className="fixed bottom-0 right-[60px] z-[1000] grid h-[70%] max-h-full max-w-full cursor-move grid-rows-[auto_calc(100%_-_theme(spacing.9))] overflow-hidden rounded-lg bg-white shadow outline-0"
        style={{ minHeight: MIN_MODAL_HEIGHT }}
        ref={modalRef}
      >
        <div className="grid h-9 grid-cols-[1fr_auto] items-center justify-between bg-sumi-100 pl-3">
          <div className="truncate">
            {newDraft?.subject || '新規メッセージ'}
          </div>
          <button
            type="button"
            className="flex h-9 w-9 cursor-pointer items-center justify-center bg-transparent p-0"
            onClick={closeDraft}
            disabled={newDraftAdding}
          >
            <Icon icon={Close} size={12} />
          </button>
        </div>
        <SimpleBar
          className="h-full w-[var(--modal-width)] max-w-full"
          classNames={{
            contentEl: 'simplebar-content h-full',
          }}
        >
          <div className="grid min-h-full cursor-auto grid-rows-[1fr] px-4 pb-4 pt-3">
            {newDraftAdding ? (
              <div className="flex h-[550px] items-center justify-center">
                <Icon icon="loading" size={24} />
              </div>
            ) : (
              <CreateMessage draft={newDraft} deleteCallback={closeDraft} />
            )}
          </div>
        </SimpleBar>
      </div>
    </Draggable>
  );
});
