import React, { VFC } from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../../../../hooks/useStore';
import { MessageLike } from 'lib';
import { Message } from '../../Teams/Messages/Message';
import { Icon, Spin } from 'antd';
import { useHistory } from 'react-router-dom';
import { WrappedInfiniteScroll } from '../../../../../components/WrappedInfiniteScroll';
import { useAtom, useAtomValue } from 'jotai';
import { commentedMessagesAtom } from '../../../../../atoms/firestore/comment';
import { meAtom, threadViewAtom } from '../../../../../atoms/auth';

const Index: VFC = observer(() => {
  const store = useStore();
  const threadView = useAtomValue(threadViewAtom);
  const me = useAtomValue(meAtom);
  const [messagesPaginate, dispatch] = useAtom(commentedMessagesAtom);
  const history = useHistory();
  const to = (link: string) => history.push(link);
  const loadMore = async () => {
    await dispatch('loadMore');
  };
  const toLink = (message: MessageLike) => {
    let url = `/me/commented/messages/${message.id}/comments/${message.latestComment?.commentId}`;
    if (threadView) {
      url += '?view=thread';
    }
    return url;
  };
  return (
    <WrappedInfiniteScroll
      initialLoad={false}
      pageStart={0}
      loadMore={loadMore}
      hasMore={messagesPaginate.hasMore}
      useWindow={false}
      className="pt-4"
    >
      <Spin
        spinning={messagesPaginate.state === 'loading'}
        indicator={<Icon type="loading" spin />}
      >
        {messagesPaginate.state === 'hasData' &&
          messagesPaginate.data.map((message) => (
            <Message
              key={message.id}
              message={message}
              comment={message.latestComment}
              checkMessage={store.checkMessage}
              checkDisabled={true}
              uncheckMessage={store.uncheckMessage}
              checkedMessages={store.checkedMessages}
              to={toLink(message)}
              onClick={() => to(toLink(message))}
              me={me}
              getTag={store.getTag}
              activeOnlyWhenExact={undefined}
              showOpenInTab={undefined}
              hideInboxTag={undefined}
              hideStatusTag={undefined}
              isReadOnly={me.isReadOnly}
            />
          ))}
      </Spin>
    </WrappedInfiniteScroll>
  );
});

export default Index;
