import React, {
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Icon } from '../../basics';
import { Close } from '../../icons';
import { v4 } from 'uuid';
import { useWindowEvent } from '../../../hooks/useWindowEvent';
import * as Portal from '@radix-ui/react-portal';
import { clamp, throttle } from 'lodash';
import { tv } from 'tailwind-variants';

type Entry = {
  value: string;
  text: string;
};

type Props = {
  value: string[];
  onChange: (value: string[]) => void;
  suggests: string[] | Entry[];
  validate?: (value: string) => boolean;
  addable?: boolean;
  disabled?: boolean;
  dropdownContainer?: HTMLElement;
  renderItem?: (state: RenderState) => ReactElement;
};

type DropdownState = {
  x: number;
  y: number;
  width: number;
};

type RenderState = {
  entry: Entry;
  closeButton: boolean;
  preDelete: boolean;
  disabled: boolean;
  remove: () => void;
};

const inputWrapper = tv({
  base: 'min-h-[theme(height.10)] cursor-text rounded-lg border border-sumi-300 bg-white p-2',
  variants: {
    disabled: {
      true: 'cursor-not-allowed text-sumi-500',
    },
  },
});

const tag = tv({
  base: 'grid h-6 grid-cols-[1fr_auto] items-center rounded-lg border border-sumi-200 bg-sumi-50',
  variants: {
    disabled: {
      true: 'px-2',
      false: 'pl-2',
    },
    preDelete: {
      true: 'border-sun-200 bg-sun-100',
    },
  },
});

const suggest = tv({
  base: 'min-h-[theme(height.8)] w-full cursor-pointer select-none truncate whitespace-nowrap rounded-lg bg-transparent p-0 px-2 text-start text-base',
  variants: {
    selected: {
      true: 'bg-sumi-200',
    },
  },
});

export const MultipleInput = ({
  value,
  onChange,
  suggests,
  validate,
  addable = false,
  disabled = false,
  dropdownContainer,
  renderItem,
}: Props) => {
  const inputId = useMemo(() => `multiInput_${v4()}`, []);
  const portalId = useMemo(() => `multiInput_${v4()}`, []);
  const [inputValue, setInputValue] = useState('');
  const [inputWidth, setInputWidth] = useState(0);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const mirrorRef = useRef<HTMLDivElement>(null);
  const [hasFocus, setHasFocus] = useState(false);
  const [dropdownState, setDropdownState] = useState<DropdownState>({
    x: 0,
    y: 0,
    width: 0,
  });
  const [preDelete, setPreDelete] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  useEffect(() => {
    const mirrorElement = mirrorRef.current;
    if (!mirrorElement) {
      return;
    }
    setInputWidth(mirrorElement.clientWidth);
  }, [inputValue]);
  const isAddable = (target: Entry): boolean => {
    if (value.includes(target.value)) {
      return false;
    }

    if (addable) {
      return true;
    }

    if (suggests.length === 0) {
      return false;
    } else if (typeof suggests[0] === 'string') {
      return (suggests as string[]).includes(target.value);
    } else {
      return (suggests as Entry[]).some((s) => s.value === target.value);
    }
  };
  const onSubmitInput = (target: Entry) => {
    inputRef.current?.focus();

    if (!isAddable(target)) {
      return;
    }

    if (validate && !validate(target.value)) {
      return;
    }

    onChange([...value, target.value]);
    setInputValue('');
  };
  const onDelete = (index: number) => {
    const newValue = value.filter((_e, i) => i !== index);
    onChange(newValue);
    inputRef.current?.focus();
  };

  const updateDropdownState = () => {
    const wrapperElement = wrapperRef.current;
    if (!wrapperElement) {
      return;
    }

    const rect = wrapperElement.getBoundingClientRect();
    setDropdownState({
      x: rect.x,
      y: rect.y + rect.height + 4,
      width: rect.width,
    });
  };

  useEffect(() => {
    const wrapperElement = wrapperRef.current;
    if (!wrapperElement) {
      return;
    }

    updateDropdownState(); // 初期化

    const update = () => {
      updateDropdownState();
    };

    const throttledUpdate = throttle(updateDropdownState, 100);
    window.addEventListener('scroll', update);
    const observer = new ResizeObserver(() => {
      throttledUpdate();
    });
    observer.observe(wrapperElement);
    return () => {
      window.removeEventListener('scroll', update);
      observer.disconnect();
    };
  }, []);

  useWindowEvent('click', (e) => {
    const target = e.target;
    if (!(target instanceof Element)) {
      return;
    }
    if (!hasFocus) {
      return;
    }

    if (!target.closest(`#${inputId}`) && !target.closest(`#${portalId}`)) {
      setHasFocus(false);
    }
  });

  const convertSuggests = (suggests: string[] | Entry[]): Entry[] => {
    if (suggests.length === 0) {
      return [];
    } else if (typeof suggests[0] === 'string') {
      return (suggests as string[]).map((s) => ({ value: s, text: s }));
    } else {
      return suggests as Entry[];
    }
  };

  const convertedSuggests = convertSuggests(suggests);
  const isValueFound = convertedSuggests.some((s) => s.text === inputValue);
  if (!isValueFound && inputValue && addable) {
    convertedSuggests.splice(0, 0, { value: inputValue, text: inputValue });
  }
  const filteredSuggests = convertedSuggests
    .filter((s) => isAddable(s))
    .filter((s) => s.text.toLowerCase().startsWith(inputValue.toLowerCase()));

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const inputValue = e.currentTarget.value.trim();

    if (e.key === 'Backspace' && inputValue.length === 0) {
      if (preDelete && !e.repeat) {
        console.log('delete');
        onDelete(value.length - 1);
        setPreDelete(false);
      } else {
        setPreDelete(true);
      }
    } else {
      setPreDelete(false);
    }

    if (e.key === ' ') {
      e.preventDefault();
      if (inputValue.length > 0) {
        onSubmitInput({ value: inputValue, text: inputValue });
      }
      return;
    }
    if (e.key === 'Enter') {
      e.preventDefault();
      const selected = filteredSuggests.at(selectedIndex);
      if (selected) {
        onSubmitInput(selected);
      }
      return;
    }

    let direction = 0;
    if (e.key === 'ArrowUp') {
      e.preventDefault();
      direction = -1;
    } else if (e.key === 'ArrowDown') {
      e.preventDefault();
      direction = 1;
    }
    if (direction !== 0) {
      const newIndex = clamp(
        selectedIndex + direction,
        0,
        filteredSuggests.length - 1
      );
      setSelectedIndex(newIndex);
    }
  };

  return (
    <>
      <div
        id={inputId}
        className={inputWrapper({ disabled })}
        onClick={(e) => {
          e.stopPropagation();
          inputRef.current?.focus();
        }}
        ref={wrapperRef}
        data-testid="multiinput"
      >
        <div
          className="flex min-h-[theme(height.6)] flex-wrap items-center gap-2"
          data-testid="multiinput-values"
        >
          {value.map((v, i) => {
            const found = convertSuggests(suggests).find((s) => s.value === v);
            let label = (
              <div
                className={tag({
                  disabled,
                  preDelete: preDelete && i === value.length - 1,
                })}
              >
                <span className="select-none overflow-hidden truncate whitespace-nowrap leading-4">
                  {found?.text ?? v}
                </span>
                {disabled ? (
                  <span />
                ) : (
                  <button
                    type="button"
                    className="flex h-6 w-6 cursor-pointer items-center justify-center bg-transparent p-0"
                    onClick={() => onDelete(i)}
                    data-testid="multiinput-delete-button"
                  >
                    <Icon icon={Close} size={8} />
                  </button>
                )}
              </div>
            );
            if (renderItem) {
              label = renderItem({
                entry: found ?? { value: v, text: v },
                closeButton: !disabled,
                preDelete: preDelete && i === value.length - 1,
                disabled,
                remove: () => onDelete(i),
              });
            }
            return (
              <div key={i} onClick={(e) => e.stopPropagation()}>
                {label}
              </div>
            );
          })}
          <input
            className="block bg-transparent p-0 text-sm outline-none"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            style={{ width: Math.max(inputWidth, 2) }}
            ref={inputRef}
            onKeyDown={onKeyDown}
            onFocus={() => {
              setHasFocus(true);
              updateDropdownState();
            }}
            spellCheck={false}
            disabled={disabled}
          />
        </div>
        <div className="invisible relative h-0 w-0 overflow-hidden">
          <div className="absolute whitespace-nowrap text-sm" ref={mirrorRef}>
            {inputValue}
          </div>
        </div>
      </div>
      {hasFocus && (
        <Portal.Root container={dropdownContainer} asChild>
          <div
            id={portalId}
            className="absolute left-0 top-0 z-[50000] flex max-h-[25%] flex-col overflow-auto rounded-lg bg-white p-2 shadow-dropdown"
            style={{
              transform: `translate3d(${dropdownState.x}px, ${dropdownState.y}px, 0)`,
              width: dropdownState.width,
            }}
          >
            {filteredSuggests.length <= 0 && (
              <div className="text-center text-sumi-500">
                データがありません
              </div>
            )}
            {filteredSuggests.map((entry, i) => (
              <button
                type="button"
                key={i}
                className={suggest({ selected: selectedIndex === i })}
                onClick={() => onSubmitInput(entry)}
                onPointerEnter={() => setSelectedIndex(i)}
                data-selected={selectedIndex === i}
              >
                {renderItem
                  ? renderItem({
                      entry: entry,
                      closeButton: false,
                      preDelete: false,
                      disabled,
                      remove: () => {
                        //
                      },
                    })
                  : entry.text}
              </button>
            ))}
          </div>
        </Portal.Root>
      )}
    </>
  );
};
