import { Atom, atom, WritableAtom } from 'jotai';
import {
  limit,
  onSnapshot,
  query,
  Query,
  QuerySnapshot,
} from 'firebase/firestore';
import { store } from '../providers/StoreProvider';
import { registerUnsubscribe, unsubscribe } from '../firestore';
import { sum } from 'lodash';
import * as Sentry from '@sentry/react';

export type Paginate<T> = (
  | {
      state: 'loading';
    }
  | {
      state: 'hasData';
      data: T[];
      currentLimit: number;
    }
  | {
      state: 'hasError';
      error: unknown;
    }
) & {
  hasMore: boolean;
};

export type PaginateAction = 'reset' | 'loadMore' | 'unsubscribe';

export const LoadingPaginate: Paginate<unknown> = {
  state: 'loading',
  hasMore: true,
};

export type PaginateAtom<T> = WritableAtom<
  Paginate<T>,
  [PaginateAction],
  Promise<void>
>;

export const atomWithPaginate = <T, R = T>(
  q: Query<T> | (() => Query<T>),
  onNext: (snapshot: QuerySnapshot<T>, prev: R[]) => R[] | Promise<R[]>,
  limitPerPage = 40
): PaginateAtom<R> => {
  const limitAtom = atom(limitPerPage);
  const dataAtom = atom<Paginate<R> | undefined>(undefined);
  const subscribe = (limitNum: number) => {
    let initialLoad = true;
    const unsub = onSnapshot(
      query(typeof q === 'function' ? q() : q, limit(limitNum)),
      async (snapshot) => {
        const prevPaginate = store.get(dataAtom);
        const prev =
          !initialLoad && prevPaginate?.state === 'hasData'
            ? [...prevPaginate.data]
            : [];
        store.set(dataAtom, {
          state: 'hasData',
          data: await onNext(snapshot, prev),
          currentLimit: limitNum,
          hasMore: snapshot.size === limitNum,
        });
        initialLoad = false;
      },
      (error) => {
        Sentry.captureException(error);
        store.set(dataAtom, {
          state: 'hasError',
          error,
          hasMore: false,
        });
      }
    );
    registerUnsubscribe(dataAtom, unsub);
  };
  return atom(
    (get) => {
      const data = get(dataAtom);
      const currentLimit = get(limitAtom);
      if (data) {
        if (data.state === 'hasData' && data.currentLimit !== currentLimit) {
          subscribe(currentLimit);
        }
        return data;
      }

      subscribe(limitPerPage);
      store.set(dataAtom, LoadingPaginate);
      return LoadingPaginate;
    },
    async (get, set, action) => {
      switch (action) {
        case 'reset':
          set(limitAtom, limitPerPage);
          break;
        case 'loadMore':
          set(limitAtom, (prev) => prev + limitPerPage);
          break;
        case 'unsubscribe':
          unsubscribe(dataAtom);
      }
    }
  );
};

type SizeCalculator<K, V> = (value: V, key: K) => number;
type AtomDisposer<K, V, A extends Atom<V>> = (value: A, key: K) => void;

type AtomLRUCacheOptions<K, V, A extends Atom<V>> = {
  max: number;
  maxSize: number;
  sizeCalculator: SizeCalculator<K, V>;
  dispose?: AtomDisposer<K, V, A>;
};

class AtomLRUCache<K extends NonNullable<unknown>, V, A extends Atom<V>>
  implements Iterable<[K, A]>
{
  readonly max: number;
  readonly maxSize: number;
  readonly sizeCalculator: SizeCalculator<K, V>;
  readonly #dispose?: AtomDisposer<K, V, A>;

  #map: Map<K, A> = new Map();
  #keys: K[] = [];
  #sizeMap: Map<K, number> = new Map();
  #unsubscribes: Map<K, () => void> = new Map();

  #calculatedSize = 0;

  constructor({
    max,
    maxSize,
    sizeCalculator,
    dispose,
  }: AtomLRUCacheOptions<K, V, A>) {
    this.max = max;
    this.maxSize = maxSize;
    this.sizeCalculator = sizeCalculator;
    this.#dispose = dispose;
  }

  [Symbol.iterator]() {
    return this.#map[Symbol.iterator]();
  }

  get(key: K): A | undefined {
    const anAtom = this.#map.get(key);
    if (!anAtom) {
      return;
    }
    this.#keys = this.#keys.filter((k) => k !== key);
    this.#keys.push(key);
    return anAtom;
  }

  set(key: K, value: A) {
    this.#unsubscribes.set(
      key,
      store.sub(value, () => {
        this.#calculateSize(key);
      })
    );
    if (this.#map.has(key)) {
      this.#keys = this.#keys.filter((k) => k !== key);
      this.#disposeByKey(key);
    } else if (this.#map.size === this.max) {
      this.#evict();
    }
    this.#keys.push(key);
    this.#map.set(key, value);
    this.#calculateSize(key);
  }

  #disposeByKey(key: K) {
    const value = this.#map.get(key) as A;
    this.#dispose?.(value, key);
    this.#unsubscribes.get(key)?.();
    this.#unsubscribes.delete(key);
  }

  #calculateSize(key: K) {
    const anAtom = this.#map.get(key);
    if (!anAtom) {
      throw new Error('key not found');
    }
    const size = this.sizeCalculator(store.get(anAtom), key);
    if (size < 0) {
      throw new Error('calculated size is negative');
    }
    const oldSize = this.#sizeMap.get(key);
    if (oldSize && oldSize === size) {
      return;
    }
    this.#sizeMap.set(key, size);
    this.#calculatedSize = sum([...this.#sizeMap.values()]);
    if (this.#keys.length <= 1) {
      return;
    }
    while (this.#calculatedSize > this.maxSize) {
      this.#evict();
    }
  }

  #evict() {
    const key = this.#keys.shift() as K;
    this.#disposeByKey(key);
    this.#map.delete(key);
    const evictedSize = this.#sizeMap.get(key) as number;
    this.#calculatedSize -= evictedSize;
    this.#sizeMap.delete(key);
  }
}

type LRUFamilyParams<K extends NonNullable<unknown>, V, A extends Atom<V>> = {
  initializeAtom: (param: K) => A;
  max: number;
  maxSize: number;
  sizeCalculator: SizeCalculator<K, V>;
  dispose?: AtomDisposer<K, V, A>;
  areEqual?: (a: K, b: K) => boolean;
};

export const atomFamilyWithLRU = <
  K extends NonNullable<unknown>,
  V,
  A extends Atom<V>,
>({
  initializeAtom,
  max,
  maxSize,
  sizeCalculator,
  dispose,
  areEqual,
}: LRUFamilyParams<K, V, A>) => {
  const cache = new AtomLRUCache<K, V, A>({
    max,
    maxSize,
    sizeCalculator,
    dispose,
  });
  return (params: K) => {
    let item: A | undefined;
    if (areEqual) {
      for (const [key, value] of cache) {
        if (areEqual(key, params)) {
          item = value;
          break;
        }
      }
    } else {
      item = cache.get(params);
    }

    if (item) {
      return item;
    }
    const newAtom = initializeAtom(params);
    cache.set(params, newAtom);
    return newAtom;
  };
};
