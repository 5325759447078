import { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Button, Dropdown, Icon, Menu, Table, Tag } from 'antd';
import { ClickParam } from 'antd/lib/menu';
import styled from 'styled-components';

import { useStore } from 'hooks/useStore';
import { ContactObject } from 'store/contact';
import { PaginationConfig, SorterResult, SortOrder } from 'antd/lib/table';
import { observer } from 'mobx-react';
import { AccountName } from './AccountName';
import { getDocs, query, where } from 'firebase/firestore';
import { normalizeAddress, unsubscribeConverter, UnsubscribeData } from 'lib';
import { companyCollection } from '../../../firestore';
import { Loading } from '../../../components/basics';

interface Props {
  keyword: string;
  existingTags: string[];
  selectedContactIds: string[];
  setSelectedContactIds: (ids: string[] | number[]) => void;
}

type ContactObjectWithStatus = ContactObject & {
  status: 'loading' | 'available' | 'unavailable' | 'partial';
};

export const Contacts = observer(
  ({
    keyword,
    existingTags,
    selectedContactIds,
    setSelectedContactIds,
  }: Props): JSX.Element => {
    const match = useRouteMatch<{ teamId: string }>();
    const history = useHistory();
    const { contactStore, me, getTeamInboxes, inboxesLoading } = useStore();
    const isReadOnly = me.isReadOnly;
    const { searchedContacts, page, hasMore, searching } = contactStore;
    const [sortOrder, setSortOrder] = useState('');
    const [tags, setTags] = useState<string[]>([]);
    const [unsubscribeEntries, setUnsubscribeEntries] = useState<
      UnsubscribeData[] | undefined
    >(undefined);

    useEffect(() => {
      contactStore.search(1, match.params.teamId, keyword, sortOrder, tags);
    }, [match.params.teamId, keyword, contactStore, sortOrder, tags]);

    useEffect(() => {
      const inboxes = getTeamInboxes(match.params.teamId ?? '');
      if (!inboxes) {
        return;
      }
      const teamEmails = inboxes.map((i) => normalizeAddress(i.email));
      if (teamEmails.length === 0) {
        return;
      }
      getDocs(
        query(
          companyCollection('unsubscribes', unsubscribeConverter),
          where('email', 'in', teamEmails)
        )
      ).then((res) => {
        const array = res.docs.flatMap((d) => d.data().data);
        const defaultData: UnsubscribeData[] = teamEmails
          .filter((e) => array.every((a) => a.email !== e))
          .map((email) => ({
            email,
            targets: [],
          }));
        setUnsubscribeEntries([...defaultData, ...array]);
      });
    }, [match.params.teamId, inboxesLoading]);

    const sortDirections: SortOrder[] = ['ascend', 'descend', 'ascend'];

    const columns = [
      {
        title: '名前',
        dataIndex: 'name',
        sorter: (a: ContactObjectWithStatus, b: ContactObjectWithStatus) =>
          a.name.localeCompare(b.name, 'ja'),
        sortDirections,
        defaultSortOrder: sortDirections[0],
      },
      {
        title: '取引先',
        dataIndex: 'accountId',
        render: (accountId: string) =>
          accountId && <AccountName id={accountId} />,
      },
      {
        title: 'メールアドレス',
        dataIndex: 'email',
        sorter: (a: ContactObjectWithStatus, b: ContactObjectWithStatus) =>
          a.email.localeCompare(b.email, 'ja'),
        sortDirections,
      },
      {
        title: 'タグ',
        dataIndex: 'tags',
        render: (tags: string[]) =>
          tags.length > 0
            ? tags.map((tag) => {
                return (
                  <Tag style={{ marginBottom: 4 }} key={tag}>
                    {tag}
                  </Tag>
                );
              })
            : null,
        filters: existingTags.map((t: any) => ({
          text: t.name,
          value: t.name,
        })),
        onFilter: (value: string, record: ContactObjectWithStatus) =>
          record.tags.includes(value),
      },
      {
        title: '会社名',
        dataIndex: 'companyName',
        sorter: (a: ContactObjectWithStatus, b: ContactObjectWithStatus) =>
          a.companyName.localeCompare(b.companyName, 'ja'),
        sortDirections,
      },
      {
        title: '電話番号',
        dataIndex: 'phoneNumber',
        sorter: (a: ContactObjectWithStatus, b: ContactObjectWithStatus) =>
          a.phoneNumber.localeCompare(b.phoneNumber, 'ja'),
        sortDirections,
      },
      {
        title: '状態',
        dataIndex: 'status',
        sorter: (a: ContactObjectWithStatus, b: ContactObjectWithStatus) =>
          a.status.localeCompare(b.status, 'ja'),
        sortDirections,
        render: (status: ContactObjectWithStatus['status']) => {
          switch (status) {
            case 'loading':
              return <Loading size={16} />;
            case 'available':
              return '配信可能';
            case 'unavailable':
              return '配信停止';
            case 'partial':
              return '一部配信';
          }
        },
      },
      {
        title: 'メモ',
        dataIndex: 'memo',
        ellipsis: true,
        render: (text: string) => <Memo>{text}</Memo>,
        sorter: (a: ContactObject, b: ContactObject) =>
          a.memo.localeCompare(b.memo, 'ja'),
        sortDirections,
      },
    ];

    const onChangeTable = (
      pagination: PaginationConfig,
      filters: Partial<Record<keyof ContactObjectWithStatus, string[]>>,
      sorter: SorterResult<ContactObjectWithStatus>
    ) => {
      const { field, order } = sorter;
      if (order) {
        setSortOrder(field + ':' + (order === 'ascend' ? 'asc' : 'desc'));
      } else {
        setSortOrder('');
      }
      setTags(filters.tags || []);
    };

    const onChangePageSize = (x: ClickParam) => {
      contactStore.changePageSize(+x.key);
    };

    let contactsWithStatus: ContactObjectWithStatus[] = searchedContacts.map(
      (c) => ({
        ...c,
        status: 'loading',
      })
    );
    if (unsubscribeEntries) {
      contactsWithStatus = searchedContacts.map((c) => {
        const normalizedEmail = normalizeAddress(c.email);
        const foundEntries = unsubscribeEntries.filter((d) =>
          d.targets.includes(normalizedEmail)
        );
        let status: ContactObjectWithStatus['status'];
        if (foundEntries.length === 0) {
          status = 'available';
        } else if (foundEntries.length === unsubscribeEntries.length) {
          status = 'unavailable';
        } else {
          status = 'partial';
        }
        const obj: ContactObjectWithStatus = {
          ...c,
          status,
        };
        return obj;
      });
    }

    return (
      <div>
        {!isReadOnly && selectedContactIds.length > 0 && (
          <Button
            size="small"
            type="link"
            onClick={() => setSelectedContactIds([])}
          >
            選択をすべて解除
          </Button>
        )}
        <Table
          rowKey="id"
          rowSelection={{
            selectedRowKeys: selectedContactIds,
            onChange: setSelectedContactIds,
            getCheckboxProps: () => ({
              disabled: isReadOnly,
            }),
          }}
          pagination={false}
          columns={columns}
          dataSource={contactsWithStatus}
          loading={searching}
          locale={{
            filterConfirm: '適用',
            filterReset: 'クリア',
          }}
          size="small"
          onChange={onChangeTable}
          onRow={(record) => ({
            onClick: () =>
              !isReadOnly &&
              history.push(
                `/contacts/${record.teamId}/contacts/contacts/${record.id}`
              ),
          })}
        />
        <Pagination>
          <Dropdown
            overlay={
              <Menu onClick={onChangePageSize}>
                <Menu.Item key="25">ページあたり25件</Menu.Item>
                <Menu.Item key="50">ページあたり50件</Menu.Item>
                <Menu.Item key="100">ページあたり100件</Menu.Item>
              </Menu>
            }
            trigger={['click']}
          >
            <Button style={{ marginRight: '1rem' }}>
              ページあたり{contactStore.pageSize}件 <Icon type="down" />
            </Button>
          </Dropdown>
          <Button
            icon="left"
            style={{ marginRight: 8 }}
            onClick={contactStore.toPrevPage}
            loading={searching}
            disabled={page < 2}
          />
          <Button
            icon="right"
            onClick={contactStore.toNextPage}
            loading={searching}
            disabled={!hasMore}
          />
        </Pagination>
      </div>
    );
  }
);

const Memo = styled.pre`
  margin: 0;
  max-height: 160px;
  overflow: auto;
`;

const Pagination = styled.div`
  float: right;
  margin: 16px 0;
  padding-bottom: 16px;
`;
