import { BubbleMenu } from '@tiptap/react';
import { tv } from 'tailwind-variants';
import { EditorHandle } from '../../Common/Editor/WysiwygEditor/WysiwygEditor';
import { message as antdMessage } from 'antd';
import { convertTextFunction } from '../../../functions';
import { useAtomValue } from 'jotai';
import { linkEditingEditorsAtom } from '../../Common/Editor/LinkBubbleMenu/LinkBubbleMenu';

type AiAssistantType = 'proofreader' | 'translator';

type Props = {
  handle: EditorHandle;
  draftId: string;
};

const button = tv({
  base: 'relative flex h-full cursor-pointer items-center justify-center border border-sumi-300 bg-white px-2 duration-100 hover:z-10 hover:border-sea-500 hover:text-sea-500',
});

export const EditorBubbleMenu = ({ handle, draftId }: Props) => {
  const editor = handle.editor;
  const linkEditingEditors = useAtomValue(linkEditingEditorsAtom);

  if (!editor) {
    return null;
  }

  const convert = (assistant: AiAssistantType) => {
    const { from, to } = editor.state.selection;
    const text = editor.state.doc.textBetween(from, to, '');

    if (!text) {
      return;
    }

    antdMessage.loading({
      content: '本文を変換中です',
      key: draftId,
      duration: 0,
    });

    convertTextFunction({
      assistant,
      content: text,
    })
      .then((result) => {
        const data = result.data;
        const transaction = editor.state.tr.insertText(data, from, to);
        editor.view.dispatch(transaction);
        editor.commands.focus();
        antdMessage.success({
          content: `本文の変換が完了しました`,
          key: draftId,
          duration: 2,
        });
      })
      .catch(() =>
        antdMessage.error({
          content: '本文の変換に失敗しました',
          key: draftId,
        })
      );
  };

  return (
    <BubbleMenu
      pluginKey="editorMenu"
      editor={editor}
      shouldShow={(props) => {
        return (
          !editor.isActive('image') &&
          !editor.isActive('link') &&
          props.from < props.to
        );
      }}
      tippyOptions={{
        appendTo: () => document.getElementById('editorScroll')!,
      }}
    >
      {!linkEditingEditors.includes(editor) && (
        <div className="flex h-6 items-center text-xs text-sumi-500">
          <button
            className={button({ className: 'rounded-l' })}
            onClick={() => convert('proofreader')}
          >
            丁寧にする
          </button>
          <button
            className={button({
              className: 'left-[-1px] rounded-r',
            })}
            onClick={() => convert('translator')}
          >
            翻訳する
          </button>
        </div>
      )}
    </BubbleMenu>
  );
};
