import * as React from 'react';
import type { SVGProps } from 'react';
const SvgTick = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="m7.875 13.084 7.563-7.563a.615.615 0 0 1 .448-.188c.173 0 .322.063.447.19a.614.614 0 0 1 .188.447.61.61 0 0 1-.188.447l-8.02 8.02a.6.6 0 0 1-.438.188.6.6 0 0 1-.437-.187l-3.792-3.792a.58.58 0 0 1-.177-.449.647.647 0 0 1 .199-.447.614.614 0 0 1 .448-.187.61.61 0 0 1 .447.187l3.312 3.334Z"
    />
  </svg>
);
export default SvgTick;
