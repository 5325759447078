import * as React from 'react';
import type { SVGProps } from 'react';
const SvgCheck = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={17}
    fill="none"
    viewBox="0 0 16 17"
    {...props}
  >
    <rect
      width={14.667}
      height={14.667}
      x={0.667}
      y={1.167}
      fill="#B4B4B7"
      rx={7.333}
    />
    <path
      fill="#fff"
      d="m6.7 10.838 4.636-4.884a.368.368 0 0 1 .275-.12c.106 0 .197.04.274.121a.407.407 0 0 1 .115.29.405.405 0 0 1-.115.288l-4.916 5.18a.359.359 0 0 1-.536 0l-2.324-2.45A.385.385 0 0 1 4 8.975a.429.429 0 0 1 .122-.289.367.367 0 0 1 .275-.12c.106 0 .197.04.274.12l2.03 2.153Z"
    />
  </svg>
);
export default SvgCheck;
