import Drafts from '../MessageList/Me/Drafts';
import { useStore } from 'hooks/useStore';
import { BaseMessagePane } from './BaseMessagePane';
import { MessageListEmpty } from 'components/MessageList';
import { observer } from 'mobx-react';

export const PATH = '/me/drafts';

// 自分 - 下書き
export const MessagePaneMeDraft: React.FC = observer(() => {
  const { searchedDrafts, draftsLoading, searchStore } = useStore();
  const getEmptyDescription = () => {
    const { inSearch } = searchStore;
    let description = '下書きはありません';
    if (inSearch) {
      description = `「検索条件と一致する` + description;
    }
    return description;
  };

  return (
    <BaseMessagePane
      listPagePath={PATH}
      empty={searchedDrafts.length === 0}
      emptyComponent={<MessageListEmpty description={getEmptyDescription()} />}
      listComponent={
        <Drafts searchedDrafts={searchedDrafts} draftsLoading={draftsLoading} />
      }
    />
  );
});
