import { matchPath, useHistory, useLocation } from 'react-router-dom';
import { useStore } from '../../../../../hooks/useStore';
import { Empty } from 'antd';
import React from 'react';
import { observer } from 'mobx-react';
import SimpleBar from 'simplebar-react';
import { ScheduledDraftItem } from './ScheduledDraftItem/ScheduledDraftItem';

export const Scheduled = observer(() => {
  const history = useHistory();
  const location = useLocation();
  const store = useStore();

  const getEmptyDescription = () => {
    const {
      searchStore: { inSearch },
    } = store;
    return inSearch
      ? `検索条件と一致する送信予約はありません`
      : '送信予約はありません';
  };

  const searchedScheduledDrafts = store.getSearchedScheduledDrafts;
  return (
    <SimpleBar className="h-full">
      <div className="flex flex-col gap-3 pt-4">
        {searchedScheduledDrafts.length === 0 && (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={getEmptyDescription()}
          />
        )}
        {searchedScheduledDrafts.map((d) => {
          const link = `/me/scheduled/${d.id}`;
          const active =
            matchPath(location.pathname, {
              path: link,
            }) != null;
          return (
            <ScheduledDraftItem
              key={d.id}
              subject={d.subject}
              body={d.body}
              scheduledAt={d.scheduledAt!.toDate()}
              toAddresses={d.to}
              active={active}
              failed={d.isFailed}
              onClick={() => history.push(link)}
            />
          );
        })}
      </div>
    </SimpleBar>
  );
});
