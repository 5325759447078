import * as React from 'react';
import type { SVGProps } from 'react';
const SvgCaretDown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={10}
    height={6}
    fill="none"
    viewBox="0 0 10 6"
    {...props}
  >
    <path
      fill="currentColor"
      d="M5 5.562a.612.612 0 0 1-.229-.041.635.635 0 0 1-.208-.146L.438 1.25A.573.573 0 0 1 .282.802.64.64 0 0 1 .459.354C.598.215.747.156.907.177c.16.02.302.087.427.198L5 4.042 8.667.375a.68.68 0 0 1 .448-.188.493.493 0 0 1 .448.188.46.46 0 0 1 .177.437.811.811 0 0 1-.198.459L5.438 5.375a.635.635 0 0 1-.208.146.612.612 0 0 1-.23.041Z"
    />
  </svg>
);
export default SvgCaretDown;
