import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { H1 } from '../../Common/H1';
import NewAccount from './newAccount';
import EditAccount from './editAccount';
import { Button, Input, message, Modal, Table, Tabs, Tag, Upload } from 'antd';
import { db } from '../../../firebase';
import firebase from 'firebase.js';
import { AddButton, Detail, List, Wrapper } from '../../Settings/common';
import { eventNames, logEvent } from '../../../analytics';
import { Contacts } from './Contacts';
import Accounts from './accountList';
import SelectedContacts from './selectedContacts';
import styled from 'styled-components';
import Papa from 'papaparse';
import {
  batchAddOrUpdateContacts,
  csvFieldNames,
  deduplicateContactValues,
  detectFileEncoding,
  filterContactValues,
  hasValidFields,
} from './importCsv';
import { ContactEditDrawerWithLogic } from './ContactEditDrawer/ContactEditDrawerWithLogic';

const { TabPane } = Tabs;
const CONTACT_TAB = 1;
const ACCOUNT_TAB = 2;

const MAX_ROW_IMPORT = 10000;

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: '',
      selectedContactIds: [],
      selectedAccountIds: [],
      showImport: false,
      duplicatedContacts: [],
      overwriteDuplicated: false,
      contactsToImport: [],
      importing: false,
      exporting: false,
      tab:
        window.location.href.indexOf('accounts') > 0
          ? ACCOUNT_TAB
          : CONTACT_TAB,
    };
  }

  onSearch = (keyword) => {
    this.setState({ keyword });
  };

  setSearchedContacts = (searchedContacts) => {
    this.setState({ searchedContacts });
  };

  setSelectedContactIds = (selectedContactIds) => {
    this.setState({ selectedContactIds });
  };

  setSelectedAccountIds = (selectedAccountIds) => {
    this.setState({ selectedAccountIds });
  };

  checkDuplicateAccountName = async (name) => {
    const { teamId } = this.props.match.params;
    const accounts = await this.props.store.getAccountsByName(teamId, name);
    return accounts?.length > 0;
  };

  checkDuplicateDomains = async (domains) => {
    const { teamId } = this.props.match.params;
    const accounts = await this.props.store.getAccountsByDomains(
      teamId,
      domains
    );
    return accounts?.length > 0;
  };

  getAllContacts = async () => {
    const { teamId } = this.props.match.params;
    return await this.props.store.getContactsByTeamId(teamId);
  };

  changeTab = (tab) => {
    const { teamId } = this.props.match.params;
    this.props.history.push(
      `/contacts/${teamId}/contacts/${
        Number(tab) === CONTACT_TAB ? 'contacts' : 'accounts'
      }`
    );
    this.setState({ tab, keyword: '' });
  };

  addAccount = async (account) => {
    const { teamId } = this.props.match.params;
    await db
      .collection(`companies/${this.props.store.signInCompany}/accounts`)
      .add({
        ...account,
        teamId,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });

    logEvent(eventNames.add_contact);
    message.success('取引先を作成しました');
    this.props.history.push('../accounts');
  };

  updateAccount = async (ref, account) => {
    const accountSnapshot = await ref.get();

    if (!accountSnapshot.exists) {
      console.error('Accounts.update: !accountSnapshot.exists:', ref);
    }
    await ref.update({
      ...account,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
    message.success('取引先を更新しました');
    this.props.history.push('../accounts');
  };

  deleteAccount = (account) => {
    Modal.confirm({
      title: `取引先 "${account.name}" を削除しますか？`,
      content: '一度削除すると元に戻せません',
      onOk: async () => {
        await account.ref.delete();
        message.success('取引先を削除しました');
        this.props.history.push('../accounts');
      },
      onCancel: () => {},
      okText: '削除',
      cancelText: 'キャンセル',
      okType: 'danger',
      maskClosable: true,
    });
  };

  clearSelectedContactIds = () => this.setState({ selectedContactIds: [] });

  validateCsvData = (result) => {
    const showErrorModal = (content) =>
      Modal.error({
        title: 'インポート作業に失敗しました',
        content: content,
        width: 600,
      });
    if (result.data.length > MAX_ROW_IMPORT) {
      showErrorModal(
        <p>一度にインポートできる最大件数(10,000件)を超えています。</p>
      );
      return false;
    }
    if (!hasValidFields(result.meta.fields)) {
      showErrorModal(
        <>
          <p>
            以下の原因が考えられるので、確認・修正のうえ再度お試しください。
          </p>
          <ul>
            <li>ヘッダ行（1行目の列名が書かれている行）がない</li>
            <li>
              必要な列が欠けている
              <ul>
                <li>例:「タグ」の列がない</li>
              </ul>
            </li>
            <li>
              不要な列がある
              <ul>
                <li>例:「ふりがな」の列がある</li>
              </ul>
            </li>
            <li>
              列の順番が異なる
              <ul>
                <li>
                  例:「名前」「メールアドレス」「電話番号」「タグ」...
                  の順になっている
                </li>
              </ul>
            </li>
          </ul>
        </>
      );
      return false;
    }
    return true;
  };

  validateContact = async (rows) => {
    rows = rows.filter((line) => line.email);

    const existing = await this.getAllContacts();
    const { add: contactsToAdd, update: contactsToUpdate } =
      filterContactValues(existing, rows);

    this.setState({
      contactsToImport: contactsToAdd,
      duplicatedContacts: deduplicateContactValues(contactsToUpdate),
    });
  };

  importContact = async () => {
    this.setState({ importing: true });
    try {
      const existing = await this.getAllContacts();
      const { teamId } = this.props.match.params;
      await batchAddOrUpdateContacts(
        this.props.store.signInCompany,
        teamId,
        this.props.store.getContactTags(teamId),
        existing,
        this.state.contactsToImport,
        this.state.overwriteDuplicated
      );

      message.success('コンタクトをインポートしました');
    } catch (e) {
      message.error('コンタクトのインポートに失敗しました');
      console.error(e);
    }

    this.setState({
      showImport: false,
      contactsToImport: [],
      duplicatedContacts: [],
      overwriteDuplicated: false,
      importing: false,
    });
  };

  exportContacts = async () => {
    const { store } = this.props;
    const { teamId } = this.props.match.params;
    this.setState({ exporting: true });
    const key = Date.now();
    try {
      message.loading({ content: 'コンタクトをエクスポート中です', key });
      await store.contactStore.exportContacts(teamId);
      message.success({ content: 'コンタクトをエクスポートしました', key });
    } catch (e) {
      message.error({ content: 'コンタクトのエクスポートに失敗しました', key });
      console.error(e);
    }
    this.setState({ exporting: false });
  };

  render() {
    const isReadOnly = this.props.store.me.isReadOnly;
    const { teamId } = this.props.match.params;
    const {
      keyword,
      selectedContactIds,
      selectedAccountIds,
      tab,
      showImport,
      duplicatedContacts,
      contactsToImport,
    } = this.state;
    const existingTags = this.props.store.getContactTags(teamId);
    const uploadProps = {
      accept: '.csv',
      customRequest: () => {},
      name: 'file',
      multiple: false,
      showUploadList: false,
      beforeUpload: async (file) => {
        const encoding = await detectFileEncoding(file);

        Papa.parse(file, {
          encoding,
          header: true,
          skipEmptyLines: 'greedy',
          transformHeader: (header) => {
            const fieldName = csvFieldNames.find((v) => v[0] === header);
            return fieldName ? fieldName[1] : header;
          },
          transform: (value, field) =>
            field === 'tags'
              ? value
                  .split(',')
                  .filter((v) => v)
                  .sort()
              : value,
          complete: (result) => {
            const valid = this.validateCsvData(result);
            if (!valid) return;
            this.validateContact(result.data);
          },
        });
      },
    };

    return (
      <Wrapper>
        <List>
          <H1>お客さま情報</H1>
          <ControlWrapper>
            <Input.Search
              value={keyword}
              style={{ width: 320 }}
              onSearch={this.onSearch}
              onChange={(e) => this.onSearch(e.target.value)}
              placeholder="キーワードで検索"
              allowClear
            />
            <ButtonWrapper>
              <AddButton
                style={{ marginBottom: 0, marginRight: 20 }}
                type="primary"
                disabled={isReadOnly}
                onClick={() => {
                  if (Number(tab) === CONTACT_TAB) {
                    this.props.history.push(
                      `/contacts/${teamId}/contacts/contacts/new`
                    );
                  }
                  if (Number(tab) === ACCOUNT_TAB) {
                    this.props.history.push(
                      `/contacts/${teamId}/contacts/accounts/new`
                    );
                  }
                }}
              >
                新規作成
              </AddButton>
              {Number(tab) === CONTACT_TAB && (
                <>
                  <AddButton
                    onClick={() => this.setState({ showImport: true })}
                    style={{ marginBottom: 0 }}
                    disabled={isReadOnly}
                  >
                    インポート
                  </AddButton>
                  <AddButton
                    onClick={this.exportContacts}
                    style={{ marginLeft: 20 }}
                    disabled={isReadOnly || this.state.exporting}
                  >
                    エクスポート
                  </AddButton>
                </>
              )}
            </ButtonWrapper>
          </ControlWrapper>
          <Tabs defaultActiveKey={`${tab}`} onChange={this.changeTab}>
            <TabPane tab="コンタクト" key={CONTACT_TAB}>
              {!isReadOnly && (
                <SelectedContacts
                  selectedContactIds={selectedContactIds}
                  clearSelectedContactIds={this.clearSelectedContactIds}
                />
              )}
              <Contacts
                keyword={keyword}
                selectedContactIds={selectedContactIds}
                setSelectedContactIds={this.setSelectedContactIds}
                existingTags={existingTags}
                key={teamId}
              />
            </TabPane>
            <TabPane tab="取引先" key={ACCOUNT_TAB}>
              <Accounts
                keyword={keyword}
                selectedAccountIds={selectedAccountIds}
                setSelectedAccountIds={this.setSelectedAccountIds}
                existingTags={existingTags}
                key={teamId}
              />
            </TabPane>
          </Tabs>
        </List>
        {!isReadOnly && (
          <Switch>
            <Route exact path="/contacts/:teamId/contacts/accounts/new">
              <Detail>
                <NewAccount
                  add={this.addAccount}
                  checkDuplicateName={this.checkDuplicateAccountName}
                  checkDuplicateDomains={this.checkDuplicateDomains}
                />
              </Detail>
            </Route>

            <Route exact path="/contacts/:teamId/contacts/accounts/:accountId">
              <Detail>
                <EditAccount
                  update={this.updateAccount}
                  delete={this.deleteAccount}
                  checkDuplicateName={this.checkDuplicateAccountName}
                  checkDuplicateDomains={this.checkDuplicateDomains}
                  getAllContacts={this.getAllContacts}
                  getAccount={this.props.store.getAccount}
                  getContactByAccountId={this.props.store.getContactByAccountId}
                />
              </Detail>
            </Route>

            {!isReadOnly && (
              <Route
                exact
                path="/contacts/:teamId/contacts/contacts/new"
                render={() => (
                  <ContactEditDrawerWithLogic
                    teamId={teamId}
                    contactId={undefined}
                  />
                )}
              />
            )}

            <Route
              exact
              path="/contacts/:teamId/contacts/contacts/:contactId"
              render={({ match }) => (
                <ContactEditDrawerWithLogic
                  teamId={teamId}
                  contactId={match.params.contactId}
                />
              )}
            />
          </Switch>
        )}
        <Modal
          visible={showImport}
          onCancel={() => this.setState({ showImport: false })}
          footer={null}
          width={800}
        >
          <ImportTitle>コンタクト情報をインポート</ImportTitle>
          <ul>
            <li>
              コンタクト情報
              (名前、メールアドレス、タグ、会社名、電話番号、メモ)をCSVファイルでインポートすることができます。
              <br />
              CSVテンプレートは
              <a href={`${process.env.PUBLIC_URL}/contactTemplate.csv`}>
                こちら
              </a>
              です
            </li>
            <li>メールアドレスの入力は必須かつ重複は不可です</li>
          </ul>
          <Upload.Dragger {...uploadProps}>
            <p>ここにCSVファイルをドロップ</p>
            または
            <ImportButtonWrap>
              <AddButton type="default">ファイルを選択</AddButton>
            </ImportButtonWrap>
            <p>一度に10,000件までインポート可能です</p>
          </Upload.Dragger>
        </Modal>
        <Modal
          width="100%"
          style={{ top: 0 }}
          visible={duplicatedContacts.length > 0}
          onCancel={() => {
            this.setState({
              contactsToImport: [],
              duplicatedContacts: [],
            });
          }}
          title="重複するコンタクトがあります"
          footer={[
            <Button
              key="ignore"
              onClick={() =>
                this.setState({
                  duplicatedContacts: [],
                })
              }
            >
              無視
            </Button>,
            <Button
              key="overwrite"
              onClick={() =>
                this.setState({
                  contactsToImport: deduplicateContactValues([
                    ...contactsToImport,
                    ...duplicatedContacts,
                  ]),
                  duplicatedContacts: [],
                  overwriteDuplicated: true,
                })
              }
              type="primary"
            >
              上書き
            </Button>,
          ]}
        >
          <Table
            size="small"
            columns={csvFieldNames.map((field) => ({
              title: field[0],
              dataIndex: field[1],
              render: (text, record, index) =>
                Array.isArray(text)
                  ? text.map((t) => <Tag key={t}>{t}</Tag>)
                  : text,
            }))}
            dataSource={duplicatedContacts.sort((a, b) =>
              a.email.localeCompare(b.email)
            )}
            rowKey={(_, index) => index.toString()}
          />
        </Modal>
        <Modal
          width="100%"
          style={{ top: 0 }}
          confirmLoading={this.state.importing}
          visible={
            duplicatedContacts.length === 0 && contactsToImport.length > 0
          }
          title={
            <>
              {contactsToImport.length}
              件のコンタクトをインポートします。よろしいですか？
            </>
          }
          onCancel={() => this.setState({ contactsToImport: [] })}
          onOk={() => this.importContact()}
          okText={'インポート'}
          cancelText={'キャンセル'}
        >
          <Table
            size="small"
            columns={csvFieldNames.map((field) => ({
              title: field[0],
              dataIndex: field[1],
              render: (text, record, index) =>
                Array.isArray(text)
                  ? text.map((t) => <Tag key={t}>{t}</Tag>)
                  : text,
            }))}
            dataSource={contactsToImport}
            rowKey={(_, index) => index.toString()}
          />
        </Modal>
      </Wrapper>
    );
  }
}

const ControlWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;
const ButtonWrapper = styled.div`
  display: flex;
  width: 50%;
  justify-content: flex-end;
`;
const ImportTitle = styled(H1)`
  text-align: center;
  margin-top: 30px;
  border-bottom: none;
`;
const ImportButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;
export default compose(withRouter, inject('store'), observer)(Index);
