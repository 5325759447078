import { getDoc } from 'firebase/firestore';
import { LRUCache } from 'lru-cache';
import { makeObservable, observable } from 'mobx';

import { AccountData } from 'lib';
import type { Store } from './index';
import { BaseStore } from './base';

export class AccountStore extends BaseStore<AccountData> {
  PATH = 'accounts';

  /** Used to trigger re-rendering. */
  timestamp = Date.now();

  constructor(rootStore: Store) {
    super(rootStore);

    makeObservable(this, {
      timestamp: observable,
    });
  }

  /**
   * Value has three cases:
   *   - undefined, means never queried firestore
   *   - '', means queried firestore, but not found or not returned
   *   - string, means found from firestore
   */
  private _accountNameCache: LRUCache<string, string> = new LRUCache({
    max: 256,
  });

  async getAccountNameById(accountId: string): Promise<string> {
    let name = this._accountNameCache.get(accountId);
    if (name !== undefined) {
      return name;
    }
    this._accountNameCache.set(accountId, '');
    const snap = await getDoc(this.doc(accountId));
    if (snap.exists()) {
      name = snap.get('name');
      if (name) {
        this._accountNameCache.set(accountId, name);
        this.timestamp = Date.now();
      }
    }
    return name || '';
  }
}
