import { SidebarBase } from '../../../components/sidebar/SidebarBase/SidebarBase';
import { SettingsSidebarSection } from './SettingsSidebarSection';
import { SidebarLinkButton } from '../../../components/sidebar/SidebarLinkButton/SidebarLinkButton';
import {
  ArrowForward,
  Conversation,
  Forum,
  Group,
  Groups,
  Lock,
  Logout,
  Mail,
  Payments,
  Person,
  Settings,
  Verified,
  YaritoriAi,
} from '../../../components/icons';
import { SidebarCommonButton } from '../../../components/sidebar/SidebarCommonButton/SidebarCommonButton';
import { useConfirmDialog } from '../../../hooks/confirmDialog';
import { Link } from 'react-router-dom';
import { Icon } from '../../../components/basics';

type Team = {
  id: string;
  name: string;
  showMemberMenu: boolean;
};

type Props = {
  isAdmin: boolean;
  isPrivateInboxSupported: boolean;
  isIntegrationsSupported: boolean;
  isIpBlockSupported: boolean;
  isYaritoriAiSupported: boolean;
  isLineSupported: boolean;
  teams: Team[];
  closedTeams: string[];
  onTeamOpenChange: (teamId: string, open: boolean) => void;
  onLogout: () => Promise<void>;
};

const NO_PERMISSION_TOOLTIP = '管理者のみの機能です';

export const SettingsSidebar = ({
  isAdmin,
  isPrivateInboxSupported,
  isIntegrationsSupported,
  isIpBlockSupported,
  isYaritoriAiSupported,
  isLineSupported,
  teams,
  closedTeams,
  onTeamOpenChange,
  onLogout,
}: Props) => {
  const openDialog = useConfirmDialog();
  const openLogoutDialog = () => {
    openDialog({
      title: 'ログアウトしますか？',
      okText: 'ログアウト',
      onOk: async () => await onLogout(),
    });
  };
  return (
    <SidebarBase className="gap-4 pb-4">
      <SettingsSidebarSection title="自分">
        <SidebarLinkButton
          icon={Person}
          label="プロフィール"
          to="/settings/me/profile"
        />
        <SidebarLinkButton
          icon={Settings}
          label="個人設定"
          to="/settings/me/general"
        />
        <SidebarLinkButton
          icon={Mail}
          label="個人メール設定"
          to="/settings/me/mail/inbox"
          disabled={!isPrivateInboxSupported}
          tooltip={
            !isPrivateInboxSupported ? '個人メールは利用できません' : undefined
          }
        />
      </SettingsSidebarSection>
      <SettingsSidebarSection title="会社">
        <SidebarLinkButton
          icon={Groups}
          label="メンバーとグループ"
          to="/settings/company/members"
        />
        <SidebarLinkButton
          icon={Group}
          label="チーム"
          to="/settings/company/teams"
        />
        <SidebarLinkButton
          icon={Payments}
          label="お支払い"
          to="/settings/company/payments"
          disabled={!isAdmin}
          tooltip={!isAdmin ? NO_PERMISSION_TOOLTIP : undefined}
        />
        <SidebarLinkButton
          icon={Conversation}
          label="外部サービス連携"
          to="/settings/integrations"
          disabled={!isIntegrationsSupported}
          tooltip={
            !isIntegrationsSupported
              ? '外部サービス連携は現在のプランではサポートされていません。'
              : undefined
          }
        />
        <SidebarLinkButton
          icon={Lock}
          label="IP制限"
          to="/settings/company/ip-limited"
          disabled={!isIpBlockSupported || !isAdmin}
          tooltip={
            !isIpBlockSupported
              ? 'IP制限はオプション機能です。'
              : !isAdmin
              ? NO_PERMISSION_TOOLTIP
              : undefined
          }
        />
        <SidebarLinkButton
          icon={YaritoriAi}
          label="yaritori AI"
          to="/settings/company/yaritori-ai"
          disabled={!isYaritoriAiSupported || !isAdmin}
          tooltip={
            !isYaritoriAiSupported
              ? 'yaritori AIは現在のプランではサポートされていません。'
              : !isAdmin
              ? NO_PERMISSION_TOOLTIP
              : undefined
          }
        />
        <SidebarLinkButton
          icon={Verified}
          label="ドメイン認証"
          to="/settings/company/domain-auth"
          disabled={!isAdmin}
          tooltip={!isAdmin ? NO_PERMISSION_TOOLTIP : undefined}
        />
      </SettingsSidebarSection>
      <div>
        <div className="select-none pb-1 text-xs text-sumi-500">チーム</div>
        <div className="h-[1px] w-full bg-sumi-200" />
      </div>
      {!teams.length && (
        <div className="flex flex-col items-center gap-2">
          <div className="text-sm">チームがありません</div>
          <Link
            to="/settings/company/teams"
            className="flex cursor-pointer items-center bg-transparent text-xs text-sea-500"
          >
            <span>チームを作成</span>
            <Icon icon={ArrowForward} size="1.1em" />
          </Link>
        </div>
      )}
      {teams.map((team) => {
        return (
          <SettingsSidebarSection
            key={team.id}
            title={team.name}
            open={!closedTeams.includes(team.id)}
            onOpenChange={(open) => onTeamOpenChange(team.id, open)}
          >
            <SidebarLinkButton
              icon={Groups}
              label="メンバー"
              to={`/settings/teams/${team.id}/members`}
            />
            {team.showMemberMenu && (
              <>
                <SidebarLinkButton
                  icon={Mail}
                  label="共有メール設定"
                  to={`/settings/teams/${team.id}/mail`}
                />
                <SidebarLinkButton
                  icon={Forum}
                  label="LINEアカウント設定"
                  to={`/settings/teams/${team.id}/line`}
                  disabled={!isLineSupported || !isAdmin}
                  tooltip={
                    !isLineSupported
                      ? 'LINEアカウント設定は現在のプランではサポートされていません。ご希望の方は右上のチャットからお問い合わせください。'
                      : !isAdmin
                      ? NO_PERMISSION_TOOLTIP
                      : undefined
                  }
                />
              </>
            )}
          </SettingsSidebarSection>
        );
      })}
      <div className="pt-4">
        <SidebarCommonButton
          icon={Logout}
          label="ログアウト"
          onClick={() => openLogoutDialog()}
        />
      </div>
    </SidebarBase>
  );
};
