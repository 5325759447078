import React, { useState } from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import {
  Alert,
  Button,
  Dropdown,
  Icon,
  Menu,
  message,
  Modal,
  Popover,
  Select,
  Spin,
} from 'antd';
import firebase, { db } from '../../../firebase';
import styled from 'styled-components';
import { maxRecipientsCount } from '../../../shared/constants';
import * as color from '../../../color';
import { Account, extractEmail } from 'lib';
import { eventNames, logEvent } from '../../../analytics';
import throttle from 'lodash.throttle';

const { Option } = Select;

/**
 * @typedef Props
 * @property {number[]} selectedContactIds
 * @property {()=>void} clearSelectedContactIds
 */

/**
 * @typedef State
 * @property {boolean} creatingDraft
 * @property {object[]} accounts
 * @property {boolean} addingToAccount
 */

/**
 * @extends {React.Component<Props, State>}
 */
class Index extends React.Component {
  /**
   * @type {State}
   */
  state = {
    creatingDraft: false,
    accounts: [],
    addingToAccount: false,
  };

  componentDidMount() {
    this.getAccounts();
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  unsubscribe = () => {
    if (this._unsubscribe) this._unsubscribe();
  };

  createDraft = ({ to, cc, bcc }) =>
    throttle(
      async () => {
        const { teamId } = this.props.match.params;
        const teamFirstInbox = this.props.store.getTeamFirstInbox(teamId);
        const defaultInbox = this.props.store.defaultInbox;
        const inbox = defaultInbox || teamFirstInbox;
        if (!inbox) {
          message.error(
            'チームにメールアドレスが登録されていないため、メールを作成することができません'
          );
          return;
        }

        this.setState({ creatingDraft: true });

        const removeAutoRecipients = (values) =>
          values.filter(
            (v) =>
              !inbox.autoRecipients.some(
                (r) => extractEmail(r) === extractEmail(v)
              )
          );

        // 自動Cc/Bccの重複を防ぐ
        to = removeAutoRecipients(to || []);
        cc = removeAutoRecipients(cc || []);
        bcc = removeAutoRecipients(bcc || []);

        const draft = await db
          .collection(`companies/${this.props.store.signInCompany}/drafts`)
          .add({
            inboxId: inbox.id,
            teamId: inbox.teamId,
            to: to || [],
            cc: cc ? [...cc, ...inbox.autoCcs] : inbox.autoCcs,
            bcc: bcc ? [...bcc, ...inbox.autoBccs] : inbox.autoBccs,
            subject: '',
            body: '',
            signature:
              this.props.store.getSignature(inbox.defaultSignatureId)
                ?.signature || null,
            attachments: [],
            plainTextMode: this.props.store.me.plainTextMode,
            drafter: this.props.store.me.id,
            isReply: false,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          });
        logEvent(eventNames.add_draft_from_contacts);
        const draftSnapshot = await draft.get();
        if (!draftSnapshot.exists) {
          console.error(
            'SelectedContacts.createDraft: !draftSnapshot.exists:',
            { draftId: draft.id }
          );
        }
        this.setState({ creatingDraft: false });
        this.props.history.push(`/me/drafts/${draftSnapshot.id}`);
      },
      2000,
      { trailing: false }
    )();

  /**
   * @returns {ref: any}
   */
  getSelectedContacts = async () => {
    const contacts = await Promise.all(
      this.props.selectedContactIds.map((contactId) =>
        this.props.store.getContact(contactId)
      )
    );

    return contacts.filter(Boolean);
  };

  getSelectedContactRefs = () => {
    return this.props.selectedContactIds.map((contactId) =>
      this.props.store.getContactRef(contactId)
    );
  };

  getSelectedContactEmailTexts = async () => {
    const contactsMap = new Map();
    const selectedContacts = await this.getSelectedContacts();
    selectedContacts.forEach((c) => {
      // emailの重複を防いでいる
      if (!contactsMap.has(c.email)) contactsMap.set(c.email, c);
    });
    return Array.from(contactsMap.values()).map((c) => c.emailText);
  };

  createDraftWithToFromSelectedContactEmailTexts = async () =>
    this.createDraft({
      to: await this.getSelectedContactEmailTexts(),
    });

  createDraftWithCcFromSelectedContactEmailTexts = async () =>
    this.createDraft({
      cc: await this.getSelectedContactEmailTexts(),
    });

  createDraftWithBccFromSelectedContactEmailTexts = async () =>
    this.createDraft({
      bcc: await this.getSelectedContactEmailTexts(),
    });

  /**
   * @returns {Promise<any>}
   */
  async deleteContacts() {
    const { store, selectedContactIds } = this.props;
    const { teamId } = this.props.match.params;
    try {
      const contacts = await this.getSelectedContacts();
      const batch = db.batch();
      contacts.forEach((c) => {
        batch.delete(c.ref);
      });
      await Promise.all([
        batch.commit(),
        store.contactStore.unindexContacts(teamId, selectedContactIds),
        ...contacts.map((c) =>
          store.removeContactTagsIfNotAttached(c.tags, c.teamId)
        ),
      ]);

      this.props.clearSelectedContactIds();
      store.contactStore.reload();
    } catch (e) {
      throw e;
    }
  }

  handleClickDeleteContactsButton = async () => {
    Modal.confirm({
      title: `${this.props.selectedContactIds.length}件のコンタクトを削除しますか？`,
      content: '一度削除すると元に戻せません',
      onOk: async () => {
        try {
          await this.deleteContacts();
          message.success('コンタクトを削除しました');
        } catch (e) {
          message.error('コンタクトの削除中にエラーが発生しました');
          throw e;
        }
      },
      onCancel: () => {},
      okText: '削除',
      cancelText: 'キャンセル',
      okType: 'danger',
      maskClosable: true,
    });
  };

  handleClickAddToAccount = () => {
    this.setState({
      addingToAccount: true,
    });
  };

  handleClickRemoveFromAccount = () => {
    Modal.confirm({
      title: `${this.props.selectedContactIds.length}件のコンタクトを取引先から削除しますか？`,
      content: '',
      onOk: async () => {
        try {
          await this.updateAccountId(null);
          message.success('コンタクトを取引先から削除しました');
        } catch (e) {
          message.error('取引先からコンタクトの削除中にエラーが発生しました');
          throw e;
        }
      },
      onCancel: () => {},
      okText: '削除',
      cancelText: 'キャンセル',
      okType: 'danger',
      maskClosable: true,
    });
  };

  /**
   *
   * @param {string} accountId
   */
  handleAddToAccount = async (accountId) => {
    try {
      await this.updateAccountId(accountId);
      message.success('取引先に追加しました');
    } finally {
      this.setState({
        addingToAccount: false,
      });
    }
  };

  handleCancelAddToAccount = () => {
    this.setState({
      addingToAccount: false,
    });
  };

  hasSelected = () => this.props.selectedContactIds.length > 0;

  selectLimitExceeded = () =>
    this.props.selectedContactIds.length > maxRecipientsCount;

  getAccounts = ({ startAfter, endBefore } = {}) => {
    this.unsubscribe();
    let query = this.getQuery();
    this._unsubscribe = query.onSnapshot(
      (snapshot) => {
        let accounts = [];
        snapshot.forEach((doc) => {
          accounts.push(new Account(doc));
        });
        this.setState({
          accounts,
        });
      },
      (err) => {
        console.log(err);
      }
    );
  };

  getQuery = () => {
    let query = db
      .collection(`companies/${this.props.store.signInCompany}/accounts`)
      .where('teamId', '==', this.props.match.params.teamId);
    return query;
  };

  update = async (accountId, contactId) => {};

  /**
   *
   * @param {string | null} accountId
   */
  updateAccountId = async (accountId) => {
    const refs = this.getSelectedContactRefs();
    console.log(refs);
    const batch = db.batch();
    refs.forEach((ref) =>
      batch.update(ref, {
        accountId,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      })
    );
    await batch.commit();

    this.props.clearSelectedContactIds();
  };

  render() {
    const { selectedContactIds } = this.props;
    const selectLimitExceeded = this.selectLimitExceeded();

    return (
      <div>
        {selectLimitExceeded && (
          <Alert
            message={`一度に送信できるのは最大${maxRecipientsCount}件までです。`}
            type="error"
            showIcon
            style={{ marginBottom: 12 }}
          />
        )}

        {this.renderActions(selectedContactIds)}
      </div>
    );
  }

  renderActions() {
    const hasSelected = this.hasSelected();
    if (!hasSelected) {
      return null;
    }

    /**
     * @type {{selectedContactIds: string[]}}
     */
    const { selectedContactIds } = this.props;
    const { creatingDraft, accounts, addingToAccount } = this.state;

    const selectLimitExceeded = this.selectLimitExceeded();

    const sendMenu = (
      <Menu>
        <Menu.Item
          onClick={this.createDraftWithToFromSelectedContactEmailTexts}
          key="1"
        >
          宛先に設定
        </Menu.Item>
        <Menu.Item
          onClick={this.createDraftWithCcFromSelectedContactEmailTexts}
          key="2"
        >
          Ccに設定
        </Menu.Item>
        <Menu.Item
          onClick={this.createDraftWithBccFromSelectedContactEmailTexts}
          key="3"
        >
          Bccに設定
        </Menu.Item>
      </Menu>
    );

    const accountMenu = (
      <Menu>
        <Menu.Item onClick={this.handleClickAddToAccount}>
          取引先に追加
        </Menu.Item>
        <Menu.Item onClick={this.handleClickRemoveFromAccount}>
          <span style={{ color: color.common.danger }}>取引先から削除</span>
        </Menu.Item>
      </Menu>
    );

    return (
      <ActionsWrapper>
        <div className="object">
          選択中の{selectedContactIds.length}件のコンタクトに対して
        </div>
        <div className="actions">
          <div className="action">
            <Dropdown overlay={sendMenu} disabled={selectLimitExceeded}>
              <Button type="primary" loading={creatingDraft}>
                <Icon type="mail"></Icon>
                メールを作成する {!creatingDraft && <Icon type="down" />}
              </Button>
            </Dropdown>
          </div>
          <div className="action account">
            <Dropdown overlay={accountMenu}>
              <Button>
                取引先の編集 <Icon type="down" />
              </Button>
            </Dropdown>
            <div className="popover">
              <AddToAccountPopover
                visible={addingToAccount}
                accounts={accounts}
                onSave={this.handleAddToAccount}
                onCancel={this.handleCancelAddToAccount}
              ></AddToAccountPopover>
            </div>
          </div>
          <div className="action">
            <Button
              type="danger"
              onClick={this.handleClickDeleteContactsButton}
            >
              <Icon type="delete"></Icon>
              削除
            </Button>
          </div>
        </div>
      </ActionsWrapper>
    );
  }
}

const ActionsWrapper = styled.div`
  margin-top: 8px;
  margin-bottom: 20px;

  & > .object {
  }

  & > .actions {
    margin-top: 8px;
    display: flex;
    & > .action {
      margin-right: 8px;

      &.account {
        & > .popover {
          margin-left: 60px;
        }
      }
    }
  }
`;

export default compose(withRouter, inject('store'), observer)(Index);

/**
 * @typedef AddToAccountPopoverProps
 * @property {boolean} visible
 * @property {any[]} accounts
 * @property {undefined | ((accountId: string)=>Promise<any>)} onSave
 * @property {undefined | (()=>void)} onCancel
 */

const AddToAccountPopoverContentWrapper = styled.div`
  & > .actions {
    margin-top: 16px;
    text-align: right;
    & > * {
      margin-left: 8px;
    }
  }
`;

/**
 *
 * @param {AddToAccountPopoverProps} props
 */
const AddToAccountPopover = (props) => {
  /**
   * @type {[string, (string)=>void]}
   */
  const [accountId, setAccountId] = useState();

  /**
   * @type {[boolean, (boolean)=>void]}
   */
  const [processing, setProcessing] = useState();

  const isSaveButtonDisalbed = processing || !accountId;

  const handleClickSaveButton = async () => {
    if (!accountId) {
      return;
    }

    setProcessing(true);
    await props.onSave(accountId);
    setProcessing(false);
  };

  const content = (
    <AddToAccountPopoverContentWrapper>
      <Select
        value={accountId}
        onSelect={(value) => setAccountId(value)}
        showSearch
        style={{ width: 200 }}
        placeholder="取引先を選択"
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {props.accounts.map((item) => (
          <Option key={item.id} value={item.id}>
            {item.name}
          </Option>
        ))}
      </Select>
      <div className="actions">
        <Button onClick={props.onCancel}>キャンセル</Button>
        <Button
          type="primary"
          onClick={handleClickSaveButton}
          disabled={isSaveButtonDisalbed}
        >
          {processing ? (
            <Spin indicator={<Icon type="loading" spin />}></Spin>
          ) : (
            '追加'
          )}
        </Button>
      </div>
    </AddToAccountPopoverContentWrapper>
  );

  return (
    <Popover
      title="取引先に追加"
      visible={props.visible}
      content={content}
      placement="bottom"
    ></Popover>
  );
};
