import { atomFamily, atomWithRefresh, unwrap } from 'jotai/utils';
import { isEqual } from 'lodash';
import { getCountFromServer, query, where } from 'firebase/firestore';
import { companyCollection } from '../../../firestore';
import { messagesPathAtom } from '../message';
import { atom, WritableAtom } from 'jotai';
import { store } from '../../../providers/StoreProvider';
import { MessageStatus } from 'lib';

type TagCountParams = {
  teamId: string;
  tagId: string;
  inboxId?: string;
};

const tagCountAtomsAtom = atom<
  Record<string, WritableAtom<number, [], void>[]>
>({});

export const tagCountAtomFamily = atomFamily(
  ({ teamId, tagId, inboxId }: TagCountParams) => {
    const countAtom = unwrap(
      atomWithRefresh(async (get) => {
        let q = query(
          companyCollection(get(messagesPathAtom)),
          where('teamId', '==', teamId),
          where('tags', 'array-contains', tagId),
          where('status', '==', MessageStatus.Unprocessed),
          where('deleted', '==', false)
        );
        if (inboxId) {
          q = query(q, where('inboxId', '==', inboxId));
        }
        const snap = await getCountFromServer(q);
        return snap.data().count;
      }),
      (prev) => prev ?? 0
    );
    store.set(tagCountAtomsAtom, (prev) => ({
      ...prev,
      [teamId]: [...(prev[teamId] ?? []), countAtom],
    }));
    return countAtom;
  },
  isEqual
);

export const refreshTagCountAtom = atom<null, [string], void>(
  null,
  async (get, set, teamId) => {
    const atoms = get(tagCountAtomsAtom)[teamId];
    if (!atoms) {
      return;
    }
    for (const countAtom of atoms) {
      set(countAtom);
    }
  }
);
