import { CustomStatusesData } from 'atoms/firestore/customStatuses';
import { CustomStatus, MessageStatus } from 'lib';
import { chain } from 'lodash';

export const flatStatusesDataAtom = (
  data: CustomStatusesData
): CustomStatus[] => {
  return chain(data).values().flatten().value();
};

/**
 * メールリストでのタブ表示するステータスを返す。こちらのメソッドは "未対応"、"対応済み" が含まれていない。
 * "未対応"、"対応済み" を含めたい場合は getStatusesForFrontIncludeSystemStatus メソッドを使用すること
 *
 * @param statuses atomからのデータ
 * @returns
 */
export const getStatusesForFront = (statuses: CustomStatus[]): string[] => {
  return chain(statuses)
    .filter((status) => status.name !== MessageStatus.Unprocessed)
    .orderBy('order', 'asc')
    .map('name')
    .value();
};

/**
 * getStatusesForFront メソッドにシステム用の "未対応"、"対応済み" を含めて返す。
 * "未対応"、"対応済み" を含まない場合は getStatusesForFront メソッドを使用すること。
 *
 * @param statuses atomからのデータ
 */
export const getStatusesForFrontIncludeSystemStatus = (
  statuses: CustomStatus[]
): string[] => {
  return [
    MessageStatus.Unprocessed,
    ...getStatusesForFront(statuses),
    MessageStatus.Processed,
  ];
};

/** カスタムステータスが登録されている場合は TRUE、登録がない場合は FALSE */
export const hasCustomStatus = (statuses: CustomStatus[]): boolean => {
  return getStatusesForFront(statuses).length > 0;
};

/**
 * メール返信する際に次に変更すべきステータスを返す
 *
 * @param currentStatus 返信したいスレッドの現在ステータス
 * @param statuses atomからのデータ
 * @returns
 */
export const getNextStatus = (
  currentStatus: string | undefined,
  statuses: CustomStatus[]
): string => {
  if (statuses.length === 0) {
    // カスタムステータスがない場合
    return currentStatus === MessageStatus.Processed
      ? MessageStatus.Unprocessed
      : MessageStatus.Processed;
  }

  if (!currentStatus) {
    return MessageStatus.Unprocessed;
  }

  const customStatusIndex = statuses.findIndex(
    (status) => status.name === currentStatus
  );
  if (customStatusIndex >= 0) {
    const nextStatus = statuses[customStatusIndex]?.nextStatus;
    if (nextStatus) {
      // 次のステータス（nextStatus）が登録されている場合は、優先的に次のステータスを返す
      return nextStatus;
    }
  }

  // 次のステータス（nextStatus）が登録されていない場合は、index順で次のステータスを返す
  if (customStatusIndex + 1 < statuses.length) {
    return statuses[customStatusIndex + 1].name;
  }

  return MessageStatus.Processed;
};
