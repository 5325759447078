import { companyCollection } from '../../../firestore';
import { messagesPathAtom } from '../message';
import { getCountFromServer, query, where } from 'firebase/firestore';
import { MessageStatus } from 'lib';
import { meAtom } from '../../auth';
import { joinedTeamIdsAtom } from '../team';
import { atomWithRefresh, unwrap } from 'jotai/utils';

export const assignedCountAtom = unwrap(
  atomWithRefresh(async (get) => {
    const snap = await getCountFromServer(
      query(
        companyCollection(get(messagesPathAtom)),
        where('teamId', 'in', get(joinedTeamIdsAtom)),
        where('status', '==', MessageStatus.Unprocessed),
        where('assignee', '==', get(meAtom).id)
      )
    );
    return snap.data().count;
  }),
  (prev) => prev ?? 0
);
