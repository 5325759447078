import { SettingPageDrawer } from '../../../common/SettingPageDrawer/SettingPageDrawer';
import { InputGroup } from '../../../../../components/forms/InputGroup/InputGroup';
import { Controller, useForm } from 'react-hook-form';
import { Input, Select } from '../../../../../components/forms';
import { useEffect, useState } from 'react';
import { Button } from '../../../../../components/basics';
import { useConfirmDialog } from '../../../../../hooks/confirmDialog';
import { CustomStatus, MessageStatus } from 'lib';

type FormData = Omit<CustomStatus, 'id'>;

type OptionItem = {
  value: string | null;
  label: string;
};

type Props = {
  statuses: CustomStatus[];
  status: CustomStatus | null;

  /** システムステータスは特例処理を行う */
  isSystemStatus: boolean;

  onUpdate: (id: CustomStatus['id'], update: FormData) => Promise<void>;
  onDelete: (id: CustomStatus['id']) => Promise<void>;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  readonly: boolean;
  container?: HTMLElement;
};

export const StatusEditDrawer = ({
  statuses,
  status,
  isSystemStatus,
  onUpdate,
  onDelete,
  open,
  onOpenChange,
  readonly,
  container,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState(
    status?.nextStatus || null
  );
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [options, setOptions] = useState<OptionItem[]>([]);
  const { register, control, watch, handleSubmit, reset } = useForm<FormData>();

  const inputValue = watch('name');

  useEffect(() => {
    if (inputValue !== '') {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [inputValue]);

  useEffect(() => {
    const filterOptions = statuses
      // nextStatusの選択肢に "未対応" は含めない
      .filter((status) => status.name !== MessageStatus.Unprocessed)
      .map((status) => {
        return {
          value: status.name,
          label: status.name,
        };
      });

    setOptions([
      {
        value: null,
        label: '表示順で次にあるステータス',
      },
      ...filterOptions,
      { value: MessageStatus.Processed, label: MessageStatus.Processed },
    ]);
  }, [statuses]);

  const handleSelectChange = (value: any) => {
    setSelectedValue(value);
  };

  const openDialog = useConfirmDialog();

  useEffect(() => {
    if (!status) {
      return;
    }
    setSelectedValue(status.nextStatus || null);

    reset({
      name: status.name,
      nextStatus: status.nextStatus || null,
      order: status.order,
    });
  }, [status]);

  const onSubmit = async (update: FormData) => {
    if (!status) {
      return;
    }

    const updateObj = {
      name: inputValue,
      nextStatus: selectedValue,
      order: status.order,
    };
    try {
      await onUpdate(status.id, updateObj);
      reset(update);
      onOpenChange(false);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <SettingPageDrawer
      title="ステータスを編集"
      open={open}
      onOpenChange={onOpenChange}
      container={container}
    >
      <form
        action=""
        className="flex flex-col gap-4"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          name="name"
          control={control}
          render={({ fieldState }) => (
            <InputGroup
              label="タイトル"
              errorMessage={fieldState.error?.message}
            >
              <Input
                {...register('name', {
                  setValueAs: (v) => (typeof v === 'string' ? v.trim() : v),
                })}
                disabled={isSystemStatus}
              />
            </InputGroup>
          )}
        />

        <InputGroup label="次のステータス">
          <Select
            value={selectedValue}
            onChange={handleSelectChange}
            options={options}
            className="w-full"
          />
        </InputGroup>

        <div className="flex gap-4">
          <>
            <Button
              color="danger"
              onClick={() =>
                openDialog({
                  title: 'ステータスを削除しますか？',
                  description: '一度削除すると元に戻せません',
                  okType: 'danger',
                  okText: '削除',
                  onOk: async () => status && (await onDelete(status.id)),
                })
              }
              disabled={isSystemStatus}
            >
              削除
            </Button>
            <Button
              type="submit"
              disabled={readonly || loading || isButtonDisabled}
              loading={loading}
            >
              更新
            </Button>
          </>
        </div>
      </form>
    </SettingPageDrawer>
  );
};
