import { atomWithRefresh, unwrap } from 'jotai/utils';
import { atom } from 'jotai';
import { allTeamStatusesInGroupAtom } from '../customStatuses';
import { getCountFromServer, query, where } from 'firebase/firestore';
import { companyCollection } from '../../../firestore';
import { store } from '../../../providers/StoreProvider';
import { messagesPathAtom } from '../message';
import { MessageStatus } from 'lib';
import { fromPairs } from 'lodash';
import { tagCountAtomFamily } from './tag';

type TabCountsFilter = {
  teamId: string;
  tagId: string;
  inboxId?: string;
};

export const tabCountsFilterAtom = atom<TabCountsFilter | undefined>(undefined);

export const tabCountsAtom = unwrap(
  atomWithRefresh<Promise<Record<string, number>>>(async (get) => {
    const filter = get(tabCountsFilterAtom);
    if (!filter) {
      return {};
    }
    const messagesPath = store.get(messagesPathAtom);
    const [, customStatusesData] = get(allTeamStatusesInGroupAtom);
    const { teamId } = filter;
    const customStatuses =
      teamId in customStatusesData ? customStatusesData[teamId] : [];
    if (!customStatuses.length) {
      return {};
    }
    const customStatusCounts: [string, number][] = await Promise.all(
      customStatuses.map(async (status) => [
        status.name,
        await getTabCount(messagesPath, filter, status.name),
      ])
    );
    return fromPairs([
      [MessageStatus.Unprocessed, get(tagCountAtomFamily(filter))],
      ...customStatusCounts,
    ]);
  }),
  (prev) => prev ?? {}
);

const getTabCount = async (
  messagesPath: string,
  { teamId, tagId, inboxId }: TabCountsFilter,
  status: string
) => {
  let q = query(
    companyCollection(messagesPath),
    where('teamId', '==', teamId),
    where('tags', 'array-contains', tagId),
    where('status', '==', status),
    where('deleted', '==', false)
  );
  if (inboxId) {
    q = query(q, where('inboxId', '==', inboxId));
  }
  const snap = await getCountFromServer(q);
  return snap.data().count;
};
