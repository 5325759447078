import { forwardRef } from 'react';

type Props = JSX.IntrinsicElements['input'] & {
  label?: string;
};

export const Checkbox = forwardRef<HTMLInputElement, Props>(
  (props: Props, ref): JSX.Element => {
    const { label, ...checkboxProps } = props;
    return label ? (
      <label className="text-sm">
        <input
          type="checkbox"
          {...checkboxProps}
          ref={ref}
          className="mr-1.5"
        />
        {label}
      </label>
    ) : (
      <input type="checkbox" {...checkboxProps} ref={ref} />
    );
  }
);
