import { forwardRef } from 'react';
import styled from 'styled-components';
import Avatar from '../../../../Common/Avatar';
import Tooltip from '../../../../Common/Tooltip';
import Tags from './tags';
import * as color from '../../../../../color';
import media from 'styled-media-query';
import { Button, Select } from 'antd';
import { CmpOptGroup, CmpOption, CmpSelect, CmpTooltip } from 'utils/antdComp';
import { observer } from 'mobx-react';
import { SPBackButton } from '../SPBackButton';
import { useStore } from 'hooks/useStore';
import { isSP } from 'shared/util';
import { SPConversationHeader } from './SPConversationHeader';
import { getAssigneeOptions } from './util';
import { ConversationHeaderProps } from './type';
import { statusesAtomFamily } from 'atoms/firestore/customStatuses';
import { useAtomValue } from 'jotai';
import {
  getStatusesForFrontIncludeSystemStatus,
  hasCustomStatus,
} from 'utils/customStatuses';

export const ConversationHeader = observer(
  forwardRef<HTMLDivElement, ConversationHeaderProps>((props, ref) => {
    const {
      message,
      messages,
      locked,
      updateStatus,
      updateAssignee,
      users,
      teamMembers,
      restoreMessage,
      deleteMessage,
      backLink,
    } = props;
    const store = useStore();
    const [, statuses] = useAtomValue(statusesAtomFamily(message.teamId));

    const isReadOnly = store.me.isReadOnly;
    let statusSelect = null;

    if (hasCustomStatus(statuses)) {
      statusSelect = (
        <Select
          value={message.status}
          onChange={(status) => updateStatus(message, status)}
          disabled={isReadOnly}
        >
          {getStatusesForFrontIncludeSystemStatus(statuses).map((status) => (
            <Select.Option value={status} key={status}>
              {status}
            </Select.Option>
          ))}
        </Select>
      );
    }

    const assigneeOptions = getAssigneeOptions(users, teamMembers);

    if (isSP()) {
      return <SPConversationHeader {...props} />;
    }

    return (
      <Header ref={ref} data-testid="conversationHeader">
        <SPBackButton backLink={backLink} />
        <div className="subject">
          <CmpTooltip title={message.subject}>{message.subject}</CmpTooltip>
        </div>
        <Lower>
          <TagsWrapper>
            <Tags
              message={message}
              messages={messages || [message]}
              onRemoveTag={props.onRemoveTag}
            />
          </TagsWrapper>

          <Right>
            <CmpSelect
              value={message.assignee ?? (null as any)}
              style={{ minWidth: 160 }}
              onChange={(value) =>
                updateAssignee(message, (value ?? null) as string | null)
              }
              disabled={isReadOnly}
            >
              {/* TODO: FIX Warning: Each child in a list should have a unique "key" prop */}
              <CmpOptGroup label="担当者">
                {assigneeOptions.map((o) =>
                  o.user ? (
                    <CmpOption
                      value={o.user.id as any}
                      key={o.user.id}
                      disabled={!o.isTeamMember}
                    >
                      <Avatar
                        user={o.user}
                        size="small"
                        style={{ marginTop: -3 }}
                      />
                      <Name>
                        {o.isTeamMember ? null : '(チーム外)'}
                        {o.user.name}
                      </Name>
                    </CmpOption>
                  ) : (
                    <CmpOption key={'none'} value={null as any}>
                      担当者未設定
                    </CmpOption>
                  )
                )}
              </CmpOptGroup>
            </CmpSelect>
            <div className="status flex gap-2">
              {message.deleted ? (
                <>
                  <Button
                    icon="delete"
                    onClick={() => deleteMessage(message)}
                    className="border-none bg-[#7a7a7a] text-white disabled:opacity-50"
                    disabled={isReadOnly}
                  >
                    完全に削除
                  </Button>
                  <Tooltip title="元に戻す" visible={!isReadOnly}>
                    <Button
                      icon="rollback"
                      onClick={() => restoreMessage(message)}
                      disabled={isReadOnly}
                      aria-label="元に戻す"
                    />
                  </Tooltip>
                </>
              ) : (
                <Tooltip
                  title="返信中はステータス変更ができません"
                  visible={!isReadOnly && locked}
                >
                  {statusSelect || (
                    <Button
                      icon="check"
                      type={message.status === '未対応' ? 'dashed' : 'default'}
                      onClick={() =>
                        updateStatus(
                          message,
                          message.status === '未対応' ? '対応済み' : '未対応'
                        )
                      }
                      disabled={isReadOnly || locked}
                      style={{
                        ...(message.status === '対応済み' && !locked
                          ? {
                              background: '#87d068',
                              borderColor: '#87d068',
                              color: 'white',
                              borderRadius: '8px',
                            }
                          : {
                              borderRadius: '8px',
                            }),
                      }}
                    >
                      {message.status === '未対応'
                        ? '対応済みにする'
                        : message.status}
                    </Button>
                  )}
                </Tooltip>
              )}
            </div>
          </Right>
        </Lower>
      </Header>
    );
  })
);

const Header = styled.div`
  position: sticky;
  top: 0;
  padding: 10px 12px;
  width: 100%;
  background-color: ${color.sidebar.background.normal};
  z-index: 2;

  & > .subject {
    margin-top: 8px;
    font-weight: 600;
    font-size: 16px;
    line-height: 1;
    padding: 6px 8px 6px 1px;
    color: rgba(0, 0, 0, 0.8);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const Lower = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: space-between;

  ${media.lessThan('medium')`
    flex-direction: column;
    gap: 10px;
  `}
`;

const TagsWrapper = styled.div`
  padding-right: 16px;
  display: flex;
  align-items: center;

  ${media.lessThan('medium')`
    order: 2;
  `}
`;

const Right = styled.div`
  display: flex;
  align-items: center;

  & > .status {
    margin-left: 8px;
  }

  ${media.lessThan('medium')`
    order: 1;
  `}
`;

const Name = styled.span`
  margin-left: 4px;
`;
