import { ComponentPropsWithoutRef, useEffect, useState } from 'react';
import {
  EditorHandle,
  WysiwygEditor,
} from '../../Common/Editor/WysiwygEditor/WysiwygEditor';
import { debounce } from 'lodash';
import { Transaction } from '@tiptap/pm/state';
import { Node } from '@tiptap/pm/model';

type Props = ComponentPropsWithoutRef<typeof WysiwygEditor> & {
  onChange: (plainText: string, html: string) => void;
  plainTextMode: boolean;
  onSubmit?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onEnter?: () => void;
  onInsertImage?: (contentId: string) => void;
  onDeleteImage?: (contentId: string) => void;
};

export const CreateMessageWysiwygEditor = ({
  onChange,
  plainTextMode,
  onSubmit,
  onFocus,
  onBlur,
  onEnter,
  onInsertImage,
  onDeleteImage,
  initEditorHandle,
  ...props
}: Props) => {
  const [handle, setHandle] = useState<EditorHandle | undefined>();
  useEffect(() => {
    const editor = handle?.editor;
    if (!editor) {
      return;
    }
    const notifyChanges = () => {
      onChange(handle.getText(), handle.getHtml());
    };
    const onUpdate = debounce(notifyChanges, 500, {
      maxWait: 2000,
    });
    editor.on('blur', notifyChanges);
    editor.on('update', onUpdate);
    return () => {
      editor.off('blur', notifyChanges);
      editor.off('update', onUpdate);
    };
  }, [handle?.editor]);
  useEffect(() => {
    const editor = handle?.editor;
    if (!editor) {
      return;
    }
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key !== 'Enter') {
        return;
      }

      if (editor.isFocused && e.ctrlKey) {
        onSubmit?.();
        e.preventDefault();
        e.stopPropagation();
      } else {
        onEnter?.();
      }
    };
    window.addEventListener('keydown', onKeyDown, true);
    return () => {
      window.removeEventListener('keydown', onKeyDown, true);
    };
  }, [onSubmit, onEnter, handle?.editor]);
  useEffect(() => {
    const editor = handle?.editor;
    if (!editor) {
      return;
    }
    if (onFocus) {
      editor.on('focus', onFocus);
    }
    if (onBlur) {
      editor.on('blur', onBlur);
    }
    return () => {
      if (onFocus) {
        editor.off('focus', onFocus);
      }
      if (onBlur) {
        editor.off('blur', onBlur);
      }
    };
  }, [onFocus, onBlur, handle?.editor]);
  useEffect(() => {
    const editor = handle?.editor;
    if (!editor) {
      return;
    }

    const onUpdate = ({ transaction }: { transaction: Transaction }) => {
      const beforeNodes = new Set<Node>();
      const afterNodes = new Set<Node>();
      transaction.before.descendants((node) => {
        if (node.type.name === 'image' && node.attrs['data-content_id']) {
          beforeNodes.add(node);
        }
      });
      transaction.doc.descendants((node) => {
        if (node.type.name === 'image' && node.attrs['data-content_id']) {
          afterNodes.add(node);
        }
      });

      for (const afterNode of afterNodes) {
        if (
          ![...beforeNodes].some(
            (beforeNode) =>
              beforeNode.attrs['data-content_id'] ===
              afterNode.attrs['data-content_id']
          )
        ) {
          onInsertImage?.(afterNode.attrs['data-content_id']);
        }
      }

      for (const beforeNode of beforeNodes) {
        if (
          ![...afterNodes].some(
            (afterNode) =>
              afterNode.attrs['data-content_id'] ===
              beforeNode.attrs['data-content_id']
          )
        ) {
          onDeleteImage?.(beforeNode.attrs['data-content_id']);
        }
      }
    };

    editor.on('update', onUpdate);
    return () => {
      editor.off('update', onUpdate);
    };
  }, [handle?.editor, onInsertImage, onDeleteImage]);
  return (
    <WysiwygEditor
      initEditorHandle={(handle) => {
        setHandle(handle);
        initEditorHandle?.(handle);
      }}
      getBubbleMenuContainer={() => document.getElementById('editorScroll')!}
      {...props}
    />
  );
};
