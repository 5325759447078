import React, { Component } from 'react';
import styled from 'styled-components';
import { Route, Switch } from 'react-router-dom';
import Contacts from 'App/Contacts/Team';
import Sidebar from './Sidebar';
import { PageWithMainNav } from 'App/Common/MainNav';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';

class Contact extends Component {
  render() {
    return (
      <PageWithMainNav>
        <ContentWrapper>
          {/* サイドバー */}
          <Sidebar />

          {/* コンタクト設定 */}
          <Right>
            <Switch>
              {/* 自分のコンタクト */}
              <Route path="/contacts/:teamId/contacts" component={Contacts} />
            </Switch>
          </Right>
        </ContentWrapper>
      </PageWithMainNav>
    );
  }
}

const ContentWrapper = styled.div`
  display: flex;
  height: 100%;
  & > * {
    height: 100%;
  }
`;

const Right = styled.div`
  overflow: scroll;
  flex: 1;
`;

export default compose(inject('store'), observer)(Contact);
