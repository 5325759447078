import { ComponentPropsWithoutRef, forwardRef } from 'react';
import { tv } from 'tailwind-variants';

type Props = Omit<ComponentPropsWithoutRef<'input'>, 'size'> & {
  size?: 'sm' | 'md';
  error?: boolean;
};

const input = tv({
  base: 'w-full rounded-lg border border-sumi-300 px-2 placeholder-sumi-500 placeholder:opacity-[1] focus-visible:outline focus-visible:outline-2 focus-visible:outline-sea-200 disabled:cursor-not-allowed disabled:text-sumi-500',
  variants: {
    size: {
      sm: 'h-8',
      md: 'h-10',
    },
    error: {
      true: 'border border-sun-500',
    },
  },
  defaultVariants: {
    size: 'md',
  },
});

export const Input = forwardRef<HTMLInputElement, Props>(
  ({ size, error, className, ...props }: Props, ref): JSX.Element => {
    return (
      <input
        {...props}
        className={input({ size, error, className })}
        ref={ref}
      />
    );
  }
);

Input.displayName = 'Input';
