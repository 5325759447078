import { useStore } from 'hooks/useStore';
import { BaseMessagePane } from './BaseMessagePane';
import { Scheduled } from '../MessageList/Me/Scheduled/Scheduled';
import { MessageListEmpty } from 'components/MessageList';
import { observer } from 'mobx-react';

export const PATH = '/me/scheduled';

// 自分 - 送信予約
export const MessagePaneMeScheduled: React.FC = observer(() => {
  const { getSearchedScheduledDrafts, searchStore } = useStore();

  const getEmptyDescription = () => {
    const { inSearch } = searchStore;
    return inSearch
      ? `検索条件と一致する送信予約はありません`
      : '送信予約はありません';
  };

  return (
    <BaseMessagePane
      listPagePath={PATH}
      empty={getSearchedScheduledDrafts.length === 0}
      emptyComponent={<MessageListEmpty description={getEmptyDescription()} />}
      listComponent={<Scheduled />}
    />
  );
});
