import styles from './SidebarBase.module.css';
import { ComponentPropsWithoutRef } from 'react';
import { twMerge } from 'tailwind-merge';

type Props = ComponentPropsWithoutRef<'div'>;

export const SidebarBase = ({ className, children, ...props }: Props) => {
  return (
    <div
      className={twMerge(
        `flex h-full max-h-full w-full flex-col gap-[1px] overflow-auto px-4`,
        styles.hideScrollbar,
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
};
