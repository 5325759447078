import { Button, Icon, Modal, notification } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { db9, FieldValue } from '../../../firebase';
import { createMessage, Draft, MessageData, MessageStatus } from 'lib';
import { eventNames, logEvent } from '../../../analytics';
import store from '../../../store';
import { sendMessageFunction } from '../../../functions';
import { WriteBatch } from '../../../utils/firestore';
import {
  doc,
  DocumentReference,
  serverTimestamp,
  updateDoc,
} from 'firebase/firestore';
import {
  SendMessageErrorContent,
  SendMessageError,
} from './SendMessageErrorContent';

const openNotification = (key: string) => {
  notification.open({
    icon: <Icon type="loading" />,
    className: 'yaritori-notification-send-message',
    message: 'メッセージを送信中です…',
    placement: 'bottomLeft',
    closeIcon: <div />,
    key,
    duration: 0, // 閉じない
  });
};

const updateCancellableNotification = (key: string, cancel: () => void) => {
  notification.open({
    icon: <Icon type="loading" />,
    className: 'yaritori-notification-send-message',
    message: 'メッセージを送信中です…',
    placement: 'bottomLeft',
    closeIcon: <div />,
    btn: (
      <Button
        type="link"
        onClick={() => {
          cancel();
          notification.close(key);
        }}
      >
        キャンセル
      </Button>
    ),
    key,
    duration: 0, // 閉じない
  });
};

const updateSendingNotification = (key: string) => {
  notification.open({
    icon: <Icon type="loading" />,
    className: 'yaritori-notification-send-message',
    message: 'メッセージを送信中です…',
    placement: 'bottomLeft',
    closeIcon: <div />,
    btn: (
      <span
        className="yaritori-notification-send-message-close-wrapper"
        onClick={() => notification.close(key)}
      >
        <CloseOutlined />
      </span>
    ),
    key,
    duration: 0, // 閉じない
  });
};

const updateSuccessNotification = (key: string) => {
  notification.success({
    className: 'yaritori-notification-send-message',
    message: 'メッセージを送信しました',
    placement: 'bottomLeft',
    closeIcon: <div />,
    btn: (
      <span
        className="yaritori-notification-send-message-close-wrapper"
        onClick={() => notification.close(key)}
      >
        <CloseOutlined />
      </span>
    ),
    key,
    duration: 1,
  });
};

const sleep = (msec: number) =>
  new Promise((resolve) => setTimeout(resolve, msec));

export const sendMessage = (draftId: string, status: MessageStatus) => {
  return new Promise(async (resolve) => {
    let isCanceled = false;
    store.cancelableSendingMessageIds = [
      ...store.cancelableSendingMessageIds,
      draftId,
    ];

    // 送信対象がモーダルで開いている下書きの場合はモーダルを非表示にする。
    if (store.newDraft && store.newDraft.id === draftId) {
      store.newDraft = null;
    }

    const key = draftId;

    openNotification(key);

    const draft = store.getDraft(draftId);
    if (!draft) {
      const errorMessage = `Draft not found in sendMessageFunc@sendMessage. DraftId:${draftId}`;
      console.error(errorMessage);
      Modal.error({
        title: 'メールの送信に失敗しました',
        width: 520,
        content: (
          <>
            <p>
              お手数ですが、画面をリロードして再度お試しください。もし解消されず、エラーが続いた場合はお問い合わせください。
              <br />
              {errorMessage}
            </p>
          </>
        ),
      });
      return;
    }

    const payload: Partial<Draft> = {
      isSending: true,
    };

    const systemStatuses: string[] = [
      MessageStatus.Unprocessed,
      MessageStatus.Processed,
    ];

    if (
      draft.followupAt &&
      draft.status &&
      !systemStatuses.includes(draft.status)
    ) {
      payload.followupStatus = draft.status;
    }

    // 下書きの状態を送信中にする。
    await draft.ref.update({
      ...payload,
      updatedAt: FieldValue.serverTimestamp(),
    });

    const cancelStatusUpdate =
      draft.isReply && status != null && status !== MessageStatus.Unprocessed
        ? await updateReplyToMessage(draft, status)
        : null;

    // キャンセル時にresolveしなかった場合、sendMessageの処理が5秒待たないとresolveされなくなるので入れています。
    const cancel = async () => {
      store.cancelableSendingMessageIds =
        store.cancelableSendingMessageIds.filter(
          (messageId) => messageId !== draftId
        );
      isCanceled = true;

      // 下書きの状態を送信中から元に戻す。
      await draft.ref.update({
        isSending: false,
        updatedAt: FieldValue.serverTimestamp(),
      });

      if (cancelStatusUpdate) {
        await cancelStatusUpdate();
      }

      resolve({ cancel: true });
    };

    // 通知にキャンセルボタンを表示させている。
    updateCancellableNotification(key, cancel);

    await sleep(5 * 1000);

    if (isCanceled) return;

    updateSendingNotification(key);

    try {
      await send(draftId);

      if (draft.isReply) {
        await createEvent(draft);
      }

      updateSuccessNotification(key);
      resolve({ cancel: false });
    } catch (e) {
      type SendMessageFuncError = {
        details?: {
          transportError?: SendMessageError;
          traceId?: string;
        };
      };

      const errorDetail = (e as SendMessageFuncError)?.details;

      console.error(
        'sendMessage:',
        {
          draftId: draft.id,
          companyId: store.signInCompany,
          teamId: draft.teamId,
          inboxId: draft.inboxId,
          inReplyToMessageId: draft.inReplyToMessageId,
          status,
        },
        errorDetail || null,
        e
      );
      Modal.error({
        title: 'メールの送信に失敗しました',
        width: 520,
        content: (
          <>
            {errorDetail?.transportError && (
              <SendMessageErrorContent error={errorDetail.transportError} />
            )}
            {errorDetail?.traceId && (
              <p>トラッキングID：{errorDetail.traceId}</p>
            )}
          </>
        ),
      });
      cancel();
      notification.close(key);
    }
  });
};

const updateReplyToMessage = async (draft: Draft, status: string) => {
  const { me } = store;
  const messageSnapshot = await draft.inReplyToMessageRef.get();
  const replyToMessage = createMessage(
    // withConverter(messageLikeConverter) や messageConverterなどがうまくDocumentReference<MessageData>に変換できないため、as でキャストする
    doc(db9, draft.inReplyToMessageRef.path) as DocumentReference<MessageData>,
    messageSnapshot.data()
  );

  const updateStatus = async (status: string) => {
    if (store.isInThreadView) {
      const snapshot = await store
        .companyCollection('messages')
        .where('teamId', '==', replyToMessage.teamId)
        .where('threadId', '==', replyToMessage.threadId)
        .where('status', '!=', status)
        .where('deleted', '==', false)
        .get();

      const batch = new WriteBatch();
      snapshot.docs
        .map((doc) => doc.ref)
        .forEach((ref) => {
          batch.update(ref, {
            status,
            updatedAt: FieldValue.serverTimestamp(),
          });
        });
      await batch.commit();
    } else {
      await updateDoc(replyToMessage.ref, {
        status,
        updatedAt: serverTimestamp(),
      });
    }
  };

  const updateProcessed = updateStatus(status);

  const addProcessedEvent = store.companyCollection('events').add({
    user: me.id,
    name: me.name,
    teamId: replyToMessage.teamId,
    messageId: replyToMessage.id,
    type: 'status:update:processed',
    text: `${me.name}が「${status}」に変更しました。`,
    createdAt: FieldValue.serverTimestamp(),
    updatedAt: FieldValue.serverTimestamp(),
  });
  await Promise.all([updateProcessed, addProcessedEvent]);

  logEvent(eventNames.update_status, { status });

  return async () => {
    const updateUnprocessed = updateStatus(MessageStatus.Unprocessed);

    const addUnprocessedEvent = store.companyCollection('events').add({
      user: me.id,
      name: me.name,
      teamId: replyToMessage.teamId,
      messageId: replyToMessage.id,
      type: 'status:update:backlog',
      text: `${me.name}が「${MessageStatus.Unprocessed}」に変更しました。`,
      createdAt: FieldValue.serverTimestamp(),
      updatedAt: FieldValue.serverTimestamp(),
    });
    await Promise.all([updateUnprocessed, addUnprocessedEvent]);
    logEvent(eventNames.update_status, { status: MessageStatus.Unprocessed });
  };
};

const createEvent = async (draft: Draft) => {
  const companyRef = draft.ref.parent.parent!;

  await companyRef.collection('events').add({
    user: store.me.id,
    name: store.me.name,
    teamId: draft.teamId,
    messageId: draft.inReplyToMessageId,
    type: 'draft:sent',
    text: `${store.me.name}が${
      draft.isForwarded ? '転送' : '返信'
    }を送信しました。`,
    createdAt: FieldValue.serverTimestamp(),
    updatedAt: FieldValue.serverTimestamp(),
  });
};

const send = (draftId: string) =>
  sendMessageFunction({
    draftId,
    companyId: store.signInCompany,
  });
