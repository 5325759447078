import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { Alert, Button, message, Modal, Tag } from 'antd';
import * as color from '../../../../../color';
import level from '../../../../../font/level';
import moment from 'moment';
import media from 'styled-media-query';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import Attachments from '../Sent/attachments';
import { WysiwygEditor } from '../../../../Common/Editor/WysiwygEditor/WysiwygEditor';
import { MultipleCopy } from '../../../../Common/MultipleCopy';
import Signature from '../../../CreateMessage/signature';
import Quote from '../../../CreateMessage/quote';
import firebase, { companyCollection, db } from '../../../../../firebase';
import { eventNames, logEvent } from '../../../../../analytics';

const { confirm } = Modal;

const Address = (props) => {
  return (
    <MultipleCopy>
      <Tag key={props.address} style={{ whiteSpace: 'break-spaces' }}>
        {props.address}
      </Tag>
    </MultipleCopy>
  );
};

const ValueComponent = ({ value, title }) => {
  if (!value) return <div />;

  if (Array.isArray(value))
    return value.length > 0 ? (
      <Addresses>
        {title}:{' '}
        {value.map((v) => (
          <Address key={v} address={v} />
        ))}
      </Addresses>
    ) : (
      <div />
    );

  return (
    <Addresses>
      {title}: <Address key={value} address={value} />
    </Addresses>
  );
};

class OtherMemberDraft extends Component {
  async componentDidMount() {
    const { draft } = this.props;

    const diff = moment().diff(moment(draft.updatedAt.toDate()), 'minutes');

    // 下書きの更新から10分以上経っていて送信中だった場合、送信中ではないはずなので戻す。
    if (draft.isSending && diff >= 10) {
      await draft.ref.update({
        isSending: false,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
    }
  }

  changeDrafter = async () => {
    const { store, draft } = this.props;
    const {
      companyId,
      teamId,
      originalInboxId,
      inboxId,
      inReplyToMessageId,
      drafter,
    } = draft;

    const locksCollection = companyCollection(companyId, 'locks');
    const locksRef = await locksCollection
      .where('teamId', '==', teamId)
      .where('messageId', '==', inReplyToMessageId)
      .get();

    await db.runTransaction(async (tx) => {
      // ロックデータの制御
      locksRef.docs.forEach((doc) => tx.delete(doc.ref));

      tx.set(locksCollection.doc(), {
        teamId: teamId,
        messageId: inReplyToMessageId,
        inboxId: originalInboxId || inboxId,
        locker: store.me.id,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });

      // 下書きデータの制御
      tx.update(draft.ref, {
        drafter: store.me.id,
        inboxId: originalInboxId || inboxId,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });

      // イベントを作成する
      const currentDrafter = store.getUser(drafter);
      tx.set(companyCollection(companyId, 'events').doc(), {
        user: store.me.id,
        name: store.me.name,
        teamId: teamId,
        messageId: inReplyToMessageId,
        type: 'draft:create:delegate',
        text: `${store.me.name}が${
          currentDrafter ? currentDrafter.name : '削除されたユーザ'
        }の代わりに返信を開始しました。`,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
    });

    logEvent(eventNames.start_reply);
    message.success('自分の下書きに変えました。');
  };

  render() {
    const { draft, store } = this.props;

    return (
      <Wrapper>
        <MessageWrapper name={`other-draft-${draft.id}`}>
          <Head>
            <Flex style={{ justifyContent: 'space-between' }}>
              <div>
                {!store.me.isReadOnly && (
                  <div style={{ marginBottom: 12 }}>
                    <Alert
                      style={{ display: 'inline-block' }}
                      message={
                        <Button
                          type="link"
                          size="small"
                          disabled={draft.isSending}
                          loading={draft.isSending}
                          onClick={() =>
                            confirm({
                              title: 'メッセージを代わりに編集しますか？',
                              content:
                                '代わりに編集するとメッセージを編集・送信できるようになります。',
                              onOk: this.changeDrafter,
                              okText: '代わりに編集する',
                              cancelText: 'そのままにする',
                            })
                          }
                        >
                          {draft.isSending ? '送信中です…' : '代わりに編集する'}
                        </Button>
                      }
                      type="warning"
                    />
                  </div>
                )}
                <Title>{draft.subject}</Title>
                <Flex>
                  <div style={{ flex: 1, marginLeft: 10 }}>
                    <Addresses>
                      <ValueComponent value={draft.to} title="宛先" />
                    </Addresses>
                    <Addresses>
                      <ValueComponent value={draft.cc} title="Cc" />
                    </Addresses>
                    <Addresses>
                      <ValueComponent value={draft.bcc} title="Bcc" />
                    </Addresses>
                  </div>
                </Flex>
              </div>
            </Flex>
          </Head>
          <MessageBody>
            <BodyHtml>
              <WysiwygEditor
                key={draft.id}
                defaultValue={draft.bodyHtml}
                disabled={true}
              />
              <Signature
                key={`signature-${draft.id}`}
                signature={draft.signature}
                readOnly={true}
              />
              {(draft.isReply || draft.inReplyToSentRef) && (
                <Quote
                  useQuote={draft.useQuote}
                  draft={draft}
                  plainTextMode={draft.plainTextMode}
                  readOnly={true}
                />
              )}
            </BodyHtml>
            <Attachments attachments={draft.attachments} />
          </MessageBody>
        </MessageWrapper>
      </Wrapper>
    );
  }
}

export default compose(withRouter, inject('store'), observer)(OtherMemberDraft);

const Wrapper = styled.div``;

const MessageWrapper = styled.div`
  padding: 20px 0;
  ${media.greaterThan('small')`
    margin: 16px;
    border-radius: 10px;
  `};
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.16);
  background-color: ${color.common.white};
  ${({ highlight }) =>
    highlight &&
    css`
      box-shadow: 0 0 0 4px rgba(53, 119, 239, 0.2) inset;
    `}
`;

const Head = styled.div`
  padding: 0 30px 20px;
  border-bottom: solid 0.5px #f1f1f3;
`;

const Title = styled.h2`
  margin-bottom: 16px;
  font-size: ${level[3]};
  font-weight: bold;
`;

const MessageBody = styled.div`
  padding: 20px 30px 0 30px;

  font-size: ${level[1]};
`;

const BodyHtml = styled.div`
  margin: 8px 8px 42px 8px;
`;

const Flex = styled.div`
  display: flex;
`;

const Addresses = styled.div`
  margin-top: 4px;
`;
